/**
 * @登录相关
 * **/
import http from 'axios'
import util from '@/libs/util';

const EDUCATION_LOGIN = '/v1/auth/educationLoginForKey'

const api = {
   educationLoginForKey: (params) => http.get(util.createURL(EDUCATION_LOGIN, params)), // 天府新区大屏免密登录
};
export default api;