/**
 * @云赛事相关
 * **/
import http from 'axios'
import util from '@/libs/util';
const GET_COMPETION_STAGE_INDEX = '/v1/qiangji/competition/getCompetitionStageIndex'
const GET_COMPETITION_LIST = '/v1/qiangji/competition/getCompetitionList'
const GET_COMPETITION_LIST_NUM = '/v1/qiangji/competition/getCompetitionListNum'
const GET_COMPETITION_DETAIL = '/v1/qiangji/competition/getCompetitionDetail'
const GET_COMPETITION_DATA_OVERVIEW = '/v1/qiangji/competition/getCompetitionDataOverview'
const GET_COMPETITION_DATA_OVERVIEW_STAGE_INFO = '/v1/qiangji/competition/getCompetitionDataOverviewStageInfo'
const GET_SCHOOL_DETAIL = '/v1/school/getSchoolDetail'
const GET__COMPETITION_DATA_DETAIL = '/v1/qiangji/competition/getCompetitionDataDetail'
const GET_COMPETITION_SPORTS = '/v1/qiangji/competition/getCompetitionSports'
const GET_STUDENT_DETAIL = '/v1/qiangji/competition/getCompetitionStudentDetail'
const GET_COMPETITION_DATA_COUNT = '/v1/qiangji/competition/countCompetitionDataDetail'
const GET_COMPETITION_STUDENT_STAGE_INFO = '/v1/qiangji/competition/getCompetitionStudentStageInfo'
const GET_COMPETITION_EXERCISE = '/v1/qiangji/competition/getCompetitionStudentExercise'
const GET_COMPETITION_EXERCISE_COUNT = '/v1/qiangji/competition/countCompetitionStudentExercise'
const GET_GRADE_CLASS_DATA = '/v1/qiangji/competition/queryGradeClassData'
const GET_GRADE_CLASS_DATA_NUM = '/v1/qiangji/competition/countClassDataNum'

const GET_GONGGA_COMPETITION_DATA = '/v1/gongga/competition/getGonggaCompetitionData' // 贡嘎杯数据概览数据
const GET_GONGGA_GRADE_DATA = '/v1/gongga/competition/getGonggaGradeData' // 贡噶杯年级班级数据
const GET_GONGGA_CLASS_COUNT = '/v1/gongga/competition/getGonggaClassCount' // 贡噶杯班级分页条数
const GET_GONGGA_ITEM_DATA = '/v1/gongga/competition/getGonggaItemData' // 贡嘎杯项目数据
const GET_GONGGA_STUDENT_DATA = '/v1/gongga/competition/getGonggaStudentData' // 贡嘎杯学生数据
const GET_GONGGA_STUDENT_DATA_NUM = '/v1/gongga/competition/getGonggaStudentDataNum' // 贡嘎杯学生数据分页
const QUERY_GONGGA_GRADE_CLASS = '/v1/gongga/competition/queryGonggaGradeClass' // 贡嘎杯年级班级

const UPDATE_SCHOOL_STATUS = '/v1/qiangji/competition/updateSchoolLeagueReplaceStatus'
const api = {
   getCompetitionStageIndex: (params) => http.get(util.createURL(GET_COMPETION_STAGE_INDEX, params)), // 其他服务-云赛事-赛事当前时间是第几阶段
   getCompetitionList: (params) => http.get(util.createURL(GET_COMPETITION_LIST, params)), // 其他服务-云赛事-列表
   getCompetitionListNum: (params) => http.get(util.createURL(GET_COMPETITION_LIST_NUM, params)), // 其他服务-云赛事-列表数量
   getCompetitionDetail: (params) => http.get(util.createURL(GET_COMPETITION_DETAIL, params)), // 其他服务-云赛事-赛事详情
   getSchoolDetail: (params) => http.get(util.createURL(GET_SCHOOL_DETAIL, params)), // h获取学校详情
   getCompetitionDataOverview: (params) => http.get(util.createURL(GET_COMPETITION_DATA_OVERVIEW, params)), // 云赛事数据概览数据
   getCompetitionDataOverviewStageInfo: (params) => http.get(util.createURL(GET_COMPETITION_DATA_OVERVIEW_STAGE_INFO, params)), // 云赛事数据概览阶段项目数据
   updateSchoolStatus: (params) => http.get(util.createURL(UPDATE_SCHOOL_STATUS, params)), // 云赛事学校抵扣状态修改


   getCompetitionDataDetail: (params) => http.post(GET__COMPETITION_DATA_DETAIL, util.createParams(params)), // 云赛事数据详情列表
   getCompetitionSports: (params) => http.get(util.createURL(GET_COMPETITION_SPORTS, params)), // 云赛事项目
   getStudentInfo: (params) => http.get(util.createURL(GET_STUDENT_DETAIL, params)), // 云赛事学生详情
   getCompetitionDataCount: (params) => http.post(GET_COMPETITION_DATA_COUNT, util.createParams(params)), // 云赛事数据详情列表数量
   getStudentStageInfo: (params) => http.get(util.createURL(GET_COMPETITION_STUDENT_STAGE_INFO, params)), // 云赛事学生详情阶段数据
   getCompetitionExercise: (params) => http.get(util.createURL(GET_COMPETITION_EXERCISE, params)), // 云赛事学生详情锻炼详情
   getCompetitionExerciseCount: (params) => http.get(util.createURL(GET_COMPETITION_EXERCISE_COUNT, params)), // 云赛事学生详情锻炼详情列表数量
   getGradeOrClassData: (params) => http.get(util.createURL(GET_GRADE_CLASS_DATA, params)), // 云赛事数据概览班级年级数据
   getGradeOrClassCount: (params) => http.get(util.createURL(GET_GRADE_CLASS_DATA_NUM, params)), // 云赛事数据概览班级年级数据

   getGonggaCompetitionData: (params) => http.get(util.createURL(GET_GONGGA_COMPETITION_DATA, params)), // 贡嘎杯数据概览数据
   getGonggaGradeData: (params) => http.get(util.createURL(GET_GONGGA_GRADE_DATA, params)), // 贡噶杯年级班级数据
   getGonggaClassCount: (params) => http.get(util.createURL(GET_GONGGA_CLASS_COUNT, params)), // 贡噶杯年级班级分页
   getGonggaItemData: (params) => http.get(util.createURL(GET_GONGGA_ITEM_DATA, params)), // 贡嘎杯项目数据
   getGonggaStudentData: (params) => http.get(util.createURL(GET_GONGGA_STUDENT_DATA, params)), // 贡嘎杯学生数据
   getGonggaStudentDatanNum: (params) => http.get(util.createURL(GET_GONGGA_STUDENT_DATA_NUM, params)), // 贡嘎杯学生分页数据
   queryGonggaGradeClass: (params) => http.get(util.createURL(QUERY_GONGGA_GRADE_CLASS, params)), // 贡嘎杯年级班级
};
export default api;