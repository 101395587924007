
const pageParams = {
    namespaced: true,
    state: {
      currentGradeMsg:{
        type:'',
        gradeId:'',
      },
      registerCode:'',
      gradeList:[],//选择的年级班级
      ActivityItem:[],//已选择的项目
      isShowExportBtn: false, // 是否显示数据档案-历史数据-上报学生导入、按学生生成的按钮
      listPagePars: new Map()
    },
    mutations: {
      setCurrentGrade(state,changeCurrentGrade) {//修改当前选择的年级
        state.currentGrade = changeCurrentGrade
      },
      setGradeList(state,changeGrade) {//修改所选的年级
        state.gradeList = changeGrade
      },
      setActivity(state,changeActivity) { //修改所选的项目
        state.ActivityItem = changeActivity
      },
      setreGisterCode(state,registerCode) {//修改测试名称
        state.registerCode = registerCode
      },
      SAVE_LIST_PAGE_PARS: (state, { name, pars }) => {
        state.listPagePars.set(name,pars);
      },
      REMOVE_LIST_PAGE_PARS: (state, { name, pars }) => {
        state.listPagePars.set(name,pars);
      },
      REMOVE_LIST_PAGE_PARS_ALL: (state) => {
        state.listPagePars = new Map();
      },
      SET_EXPORT_BTN: (state, paylod) => {
        state.isShowExportBtn = paylod
      }
    },
    actions: {
      saveListPagePars: ({ commit },{name,pars}) => {
        commit('SAVE_LIST_PAGE_PARS',{ name,pars });
      },
      removeListPagePars: ({ commit },{name,pars}) => {
        commit('REMOVE_LIST_PAGE_PARS',{ name,pars });
      },
      removeListPageParsAll: ({ commit }) => {
        commit('REMOVE_LIST_PAGE_PARS_ALL');
      },
      setExportBtn: ({ commit }, paylod) => {
        commit('SET_EXPORT_BTN', paylod)
      }
    }
};

export default pageParams;
