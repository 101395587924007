<style lang="less">
@import './main.less';
</style>

<template>
  <div class="main" :class="{ 'main-hide-text': shrink }">
    <div
      class="sidebar-menu-con"
      :style="{
        width: shrink ? '4.6875vw' : '13.0208vw',
        overflow: shrink ? 'visible' : 'auto'
      }"
      v-if="!isFullscreen"
    >
      <!-- 侧边栏 -->
      <shrinkable-menu ref="shrinkable" :shrink="shrink" :menu-list="menuList" :open-names="openNames">
        <template slot="top" v-if="isQiangji">
          <div class="qiangji_title fcc">
            <img src="../../images/logo_qiangji.png" alt="" class="min-logo" />
            <div>强基未来</div>
          </div>
        </template>
        <div
          slot="top"
          :class="schoolName.length < 10 ? 'logo-con' : 'logo-con-more'"
          style="background-color: white"
          v-else
        >
          <img src="../../images/schoolLogo.png" class="min-logo" />
          <div class="schoolName-tit" v-if="!shrink">{{ schoolName }}</div>
        </div>
      </shrinkable-menu>
    </div>
    <!-- 顶部菜单 -->
    <div class="main-header-con" :style="{ paddingLeft: shrink ? '4.6875vw' : '13.0208vw' }" v-if="!isFullscreen">
      <div class="main-header">
        <div class="navicon-con">
          <topMenu
            ref="tpoMenu"
            :menu-list="menuListAll"
            :theme1="theme1"
            :schoolLevel="schoolLevel"
            :isQiangji="isQiangji"
            @on-click="handleChange"
            @on-change="handleOpenNames"
          ></topMenu>
        </div>
        <div class="header-avator-con p16">
          <div class="nowTimes" v-if="!shrink" :style="{ color: themeActiveColor }">
            <p>
              <strong>
                {{
                  semesterStatus == 1
                    ? '第一学期'
                    : semesterStatus == 2
                    ? '寒假'
                    : semesterStatus == 3
                    ? '第二学期'
                    : '暑假'
                }}
                <span v-if="semesterStatus == 1 || semesterStatus == 3">第{{ weekCount }}周 星期{{ weekDay }}</span>
              </strong>
            </p>
            <p>{{ nowTime }}</p>
          </div>
          <div class="user-dropdown-menu-con ml-20">
            <div class="user-dropdown-innercon flex-c">
              <Dropdown transfer trigger="click" @on-click="handleClickUserDropdown" style="flex: 1">
                <a href="javascript:void(0)" class="flex-c">
                  <span class="main-user-name">{{ userName }}</span>
                  <Icon type="md-arrow-dropdown" />
                </a>
                <DropdownMenu slot="list">
                  <DropdownItem
                    v-if="loginSchoolList && loginSchoolList.length > 1"
                    :name="item.relationSchoolId"
                    v-for="(item, index) in loginSchoolList"
                    :key="index"
                    divided
                    style="margin-top: 0px"
                    :selected="schoolName === item.relationSchoolName"
                  >
                    {{ item.relationSchoolName }}
                  </DropdownItem>
                  <DropdownItem name="loginout" divided style="margin-top: 0px">退出登录</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="single-page-con" :style="getContentPosition /** 手动计算position */" ref="mainBox">
      <!-- 待办事项浮窗 -->
      <div class="glob-message" v-if="!isFullscreen">
        <div
          class="circle"
          :style="{ color: themeActiveColor }"
          @click="clickShowMessage"
          v-if="messageList.length && schoolLevel != '2'"
        >
          待办
          <br />
          事项
        </div>
        <div class="message-box" v-if="showMessageBox && schoolLevel != '2'">
          <div>
            <div class="message-box-title">
              <img
                src="../../images/icon_horn.png"
                alt=""
                class="icon_horn"
                v-if="messageList[pageIndex].noticeType == 2"
              />
              <img src="../../images/icon_submit.png" alt="" class="icon_horn" v-else />
              <span v-if="messageList[pageIndex].noticeType">
                {{ getNoticeName(messageList[pageIndex].noticeType) }}
              </span>
            </div>
            <div class="message-box-content">
              <div>{{ messageList[pageIndex].noticeContent }}</div>
              <div class="time">
                {{
                  messageList[pageIndex].noticeType == 3 || messageList[pageIndex].noticeType == 4
                    ? '时间：'
                    : '截止时间：'
                }}
                {{ messageList[pageIndex].endTime }}
              </div>
            </div>
            <div class="message-box-footer">
              <div class="shouqi" @click="cancelMessageBox">
                <span>收起</span>
                <img src="../../images/icon_arrow_top.png" alt="" class="icon_arrow_top" />
              </div>
              <div v-if="messageList[pageIndex].noticeType == 3 || messageList[pageIndex].noticeType == 4">
                <Button style="width: 5.2083vw" @click="readMessage" type="success">已读本条</Button>
              </div>
              <Page :current="pageIndex + 1" :total="pageTotal * 10" simple @on-change="changeMessage" />
            </div>
          </div>
        </div>
      </div>
      <div class="single-page" ref="signglePage">
        <router-view :key="$route.fullPath"></router-view>
      </div>
      <!-- 重新登录 -->
      <Modal v-model="loginAgain" :closable="false" :mask-closable="false" width="400">
        <p slot="header" class="userSign">
          <span>{{ !loginStep ? '温馨提示' : '重新登录' }}</span>
        </p>
        <div class="again_content" v-if="!loginStep">由于长时间未操作，系统已自动退出登录。</div>
        <div class="formData" v-else>
          <Form ref="loginForm" :model="againParams" :rules="rules">
            <FormItem prop="userPhone" class="login-item">
              <img src="../../images/userName.png" alt />
              <Input
                v-model="againParams.userPhone"
                class="login-name"
                placeholder="请输入账号"
                :maxlength="11"
                disabled
              ></Input>
            </FormItem>
            <FormItem prop="againPassword" class="login-item">
              <img src="../../images/password.png" alt />
              <Input
                v-model="againParams.againPassword"
                type="password"
                class="login-name"
                placeholder="请输入密码"
                :minlength="6"
              ></Input>
            </FormItem>
            <FormItem prop="code" class="login-item">
              <img src="../../images/code.png" alt />
              <Input v-model="againParams.code" class="login-names" placeholder="请输入验证码" :maxlength="4"></Input>
              <img class="code-img" @click="changeCode" alt :src="againParams.codeSource" />
              <img class="reload" @click="changeCode" alt src="../../images/reload.png" />
            </FormItem>
          </Form>
        </div>
        <div slot="footer" class="footer">
          <Button type="success" @click="cancelLogin">退出</Button>
          <Button type="success" @click="isAgainLogin" v-if="!loginStep">重新登录</Button>
          <Button type="success" @click="againLogin" v-if="loginStep">登录</Button>
        </div>
      </Modal>
    </div>

    <!-- 新学期提醒弹窗 -->
    <SemesterConfigModal
      v-model="isShowSemesterConfig"
      @on-config="goConfigure"
      @on-ok="handleConfigModalOk"
      :limitStatus="limitStatus"
    />
    <!-- 学校基础配置弹窗 -->
    <SchoolBaseConfigModal
      @on-ok="handleConfigModalOk"
      v-model="isShowSchoolBaseConfig"
      :unirunInfo="unirunInfo"
      :semesterClass="semesterClass"
      :timetable="timetable"
    />
  </div>
</template>

<script>
import shrinkableMenu from '@/components/main-components/shrinkable-menu/shrinkable-menu.vue'
import topMenu from '@/components/main-components/shrinkable-menu/components/topMenu.vue'
import '@/components/main-components/shrinkable-menu/styles/index.less'
import Mixins from './MainMixin.js'
import cloudMatch from '@/api/cloudMatch'
import baseApi from '@/api/baseApi'
import Util from '@/libs/util'
import { mapActions, mapState } from 'vuex'
import SemesterConfigModal from '../../components/main-components/SemesterConfigModal.vue' // 新学期提醒
import SchoolBaseConfigModal from '../../components/main-components/SchoolBaseConfigModal.vue'

export default {
  name: 'LayoutMain',
  mixins: [Mixins],
  components: {
    shrinkableMenu,
    topMenu,
    SemesterConfigModal,
    SchoolBaseConfigModal
  },
  data() {
    return {
      timer: null,
      schoolLogo: '../../images/index_timo.png',
      isQiangji: false, // 是否是强基未来域名
      schoolName: '',
      shrink: false,
      userName: '',
      phoneNo: '',
      operationCode: '',
      avatarUrl: '',
      nowTime: '',
      theme1: 'light',
      openNames: [],
      loginSchoolList: [],
      weekCount: '',
      semesterStatus: '',
      weekDay: '',
      showMessageBox: false,
      messageList: [],
      pageIndex: 0,
      pageTotal: 3,
      schoolDistrict: '',
      menuLeftList: [],
      status: false,
      clientWidth: '',
      schoolLevel: '',

      /** 2424-10-11 优化 */
      isShowSemesterConfig: false, // 新学期提醒弹窗
      isShowSchoolBaseConfig: false, // 学校基础配置弹窗
      unirunInfo: {
        firstSemesterDateStart_month: '',
        firstSemesterDateStart_day: '',
        firstSemesterDateEnd_month: '',
        firstSemesterDateEnd_day: '',
        secondSemesterDateStart_month: '',
        secondSemesterDateStart_day: ''
      },
      limitStatus: '',
      semesterClass: {
        oneCount: '',
        twoCount: '',
        threeCount: '',
        fourCount: '',
        fiveCount: '',
        sixCount: '',
        sevenCount: '',
        eightCount: '',
        nineCount: '',
        tenCount: '',
        elevenCount: '',
        twelveCount: ''
      },
      timetable: {
        old: 'old',
        count: 'old',
        contentCopy: 'old'
      },
      isNextClick: false
    }
  },
  computed: {
    ...mapState({
      isFullscreen: state => state.isFullscreen
    }),

    /** 动态计算页面定位距离 */
    getContentPosition() {
      const { isFullscreen, shrink } = this
      let left = shrink ? '4.6875vw' : isFullscreen ? '0px' : '13.0208vw'
      let top = isFullscreen ? '0' : '80px'
      return {
        left,
        top
      }
    },
    getNoticeName() {
      return function (type) {
        let text
        if (type == 1) {
          text = '数据报送'
        } else if (type == 2) {
          text = '通知公告'
        } else if (type == 3) {
          text = '系统审核'
        } else if (type == 4) {
          text = '系统通知'
        }
        return text
      }
    },
    menuList() {
      return this.$store.state.app.menuList
    },
    menuListAll() {
      return this.itemvalue(this.$store.state.app.menuListAll)
    },
    themeActiveColor() {
      return this.$store.state.themeActiveColor
    },
    loginAgain() {
      let loginAgainStatus = this.$store.state.loginAgain
      if (loginAgainStatus) {
        this.againParams.userPhone = sessionStorage.getItem('phoneNo')
        this.againParams.againPassword = ''
        this.againParams.code = ''
        this.changeCode()
      }
      return loginAgainStatus
    }
  },
  watch: {
    // 监听屏幕宽度，如果小于等于1440显示收缩菜单，否则显示展开菜单
    clientWidth: {
      handler(newVal, oldVal) {
        if (newVal <= 1440) {
          this.shrink = true
        } else if (newVal > 1440) {
          this.shrink = false
        }
      },
      immediate: true
    }
  },
  beforeMount() {
    /**
     * 在挂载阶段监听浏览器尺寸，动态获取屏幕宽度
     */
    const vm = this
    this.clientWidth = document.body.clientWidth
    window.onresize = () => {
      return (() => {
        vm.clientWidth = document.body.clientWidth
      })()
    }
  },
  methods: {
    ...mapActions(['updateActiveTopMenuName', 'updateDataDeskPage', 'updateFullScreenStatus']),
    /**
     * @description 监听新学期提醒以及学校基础配置弹窗
     * @param str 0 表示新学期提醒弹窗 1表示学校基础配置需要保存
     */
    handleConfigModalOk(str) {
      let vm = this
      if (this.isNextClick) {
        return
      }
      this.isNextClick = true
      let info = {}
      if (str == 1) {
        //配置了
        if (
          this.unirunInfo.secondSemesterDateStart_month == '' ||
          this.unirunInfo.secondSemesterDateStart_day == '' ||
          this.unirunInfo.firstSemesterDateEnd_month == '' ||
          this.unirunInfo.firstSemesterDateEnd_day == '' ||
          this.unirunInfo.firstSemesterDateStart_month == '' ||
          this.unirunInfo.firstSemesterDateStart_day == ''
        ) {
          this.$Message.error('请选择学期起止日期。')
          this.isNextClick = false
          return false
        }
        if (
          parseInt(this.unirunInfo.firstSemesterDateStart_month) > parseInt(this.unirunInfo.firstSemesterDateEnd_month)
        ) {
          this.$Message.error('结束时间不能小于开始时间')
          this.isNextClick = false
          return false
        }
        if (
          this.unirunInfo.firstSemesterDateStart_month + this.unirunInfo.firstSemesterDateStart_day ==
          this.unirunInfo.firstSemesterDateEnd_month + this.unirunInfo.firstSemesterDateEnd_day
        ) {
          this.$Message.error('日期不能是同一天')
          this.isNextClick = false
          return false
        }
        if (this.schoolType == '2') {
          if (
            this.semesterClass.oneCount == '' ||
            this.semesterClass.oneCount < 0 ||
            this.semesterClass.twoCount == '' ||
            this.semesterClass.twoCount < 0 ||
            this.semesterClass.threeCount == '' ||
            this.semesterClass.threeCount < 0 ||
            this.semesterClass.fourCount == '' ||
            this.semesterClass.fourCount < 0 ||
            this.semesterClass.fiveCount == '' ||
            this.semesterClass.fiveCount < 0 ||
            this.semesterClass.sixCount == '' ||
            this.semesterClass.sixCount < 0
          ) {
            this.$Message.error('请输入年级课时数。')
            this.isNextClick = false
            return false
          }
        }
        if (this.schoolType == '3') {
          if (
            this.semesterClass.sevenCount == '' ||
            this.semesterClass.sevenCount < 0 ||
            this.semesterClass.eightCount == '' ||
            this.semesterClass.eightCount < 0 ||
            this.semesterClass.nineCount == '' ||
            this.semesterClass.nineCount < 0
          ) {
            this.$Message.error('请输入年级课时数。')
            this.isNextClick = false
            return false
          }
        }
        if (this.schoolType == '4') {
          if (
            this.semesterClass.tenCount == '' ||
            this.semesterClass.tenCount < 0 ||
            this.semesterClass.elevenCount == '' ||
            this.semesterClass.elevenCount < 0 ||
            this.semesterClass.twelveCount == '' ||
            this.semesterClass.twelveCount < 0
          ) {
            this.$Message.error('请输入年级课时数。')
            this.isNextClick = false
            return false
          }
        }
      }
      info = {
        classScheduleStatus: this.timetable.old == 'old' ? 1 : 0, // 课表沿用状态1沿用0不沿用
        classSecondStart:
          this.unirunInfo.secondSemesterDateStart_month + '-' + this.unirunInfo.secondSemesterDateStart_day, //开课时间 ,
        configurationStatus: str, //是否配置1是0否 ,
        gradeEightCount: this.semesterClass.eightCount, //八年级课时数 ,
        gradeElevenCount: this.semesterClass.elevenCount, //高二年级课时数 ,
        gradeFiveCount: this.semesterClass.fiveCount, //五年级课时数 ,
        gradeFourCount: this.semesterClass.fourCount, //四年级课时数 ,
        gradeNineCount: this.semesterClass.nineCount, //九年级课时数 ,
        gradeOneCount: this.semesterClass.oneCount, //一年级课时数 ,
        gradeSevenCount: this.semesterClass.sevenCount, //七年级课时数 ,
        gradeSixCount: this.semesterClass.sixCount, // 六年级课时数 ,
        gradeTenCount: this.semesterClass.tenCount, //高一年级课时数 ,
        gradeThreeCount: this.semesterClass.threeCount, //三年级课时数 ,
        gradeTwelveCount: this.semesterClass.twelveCount, //高三年级课时数 ,
        gradeTwoCount: this.semesterClass.twoCount, //二年级课时数 ,
        homeworkConfigurationStatus: this.timetable.contentCopy == 'old' ? 1 : 0, //家庭作业配置是否沿用1是0否 ,
        homeworkCountStatus: this.timetable.count == 'old' ? 1 : 0, // 家庭作业次数是否沿用1是0否 ,
        schoolId: sessionStorage.getItem('schoolId'), //学校id ,
        secondSemesterDateEnd:
          this.unirunInfo.firstSemesterDateEnd_month + '-' + this.unirunInfo.firstSemesterDateEnd_day, //学期结束时间 ,
        secondSemesterDateStart:
          this.unirunInfo.firstSemesterDateStart_month + '-' + this.unirunInfo.firstSemesterDateStart_day, //学期开始时间 ,
        teacherId: sessionStorage.getItem('teacherId') //教师id
      }
      this.$axios
        .post('/v1/schoolsemesternotice/addSchoolConfiguration', info)
        .then(res => {
          if (res.data.code == 10000) {
            vm.isNextClick = false
            if (str == 1) {
              vm.$Message.success('配置成功')
              vm.isShowSchoolBaseConfig = false
            }
          } else {
            vm.isNextClick = false
          }
        })
        .catch(err => {
          vm.$Message.success('配置失败,请稍候再试！')
          vm.isNextClick = false
        })
    },
    goConfigure() {
      console.log('去配置')
      this.isShowSchoolBaseConfig = true
    },
    getSchoolWasNotice() {
      let vm = this
      vm.schoolType = sessionStorage.getItem('schoolType')
      this.$axios.get('/v1/schoolsemesternotice/getSchoolWasNotice').then(res => {
        if (res.data.code == 10000) {
          vm.configurationStatus = res.data.response.configurationStatus
          vm.limitStatus = res.data.response.limitStatus
          if (res.data.response.secondSemesterDateStart) {
            vm.unirunInfo.firstSemesterDateStart_month = res.data.response.secondSemesterDateStart.split('-')[0]
            vm.unirunInfo.firstSemesterDateStart_day = res.data.response.secondSemesterDateStart.split('-')[1]
          }
          if (res.data.response.secondSemesterDateEnd) {
            vm.unirunInfo.firstSemesterDateEnd_month = res.data.response.secondSemesterDateEnd.split('-')[0]
            vm.unirunInfo.firstSemesterDateEnd_day = res.data.response.secondSemesterDateEnd.split('-')[1]
          }
          if (res.data.response.sportsClassSecondStart) {
            vm.unirunInfo.secondSemesterDateStart_month = res.data.response.sportsClassSecondStart.split('-')[0]
            vm.unirunInfo.secondSemesterDateStart_day = res.data.response.sportsClassSecondStart.split('-')[1]
          }
          if (res.data.response.configurationStatus != 1 && res.data.response.isAdmin == '1') {
            vm.isShowSemesterConfig = true
          }
        }
      })
    },
    // 切换全屏状态
    changeFullScreen(value) {
      this.updateFullScreenStatus(!value)
    },
    // 回滚到顶部
    fatherMethod() {
      // this.$refs.mainBox.scrollTop = this.$refs.signglePage.offsetHeight
      this.$refs.mainBox.scrollTop = 0
    },
    getMessageList() {
      this.$axios.get('/v1/education/queryEducationInfoList').then(res => {
        if (res.data.code === 10000) {
          let result = res.data.response
          if (result && result.length > 0) {
            for (let i = 0; i < result.length; i++) {
              let item = result[i]
              item.endTime = item.endTime.split('.')[0]
            }
            let lastNoticeId = Number(sessionStorage.getItem('lastNoticeId'))
            if (lastNoticeId) {
              // 从缓存中取出noticeId
              if (result[result.length - 1].noticeId > lastNoticeId) {
                // 如果最新的noticeId大于缓存中的noticeId则说明有消息
                this.showMessageBox = true
              }
            }
            sessionStorage.setItem('lastNoticeId', result[result.length - 1].noticeId)
            this.messageList = result
            this.pageTotal = res.data.response.length
            if (sessionStorage.getItem('showMessageBox')) {
              // 如果检测到是第一次登陆进来显示消息
              this.showMessageBox = true
            }
          } else {
            this.showMessageBox = false
            sessionStorage.removeItem('showMessageBox')
          }
        }
      })
    },
    changeMessage(e) {
      this.pageIndex = e - 1
    },
    cancelMessageBox() {
      this.showMessageBox = false
      sessionStorage.removeItem('showMessageBox')
    },
    readMessage() {
      let vm = this
      this.$axios
        .get(
          '/v1/notice/teacherReadMessage?noticeId=' +
            vm.messageList[vm.pageIndex].noticeId +
            '&noticeFrom=' +
            vm.messageList[vm.pageIndex].noticeFrom
        )
        .then(function (res) {
          vm.getMessageList()
        })
    },
    clickShowMessage() {
      this.showMessageBox = true
    },
    handleChange(name) {
      this.$refs.shrinkable.handleChange(name)
    },
    handleOpenNames(name) {
      this.openNames = [name]
    },
    itemvalue(list) {
      let newList = []
      for (let val of list) {
        newList.push({
          parentCode: val.parentCode,
          parentName: val.parentName,
          name: val.name,
          parentIcon: val.parentIcon,
          disabled: val.disabled
        })
      }
      return this.unique(newList)
    },
    unique(person) {
      let obj = {}
      let peon = person.reduce((cur, next) => {
        obj[next.parentCode] ? '' : (obj[next.parentCode] = true && cur.push(next))
        return cur
      }, [])
      return peon
    },
    init() {
      this.userName = sessionStorage.getItem('name')
      if (this.userName == '') {
        this.handleClickUserDropdown(this.userName)
      }
    },
    toggleClick() {
      this.shrink = !this.shrink
    },
    handleClickUserDropdown(name) {
      if (name != 'loginout') {
        this.status = true
        const activeSchoolId = sessionStorage.getItem('schoolId')
        if (activeSchoolId == name) return
        this.$Spin.show({
          render: h => {
            return h('div', [
              h('Icon', {
                class: 'demo-spin-icon-load',
                props: {
                  type: 'ios-loading',
                  size: 18
                }
              }),
              h('div', '切换中')
            ])
          }
        })
        const token = sessionStorage.getItem('token')
        this.$store.dispatch('pageParams/removeListPageParsAll') // 清除缓存

        // 非大屏进入切换身份重置参数
        const { qiangjiURL } = this.$axios.defaults
        const { origin } = window.location
        let yb_isYiBinBigScreen = sessionStorage.getItem('yb_isYiBinBigScreen')
        sessionStorage.clear()
        if (yb_isYiBinBigScreen == '0') {
          // 大屏默认参数
          sessionStorage.setItem('yb_isYiBinBigScreen', '0')
          sessionStorage.setItem('yb_enter', '0')
          sessionStorage.setItem('yb_originType', origin == qiangjiURL ? '2' : '1')
          sessionStorage.setItem('yb_deviceCode', '')
        }
        sessionStorage.setItem('token', token)
        this.$Spin.hide()
        this.loginByToken(name)
      } else {
        sessionStorage.clear()
        this.$store.dispatch('pageParams/removeListPageParsAll') // 清除缓存
        this.changeFullScreen(true)
        this.handleLoginOut() // 处理退出
      }
    },
    //处理退出
    handleLoginOut() {
      const { shuangliuURL, wjkcURL, jiafaURL } = this.$axios.defaults
      let { origin } = window.location
      if (origin === shuangliuURL || origin === wjkcURL) {
        if (origin === shuangliuURL) {
          window.location.replace(`${jiafaURL}/sso/logout`)
        } else {
          window.location.replace('https://www.i-school.net/login/logout')
        }
      } else {
        this.$router.push({ name: 'login' })
      }
    },
    showTime() {
      var myDate = new Date()
      this.nowTime =
        myDate.getFullYear() +
        '-' +
        (myDate.getMonth() + 1 < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1) +
        '-' +
        (myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate()) +
        ' ' +
        (myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours()) +
        ':' +
        (myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes()) +
        ':' +
        (myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds())
      setTimeout(() => {
        this.showTime()
      }, 1000)
    },
    async loginByToken(schoolId) {
      const res = await baseApi.loginToken({
        token: Util.getStorage('token'),
        schoolId: schoolId,
        schoolType: '2'
      })
      const this_ = this
      if (res.data.code != 10000) {
        this_.$Spin.hide()
        this_.$router.push({
          name: 'login'
        })
      } else {
        this.getSchoolDetail(schoolId)
        if (this_.status) {
          this_.status = false
          this_.$nextTick(() => {
            this_.updateActiveTopMenuName(`top_config-${this.menuList[0].name}`) // 更新顶部导航默认选中
          })
        }
        sessionStorage.setItem('token', '')
        sessionStorage.setItem('phoneNo', '')
        sessionStorage.setItem('teacherId', '')
        sessionStorage.setItem('name', '')
        sessionStorage.setItem('schoolId', '')
        sessionStorage.setItem('avatarUrl', '')
        sessionStorage.setItem('menuList', '')
        sessionStorage.setItem('schoolName', '')
        sessionStorage.setItem('schoolType', '')
        sessionStorage.setItem('schoolDistrict', '')
        sessionStorage.setItem('isAdmin', '')
        sessionStorage.setItem('yearSemester', '')
        sessionStorage.setItem('semesterOver', '')
        sessionStorage.setItem('schoolYear', '')
        sessionStorage.setItem('semesterStatus', '')
        sessionStorage.setItem('weekCount', '')
        sessionStorage.setItem('single', '')
        let response = res.data.response
        //成都市温江区永盛学校-初中部
        if (response.schoolId == 2996) {
          response.menuList.push('schoolDataReport') //添加学校数据报告
          //删除学生健康监测
          let menuIundexdex = response.menuList.indexOf('healthDetection')
          response.menuList.splice(menuIundexdex, 1)
        }

        this_.$axios.defaults.headers.common['token'] = response.oAuthTokenVO.token
        // 区分强基和小马，区分归属版本
        const { schoolLevel, qiangjiSchoolLevel } = response
        response.schoolLevel = this_.isQiangji ? qiangjiSchoolLevel : schoolLevel
        // 文轩测试版本，添加固定置灰的路由
        if (response.schoolLevel === '4') {
          const wenxuanDisMenuList = [
            'examManage',
            'examItemManage',
            'healthknowledge',
            'paperManage',
            'sportsTotalScore',
            'sportsScoreStudent',
            'healthRecordManage',
            'healthRecordStudent',
            'dataReport',
            'reportStudentManage',
            'systemNotice',
            'systemNoticeList'
          ]
          response.menuList = [...response.menuList, ...wenxuanDisMenuList]
        }
        sessionStorage.setItem('overallSituationuserInfo', JSON.stringify(response))
        sessionStorage.setItem('token', response.oAuthTokenVO.token)
        sessionStorage.setItem('phoneNo', response.phoneNum)
        sessionStorage.setItem('teacherId', response.teacherId)
        sessionStorage.setItem('schoolId', response.schoolId)
        sessionStorage.setItem('name', response.name)
        sessionStorage.setItem('avatarUrl', response.avatarUrl)
        sessionStorage.setItem('menuList', response.menuList)
        sessionStorage.setItem('schoolName', response.schoolName)
        sessionStorage.setItem('schoolType', response.schoolType)
        sessionStorage.setItem('isAdmin', response.isAdmin)
        sessionStorage.setItem('yearSemester', response.yearSemester)
        sessionStorage.setItem('semesterOver', response.semesterOver)
        sessionStorage.setItem('schoolYear', response.schoolYear)
        sessionStorage.setItem('semesterStatus', response.semesterStatus)
        sessionStorage.setItem('weekCount', response.weekCount)
        sessionStorage.setItem('single', response.single)
        sessionStorage.setItem('schoolDistrict', response.schoolDistrict)
        sessionStorage.setItem('userInfo', JSON.stringify(response))

        this_.userName = response.name
        this_.phoneNo = response.phoneNo
        this_.operationCode = response.operationCode
        this_.avatarUrl = response.avatarUrl
        this_.schoolName = response.schoolName
        this_.semesterStatus = response.semesterStatus
        this_.weekCount = response.weekCount

        if (sessionStorage.getItem('selectedName') == null) {
          if (sessionStorage.getItem('schoolType') == 6) {
            sessionStorage.setItem('selectedName', 'top_data-dataMonitoring')
          }
          // this_.updateDataDeskPage('2')
          this_.$store.commit('updateMenulist')
          this_.$store.commit('setStateName', '')
          this_.$store.commit('modifyMnueList', '')
          this_.$router.push({
            name: this_.$store.state.app.menuList[0].children[0].name
          })

          // 由于相同路由相同组件间跳转不会触发视图更新，所以这里先强制刷新一下
          // 后面找到更好的解决方案再修改
          this_.timer = setTimeout(() => {
            window.location.reload()
          }, 50)
          this_.handleOpenNames(this_.$store.state.app.menuList[0].name)
          this_.handleChange(this_.$store.state.app.menuList[0].children[0].name)
        }
        if (schoolId != null && schoolId > 0) {
          this_.$Spin.hide()
        }

        this_.replaceNoTestTitle()
      }
    },
    // 替换免试管理或者免考管理
    replaceNoTestTitle(schoolDistrict) {
      let menuListClone = JSON.parse(JSON.stringify(this.menuList))
      const regExp = /免试/g
      menuListClone.forEach(item => {
        if (item.path == '/StudentExempt') {
          item.title = schoolDistrict == 2000 ? '免考管理' : '免试管理'
        }
        if (item.children) {
          item.children.forEach(childrenItem => {
            childrenItem.title = childrenItem.title.replace(regExp, schoolDistrict == 2000 ? '免考' : '免试')
          })
        }
      })
      this.$store.dispatch('setMenuList', menuListClone)
    },
    queryLoginSchoolList() {
      let this_ = this
      let token = sessionStorage.getItem('token')
      this_.$axios.defaults.headers.common['token'] = token
      if (token != null && token != '') {
        this.$axios
          .get('/v1/auth/query/login/school/list?token=' + token)
          .then(function (res) {
            if (res.data.code == 10000) {
              this_.loginSchoolList = []
              for (let i = 0; i < res.data.response.length; i++) {
                if (res.data.response[i].relationSchoolId != sessionStorage.getItem('schoolId')) {
                  this_.loginSchoolList.push(res.data.response[i])
                } else {
                  this_.loginSchoolList.push(res.data.response[i])
                }
              }
            }
          })
          .catch(function (error) {})
      }
    },
    async getSchoolDetail(schoolId) {
      const res = await cloudMatch.getSchoolDetail({ schoolId: schoolId ? schoolId : Util.getStorage('schoolId') })
      if (res.data.code === 10000) {
        const { schoolLevel, qiangjiSchoolLevel } = res.data.response
        this.schoolLevel = this.isQiangji ? qiangjiSchoolLevel : schoolLevel
      }
    }
  },
  created() {
    /** 强基未来更换主题色 **/
    const { qiangjiURL } = this.$axios.defaults
    const body = document.querySelector('body')
    if (window.location.hostname === qiangjiURL) {
      this.isQiangji = true
      body.classList.add('theme-simple')
    } else {
      body.classList.add('theme-default')
    }
  },
  mounted() {
    this.getSchoolWasNotice()
    this.$store.commit('updateMenulist')
    this.schoolLogo = sessionStorage.getItem('schoolLogo')
    this.weekCount = sessionStorage.getItem('weekCount') // 当前周数
    this.semesterStatus = sessionStorage.getItem('semesterStatus') // 当前学期
    this.weekDay = '日一二三四五六'.charAt(new Date().getDay())
    this.showTime()
    this.queryLoginSchoolList()
    const { schoolId } = this.$route.query

    this.loginByToken(0)
    this.getMessageList()
    setTimeout(() => {
      window.scrollTo(0, sessionStorage.getItem('classMonitoringData-classMonitoringStudentDetail-scrollIndex') || 0)
    }, 4000)
  },
  beforeDestory() {
    clearTimeout(window.MainInterVal)
    window.MainInterVal = null
    this.changeFullScreen(true)
    this.timer = null
  }
}
</script>
