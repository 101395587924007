var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "top_menu", on: { click: _vm.handleMenu } },
    [
      _c(
        "Tabs",
        {
          attrs: { value: _vm.activeTopMenuName },
          on: { "on-click": _vm.changeTab },
        },
        _vm._l(_vm.menuList, function (item, index) {
          return _c("TabPane", {
            key: index + item,
            staticClass: "topMenuItem",
            attrs: {
              label: item.parentName || item.name,
              name: item.parentCode + "-" + item.name,
              id: item.name,
              disabled: item.disabled,
              icon: item.parentIcon,
            },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }