import axios from 'axios'
import md5 from 'js-md5';
import store from '@/store';
import { router } from '@/router'

import {
  DEVELOP_BASE_URL_PATH,
  RELEASE_BASE_URL_PATH,
  DEVELOP_Z_H_URL_PATH,
  RELEASE_Z_H_URL_PATH,
  DEVELOP_QIANG_JI_URL_PATH,
  RELEASE_QIANG_JI_URL_PATH,
  DEVELOP_SHUANG_LIU_URL_PATH,
  RELEASE_SHUANG_LIU_URL_PATH,
  DEVELOP_WJ_KC_URL_PATH,
  RELEASE_WJ_KC_URL_PATH,
  DEVELOP_JIA_FA_URL_PATH,
  RELEASE_JIA_FA_URL_PATH,
  DEVELOP_ANSWER_URL_PATH,
  RELEASE_ANSWER_URL_PATH
} from '@/libs/urlConfig.js'


// 开发环境配置
const devConfig = {
  baseURL: DEVELOP_BASE_URL_PATH,
  zhURL: DEVELOP_Z_H_URL_PATH,
  qiangjiURL: DEVELOP_QIANG_JI_URL_PATH,
  shuangliuURL: DEVELOP_SHUANG_LIU_URL_PATH,
  wjkcURL: DEVELOP_WJ_KC_URL_PATH,
  jiafaURL: DEVELOP_JIA_FA_URL_PATH,
  answerUrl: DEVELOP_ANSWER_URL_PATH,
};
// 生产环境配置
const prodConfig = {
  baseURL: RELEASE_BASE_URL_PATH,
  zhURL: RELEASE_Z_H_URL_PATH,
  qiangjiURL: RELEASE_QIANG_JI_URL_PATH,
  shuangliuURL: RELEASE_SHUANG_LIU_URL_PATH,
  wjkcURL: RELEASE_WJ_KC_URL_PATH,
  jiafaURL: RELEASE_JIA_FA_URL_PATH,
  answerUrl: RELEASE_ANSWER_URL_PATH,
};
const domainConfig =
  process.env.NODE_ENV === 'development'
    ? devConfig
    : prodConfig


axios.defaults.timeout = 10000
axios.defaults.headers.common['Content-Type'] = "application/json;charset=UTF-8"
Object.assign(axios.defaults, domainConfig)


axios.interceptors.response.use(
  response => {
    if (response.data.code === 30003 || response.data.code === 30005) {
      // 登录过期
      if (sessionStorage.getItem('token')) {
        const { jiafaURL } = axios.defaults;
        const { isDefaultLogout } = useLoginOut()
        // 如果是双流就直接退出，否则就正常弹窗重新登录
        if (!isDefaultLogout) {
          sessionStorage.clear()
          window.location.replace(`${jiafaURL}/sso/logout`);
        } else {
          store.commit('setLoginStatus', true);
        }
        return;
      } else {
        router.push({ name: 'login' })
      }
    }
    return response;
  },
  error => {
    return error;
  });

axios.interceptors.request.use(config => {

  if (config.method === 'post') {
    for (let o in config.data) {
      config.data[o] = trim(config.data[o]);
    }
  }
  let param = (config.url.split('?').length > 1) ? config.url.split('?')[1] : '';
  for (let i in param) {
    if (param[i] == undefined) {
      param[i] = '';
    }
  }
  let paramStr = '';
  let paramMap = new Map();
  let keyList = [];
  let haveParam = false;
  if (param) {
    let params = param.split('&');
    if (params && params.length > 0) {
      for (let val of params) {
        if (val && val.length > 0) {
          paramMap.set(val.split('=')[0], trim(val.split('=')[1]));
          keyList.push(val.split('=')[0]);
          haveParam = true;
        }
      }
    }
  }
  if (haveParam) {
    let keyListSort = keyList.sort();
    for (let keyItem of keyListSort) {
      if (paramMap.get(keyItem)) {
        if (paramStr) {
          paramStr = paramStr + keyItem + paramMap.get(keyItem);
        } else {
          paramStr = keyItem + paramMap.get(keyItem);
        }
      }
    }
  }
  paramStr = `${paramStr}355b61ed8d0648fa56E39A1658CA0C4FFF62B0425C0FD16055A21676`;
  let jsonData = JSON.stringify(config.data);
  if (config.data) {
    paramStr = paramStr + jsonData;
  };
  paramStr = paramStr.replace(/ /g, '').replace(/!/g, '').replace(/~/g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/\'/g, '');
  paramStr = md5(encodeURIComponent(paramStr)).toUpperCase();
  paramStr = paramStr + 'encodeutf8';
  config.headers['sign'] = paramStr;
  config.headers['appKey'] = '355b61ed8d0648fa';
  config.headers['token'] = sessionStorage.getItem('token');

  return config;
}, err => {
  return Promise.resolve(err);
})



// 正常退出,还是跳转单点退出
function useLoginOut() {
  const { shuangliuURL } = axios.defaults;
  let { origin } = window.location;
  let isDefaultLogout = origin === shuangliuURL ? false : true;
  return {
    isDefaultLogout
  }
}

/*只做了第一层参数的去空格*/
function trim(s) {
  if (typeof (s) == 'string') {
    s = s.replace(/(^\s*)|(\s*$)/g, '');
  }
  return s;
}


export default axios