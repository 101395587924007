
const user = {
  state: {

  },

  mutations: {
    logout(state, vm) {
      sessionStorage.clear();
    }
  }
};

export default user;
