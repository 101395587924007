var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login_box" }, [
      _c("div", { staticClass: "welcome-container" }, [
        _c("div", { staticClass: "welcome-left flex-c" }, [
          _c("img", {
            staticClass: "company-logo",
            attrs: {
              src: require("../../images/login/login-logo.png"),
              alt: "小马健康",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "aside-container" }, [
            _c("div", { staticClass: "wel-title" }, [
              _vm._v("智慧体育教育SaaS云平台"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "wel-saide flex-c" }, [
              _c("img", {
                staticClass: "saide-logo",
                attrs: {
                  src: require("../../images/login/login-warning.png"),
                  alt: "小马健康",
                },
              }),
              _c("span", [
                _vm._v("当前账号无登录权限，请联系小马健康平台管理员。"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "funs" }, [
              _c("span", [_vm._v("联系方式：")]),
              _c("span", { staticClass: "wel11" }, [_vm._v("400-666-1816")]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "welcome-right" }, [
          _c("img", {
            staticClass: "company-aside",
            attrs: {
              src: require("../../images/login/login-welcome.png"),
              alt: "小马健康",
            },
          }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }