import Vue from 'vue';
import iView from 'iview';
import Util from '../libs/util';
import VueRouter from 'vue-router';
import { routers } from './router';
Vue.use(VueRouter);
const RouterConfig = {
  routes: routers
};
export const router = new VueRouter(RouterConfig);

// 路由拦截
router.beforeEach((to, from, next) => {

  if (from.name) {
    sessionStorage.setItem('fromName', from.name);
  }
  //课堂监控数据页面切换路由则清空滚动数据
  if (to.name !== 'classMonitoringData' && to.name !== 'classMonitoringStudentDetail') {
    sessionStorage.setItem('classMonitoringData-classMonitoringStudentDetail-scrollIndex', 0);
  }
  // 如果缓存中有左侧列表，设置选中状态
  if (sessionStorage.getItem('menuList') != null && sessionStorage.getItem('menuList') != '' && sessionStorage.getItem('menuList') != undefined) {
    const menuList = sessionStorage.getItem('menuList').split(',');
    let activeName = ''
    // 如果是从欢迎页过来并且是跳往系统通知页面，强制设置 selectedName
    if (from.name === 'page-welCome' && to.name === 'systemNoticeList') {
      sessionStorage.setItem('selectedName', 'top_psychological-systemNotice')
    } else {
      activeName = sessionStorage.getItem('activeName');
    }
    let isHaveName = menuList.some((val, index, arr) => { return val == to.name; });

    // 如果列表中包含当前的name就设置其选中
    if (!isHaveName) {
      if (activeName != null && activeName != '' && activeName != undefined) {
        setTimeout(() => {
          if (document.getElementById(activeName)) {
            document.getElementById(activeName).parentNode.parentNode.className = 'ivu-menu-submenu ivu-menu-item-active ivu-menu-opened';
            let elementArr = siblings(document.getElementById(activeName));
            for (let vals of elementArr) { vals.className = 'ivu-menu-item'; }
            setTimeout(() => {
              document.getElementById(activeName).className = 'ivu-menu-item ivu-menu-item-active ivu-menu-item-selected';
            }, 10);
          }
        }, 100);
      }
    } else {
      sessionStorage.setItem('activeName', to.name);
      setTimeout(() => {
        if (document.getElementById(to.name)) {
          document.getElementById(to.name).parentNode.parentNode.className = 'ivu-menu-submenu ivu-menu-item-active ivu-menu-opened';
          let elementArr = siblings(document.getElementById(to.name));
          for (let vals of elementArr) { vals.className = 'ivu-menu-item'; }
          setTimeout(() => {
            document.getElementById(to.name).className = 'ivu-menu-item ivu-menu-item-active ivu-menu-item-selected';
          }, 10)
        }
      }, 100);
    }
  }
  iView.LoadingBar.start();
  Util.title(to.meta.title);
  next();
});

router.afterEach((to) => {
  iView.LoadingBar.finish();
  window.scrollTo(0, 0);
});

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}



function siblings(elm) {
  if (!elm) { return; }
  var a = [];
  var p = elm.parentNode.children;
  for (var i = 0, pl = p.length; i < pl; i++) {
    if (p[i] !== elm) a.push(p[i]);
  }
  return a;
}
