<template>
  <Modal
    v-model="visable"
    title="学校基础配置"
    :closable="false"
    :mask-closable="false"
    width="800"
    ok-text="提交"
    cancel-text="取消"
  >
    <Form ref="unirunInfo" :model="unirunInfo">
      <FormItem label="">
        <Row style="margin-bottom: 30px">
          <Label>学年下学期标准配置 ：</Label>
          <Select v-model="unirunInfo.firstSemesterDateStart_month" placeholder="月" style="width: 60px">
            <Option v-for="item in months" :value="item.value" :key="item.value">{{ item.name }}</Option>
          </Select>
          &nbsp;&nbsp;月&nbsp;&nbsp;
          <Select v-model="unirunInfo.firstSemesterDateStart_day" placeholder="日" style="width: 60px">
            <Option v-for="item in days" :value="item.value" :key="item.value">{{ item.name }}</Option>
          </Select>
          &nbsp;&nbsp;日
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;至&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Select v-model="unirunInfo.firstSemesterDateEnd_month" placeholder="月" style="width: 60px">
            <Option v-for="item in months" :value="item.value" :key="item.value">{{ item.name }}</Option>
          </Select>
          &nbsp;&nbsp;月&nbsp;&nbsp;
          <Select v-model="unirunInfo.firstSemesterDateEnd_day" placeholder="日" style="width: 60px">
            <Option v-for="item in days" :value="item.value" :key="item.value">{{ item.name }}</Option>
          </Select>
          &nbsp;&nbsp;日
        </Row>
        <Row style="margin-top: 5px">
          <Label>学年下学期开始日期 ：</Label>
          <Select v-model="unirunInfo.secondSemesterDateStart_month" placeholder="月" style="width: 60px">
            <Option v-for="item in months" :value="item.value" :key="item.value">{{ item.name }}</Option>
          </Select>
          &nbsp;&nbsp;月&nbsp;&nbsp;
          <Select v-model="unirunInfo.secondSemesterDateStart_day" placeholder="日" style="width: 60px">
            <Option v-for="item in days" :value="item.value" :key="item.value">{{ item.name }}</Option>
          </Select>
          &nbsp;&nbsp;日
        </Row>
      </FormItem>
      <FormItem>
        <Row>
          <Col span="5">
            <Label>学年下学期总课时数 ：</Label>
          </Col>
          <Col span="19">
            <div v-if="schoolType == '2'">
              <div style="display: inline-block; margin-right: 20px; margin-bottom: 20px">
                <span>一年级 ：</span>
                <Input style="width: 100px" v-model="semesterClass.oneCount" :maxlength="20" />
              </div>
              <div style="display: inline-block; margin-right: 20px; margin-bottom: 20px">
                <span>二年级 ：</span>
                <Input style="width: 100px" v-model="semesterClass.twoCount" :maxlength="20" />
              </div>
              <div style="display: inline-block; margin-right: 20px; margin-bottom: 20px">
                <span>三年级 ：</span>
                <Input style="width: 100px" v-model="semesterClass.threeCount" :maxlength="20" />
              </div>
              <div style="display: inline-block; margin-right: 20px; margin-bottom: 20px">
                <span>四年级 ：</span>
                <Input style="width: 100px" v-model="semesterClass.fourCount" :maxlength="20" />
              </div>
              <div style="display: inline-block; margin-right: 20px; margin-bottom: 20px">
                <span>五年级 ：</span>
                <Input style="width: 100px" v-model="semesterClass.fiveCount" :maxlength="20" />
              </div>
              <div style="display: inline-block; margin-right: 20px; margin-bottom: 20px">
                <span>六年级 ：</span>
                <Input style="width: 100px" v-model="semesterClass.sixCount" :maxlength="20" />
              </div>
            </div>
            <div v-if="schoolType == '3'">
              <div style="display: inline-block; margin-right: 20px; margin-bottom: 20px">
                <span>七年级 ：</span>
                <Input style="width: 100px" v-model="semesterClass.sevenCount" :maxlength="20" />
              </div>
              <div style="display: inline-block; margin-right: 20px; margin-bottom: 20px">
                <span>八年级 ：</span>
                <Input style="width: 100px" v-model="semesterClass.eightCount" :maxlength="20" />
              </div>
              <div style="display: inline-block; margin-right: 20px; margin-bottom: 20px">
                <span>九年级 ：</span>
                <Input style="width: 100px" v-model="semesterClass.nineCount" :maxlength="20" />
              </div>
            </div>
            <div v-if="schoolType == '4'">
              <div style="display: inline-block; margin-right: 20px; margin-bottom: 20px">
                <span>高一 ：</span>
                <Input style="width: 100px" v-model="semesterClass.tenCount" :maxlength="20" />
              </div>
              <div style="display: inline-block; margin-right: 20px; margin-bottom: 20px">
                <span>高二 ：</span>
                <Input style="width: 100px" v-model="semesterClass.elevenCount" :maxlength="20" />
              </div>
              <div style="display: inline-block; margin-right: 20px; margin-bottom: 20px">
                <span>高三 ：</span>
                <Input style="width: 100px" v-model="semesterClass.twelveCount" :maxlength="20" />
              </div>
            </div>
          </Col>
        </Row>
      </FormItem>
      <FormItem>
        <RadioGroup v-model="timetable.old" style="width: 100%">
          <Row>
            <Col span="5">
              <Label>
                <span style="visibility: hidden">学年下学期总课</span>
                课表 ：
              </Label>
            </Col>
            <Col span="8">
              <Radio label="old">
                <span>延用上学期课表</span>
              </Radio>
            </Col>
            <Col span="8">
              <Radio label="new">
                <span>新课表</span>
              </Radio>
            </Col>
          </Row>
        </RadioGroup>
      </FormItem>
      <FormItem>
        <RadioGroup v-model="timetable.count" style="width: 100%">
          <Row>
            <Col span="5">
              <Label>
                <span style="visibility: hidden">学年下</span>
                课后作业配置 ：
              </Label>
            </Col>
            <Col span="8">
              <Radio label="old">
                <span>延用上学期课后作业次数配置</span>
              </Radio>
            </Col>
            <Col span="8">
              <Radio label="new">
                <span>重新设置课后作业次数</span>
              </Radio>
            </Col>
          </Row>
        </RadioGroup>
        <RadioGroup v-model="timetable.contentCopy" style="width: 100%">
          <Row>
            <Col span="8" offset="5">
              <Radio label="old">
                <span>延用上学期课后作业内容配置</span>
              </Radio>
            </Col>
            <Col span="8">
              <Radio label="new">
                <span>重新设置课后作业内容</span>
              </Radio>
            </Col>
          </Row>
        </RadioGroup>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button type="success" @click="basicsOk">保存</Button>
    </div>
  </Modal>
</template>

<script>
// import { days } from '../../libs/day'
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    unirunInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    semesterClass: {
      type: Object,
      default: () => {
        return {}
      }
    },
    timetable: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      schoolType: sessionStorage.getItem('schoolType')
    }
  },
  computed: {
    visable: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    months() {
      return Array.from({ length: 12 }, (_, i) => ({
        name: i + 1,
        value: (i + 1).toString().padStart(2, '0')
      }))
    },
    days() {
      return Array.from({ length: 31 }, (_, i) => {
        return {
          name: i + 1,
          value: (i + 1).toString().padStart(2, '0')
        }
      })
    }
  },
  methods: {
    basicsOk() {
      // this.visable = false
      this.$emit('on-ok', 1)
    }
  }
}
</script>

<style lang="less" scoped></style>
