import Main from '@/views/MAIN/Main.vue';
export default [
    {
        path: '/healthknowledge',
        icon: 'ios-book',
        name: 'healthknowledge',
        title: '健康知识',
        parentCode: 'top_healthknowledge',
        parentName: '健康知识',
        parentIcon: 'ios-book',
        disabled: false,
        component: Main,
        children: [
            {
                path: 'paperManage',
                icon: 'ios-list-box-outline',
                name: 'paperManage',
                title: '试卷管理',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/health_knowledge/paperManage.vue'], resolve);
                }
            },
            {
                path: 'paperScoreManage',
                icon: 'ios-list-box-outline',
                name: 'paperScoreManage',
                title: '成绩管理',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/health_knowledge/paperScoreManage.vue'], resolve);
                }
            },
            {
                path: 'paperClassResults',
                icon: 'ios-list-box-outline',
                name: 'paperClassResults',
                title: '班级成绩',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/health_knowledge/paperClassResults.vue'], resolve);
                }
            },
            {
                path: 'paperEntryResults',
                icon: 'ios-list-box-outline',
                name: 'paperEntryResults',
                title: '录入成绩',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/health_knowledge/paperEntryResults.vue'], resolve);
                }
            },
            {
                path: 'paperClassStudentResults',
                icon: 'ios-list-box-outline',
                name: 'paperClassStudentResults',
                title: '修改单个学生成绩',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/health_knowledge/paperClassStudentResults.vue'], resolve);
                }
            },
            {
                path: 'paperManageDetail',
                icon: 'ios-list-box-outline',
                name: 'paperManageDetail',
                title: '试卷详情',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/health_knowledge/paperManageDetail.vue'], resolve);
                }
            },
            {
                path: 'healthKonowledge-importResult',
                icon: 'ios-list-box-outline',
                name: 'healthKonowledge-importResult',
                title: '健康知识成绩导入',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/public_import_report/commonImport/healthKnowledge/healthKnowledgeScore.vue'], resolve);
                }
            }
        ]
    }
]