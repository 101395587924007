var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main", class: { "main-hide-text": _vm.shrink } },
    [
      !_vm.isFullscreen
        ? _c(
            "div",
            {
              staticClass: "sidebar-menu-con",
              style: {
                width: _vm.shrink ? "4.6875vw" : "13.0208vw",
                overflow: _vm.shrink ? "visible" : "auto",
              },
            },
            [
              _c(
                "shrinkable-menu",
                {
                  ref: "shrinkable",
                  attrs: {
                    shrink: _vm.shrink,
                    "menu-list": _vm.menuList,
                    "open-names": _vm.openNames,
                  },
                },
                [
                  _vm.isQiangji
                    ? _c("template", { slot: "top" }, [
                        _c("div", { staticClass: "qiangji_title fcc" }, [
                          _c("img", {
                            staticClass: "min-logo",
                            attrs: {
                              src: require("../../images/logo_qiangji.png"),
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", [_vm._v("强基未来")]),
                        ]),
                      ])
                    : _c(
                        "div",
                        {
                          class:
                            _vm.schoolName.length < 10
                              ? "logo-con"
                              : "logo-con-more",
                          staticStyle: { "background-color": "white" },
                          attrs: { slot: "top" },
                          slot: "top",
                        },
                        [
                          _c("img", {
                            staticClass: "min-logo",
                            attrs: {
                              src: require("../../images/schoolLogo.png"),
                            },
                          }),
                          _vm._v(" "),
                          !_vm.shrink
                            ? _c("div", { staticClass: "schoolName-tit" }, [
                                _vm._v(_vm._s(_vm.schoolName)),
                              ])
                            : _vm._e(),
                        ]
                      ),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isFullscreen
        ? _c(
            "div",
            {
              staticClass: "main-header-con",
              style: { paddingLeft: _vm.shrink ? "4.6875vw" : "13.0208vw" },
            },
            [
              _c("div", { staticClass: "main-header" }, [
                _c(
                  "div",
                  { staticClass: "navicon-con" },
                  [
                    _c("topMenu", {
                      ref: "tpoMenu",
                      attrs: {
                        "menu-list": _vm.menuListAll,
                        theme1: _vm.theme1,
                        schoolLevel: _vm.schoolLevel,
                        isQiangji: _vm.isQiangji,
                      },
                      on: {
                        "on-click": _vm.handleChange,
                        "on-change": _vm.handleOpenNames,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "header-avator-con p16" }, [
                  !_vm.shrink
                    ? _c(
                        "div",
                        {
                          staticClass: "nowTimes",
                          style: { color: _vm.themeActiveColor },
                        },
                        [
                          _c("p", [
                            _c("strong", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.semesterStatus == 1
                                      ? "第一学期"
                                      : _vm.semesterStatus == 2
                                      ? "寒假"
                                      : _vm.semesterStatus == 3
                                      ? "第二学期"
                                      : "暑假"
                                  ) +
                                  "\n              "
                              ),
                              _vm.semesterStatus == 1 || _vm.semesterStatus == 3
                                ? _c("span", [
                                    _vm._v(
                                      "第" +
                                        _vm._s(_vm.weekCount) +
                                        "周 星期" +
                                        _vm._s(_vm.weekDay)
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(_vm.nowTime))]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "user-dropdown-menu-con ml-20" }, [
                    _c(
                      "div",
                      { staticClass: "user-dropdown-innercon flex-c" },
                      [
                        _c(
                          "Dropdown",
                          {
                            staticStyle: { flex: "1" },
                            attrs: { transfer: "", trigger: "click" },
                            on: { "on-click": _vm.handleClickUserDropdown },
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "flex-c",
                                attrs: { href: "javascript:void(0)" },
                              },
                              [
                                _c("span", { staticClass: "main-user-name" }, [
                                  _vm._v(_vm._s(_vm.userName)),
                                ]),
                                _vm._v(" "),
                                _c("Icon", {
                                  attrs: { type: "md-arrow-dropdown" },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "DropdownMenu",
                              { attrs: { slot: "list" }, slot: "list" },
                              [
                                _vm._l(
                                  _vm.loginSchoolList,
                                  function (item, index) {
                                    return _vm.loginSchoolList &&
                                      _vm.loginSchoolList.length > 1
                                      ? _c(
                                          "DropdownItem",
                                          {
                                            key: index,
                                            staticStyle: {
                                              "margin-top": "0px",
                                            },
                                            attrs: {
                                              name: item.relationSchoolId,
                                              divided: "",
                                              selected:
                                                _vm.schoolName ===
                                                item.relationSchoolName,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  item.relationSchoolName
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        )
                                      : _vm._e()
                                  }
                                ),
                                _vm._v(" "),
                                _c(
                                  "DropdownItem",
                                  {
                                    staticStyle: { "margin-top": "0px" },
                                    attrs: { name: "loginout", divided: "" },
                                  },
                                  [_vm._v("退出登录")]
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "mainBox",
          staticClass: "single-page-con",
          style: _vm.getContentPosition /** 手动计算position */,
        },
        [
          !_vm.isFullscreen
            ? _c("div", { staticClass: "glob-message" }, [
                _vm.messageList.length && _vm.schoolLevel != "2"
                  ? _c(
                      "div",
                      {
                        staticClass: "circle",
                        style: { color: _vm.themeActiveColor },
                        on: { click: _vm.clickShowMessage },
                      },
                      [
                        _vm._v("\n        待办\n        "),
                        _c("br"),
                        _vm._v("\n        事项\n      "),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showMessageBox && _vm.schoolLevel != "2"
                  ? _c("div", { staticClass: "message-box" }, [
                      _c("div", [
                        _c("div", { staticClass: "message-box-title" }, [
                          _vm.messageList[_vm.pageIndex].noticeType == 2
                            ? _c("img", {
                                staticClass: "icon_horn",
                                attrs: {
                                  src: require("../../images/icon_horn.png"),
                                  alt: "",
                                },
                              })
                            : _c("img", {
                                staticClass: "icon_horn",
                                attrs: {
                                  src: require("../../images/icon_submit.png"),
                                  alt: "",
                                },
                              }),
                          _vm._v(" "),
                          _vm.messageList[_vm.pageIndex].noticeType
                            ? _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.getNoticeName(
                                        _vm.messageList[_vm.pageIndex]
                                          .noticeType
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "message-box-content" }, [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.messageList[_vm.pageIndex].noticeContent
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "time" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.messageList[_vm.pageIndex].noticeType ==
                                    3 ||
                                    _vm.messageList[_vm.pageIndex].noticeType ==
                                      4
                                    ? "时间："
                                    : "截止时间："
                                ) +
                                "\n              " +
                                _vm._s(_vm.messageList[_vm.pageIndex].endTime) +
                                "\n            "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "message-box-footer" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "shouqi",
                                on: { click: _vm.cancelMessageBox },
                              },
                              [
                                _c("span", [_vm._v("收起")]),
                                _vm._v(" "),
                                _c("img", {
                                  staticClass: "icon_arrow_top",
                                  attrs: {
                                    src: require("../../images/icon_arrow_top.png"),
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.messageList[_vm.pageIndex].noticeType == 3 ||
                            _vm.messageList[_vm.pageIndex].noticeType == 4
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "Button",
                                      {
                                        staticStyle: { width: "5.2083vw" },
                                        attrs: { type: "success" },
                                        on: { click: _vm.readMessage },
                                      },
                                      [_vm._v("已读本条")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("Page", {
                              attrs: {
                                current: _vm.pageIndex + 1,
                                total: _vm.pageTotal * 10,
                                simple: "",
                              },
                              on: { "on-change": _vm.changeMessage },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { ref: "signglePage", staticClass: "single-page" },
            [_c("router-view", { key: _vm.$route.fullPath })],
            1
          ),
          _vm._v(" "),
          _c(
            "Modal",
            {
              attrs: { closable: false, "mask-closable": false, width: "400" },
              model: {
                value: _vm.loginAgain,
                callback: function ($$v) {
                  _vm.loginAgain = $$v
                },
                expression: "loginAgain",
              },
            },
            [
              _c(
                "p",
                {
                  staticClass: "userSign",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(!_vm.loginStep ? "温馨提示" : "重新登录")),
                  ]),
                ]
              ),
              _vm._v(" "),
              !_vm.loginStep
                ? _c("div", { staticClass: "again_content" }, [
                    _vm._v("由于长时间未操作，系统已自动退出登录。"),
                  ])
                : _c(
                    "div",
                    { staticClass: "formData" },
                    [
                      _c(
                        "Form",
                        {
                          ref: "loginForm",
                          attrs: { model: _vm.againParams, rules: _vm.rules },
                        },
                        [
                          _c(
                            "FormItem",
                            {
                              staticClass: "login-item",
                              attrs: { prop: "userPhone" },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("../../images/userName.png"),
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c("Input", {
                                staticClass: "login-name",
                                attrs: {
                                  placeholder: "请输入账号",
                                  maxlength: 11,
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.againParams.userPhone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.againParams, "userPhone", $$v)
                                  },
                                  expression: "againParams.userPhone",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "FormItem",
                            {
                              staticClass: "login-item",
                              attrs: { prop: "againPassword" },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("../../images/password.png"),
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c("Input", {
                                staticClass: "login-name",
                                attrs: {
                                  type: "password",
                                  placeholder: "请输入密码",
                                  minlength: 6,
                                },
                                model: {
                                  value: _vm.againParams.againPassword,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.againParams,
                                      "againPassword",
                                      $$v
                                    )
                                  },
                                  expression: "againParams.againPassword",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "FormItem",
                            {
                              staticClass: "login-item",
                              attrs: { prop: "code" },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("../../images/code.png"),
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c("Input", {
                                staticClass: "login-names",
                                attrs: {
                                  placeholder: "请输入验证码",
                                  maxlength: 4,
                                },
                                model: {
                                  value: _vm.againParams.code,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.againParams, "code", $$v)
                                  },
                                  expression: "againParams.code",
                                },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "code-img",
                                attrs: {
                                  alt: "",
                                  src: _vm.againParams.codeSource,
                                },
                                on: { click: _vm.changeCode },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "reload",
                                attrs: {
                                  alt: "",
                                  src: require("../../images/reload.png"),
                                },
                                on: { click: _vm.changeCode },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "Button",
                    {
                      attrs: { type: "success" },
                      on: { click: _vm.cancelLogin },
                    },
                    [_vm._v("退出")]
                  ),
                  _vm._v(" "),
                  !_vm.loginStep
                    ? _c(
                        "Button",
                        {
                          attrs: { type: "success" },
                          on: { click: _vm.isAgainLogin },
                        },
                        [_vm._v("重新登录")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.loginStep
                    ? _c(
                        "Button",
                        {
                          attrs: { type: "success" },
                          on: { click: _vm.againLogin },
                        },
                        [_vm._v("登录")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("SemesterConfigModal", {
        attrs: { limitStatus: _vm.limitStatus },
        on: { "on-config": _vm.goConfigure, "on-ok": _vm.handleConfigModalOk },
        model: {
          value: _vm.isShowSemesterConfig,
          callback: function ($$v) {
            _vm.isShowSemesterConfig = $$v
          },
          expression: "isShowSemesterConfig",
        },
      }),
      _vm._v(" "),
      _c("SchoolBaseConfigModal", {
        attrs: {
          unirunInfo: _vm.unirunInfo,
          semesterClass: _vm.semesterClass,
          timetable: _vm.timetable,
        },
        on: { "on-ok": _vm.handleConfigModalOk },
        model: {
          value: _vm.isShowSchoolBaseConfig,
          callback: function ($$v) {
            _vm.isShowSchoolBaseConfig = $$v
          },
          expression: "isShowSchoolBaseConfig",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }