import Main from '@/views/MAIN/Main.vue';
export default [
  {
    path: '/dataReport',
    icon: 'md-mail',
    name: 'dataReport',
    title: '国网数据报送',
    parentCode: 'top_dataReport',
    parentName: '数据报送',
    parentIcon: 'md-mail',
    disabled: false,
    component: Main,
    children: [
      {
        path: 'reportStudentManage',
        icon: 'ios-list-box-outline',
        name: 'reportStudentManage',
        title: '国网学生管理',
        component: resolve => {
          require(['@/views/COMMON_VIEW/data_submission/state_grid/reportStudentManage.vue'], resolve);
        }
      },
      {
        path: 'nationReport',
        icon: 'ios-list-box-outline',
        name: 'nationReport',
        title: '体质健康数据上报',
        component: resolve => {
          require(['@/views/COMMON_VIEW/data_submission/state_grid/nationReport.vue'], resolve);
        }
      },
      {
        path: 'importStudentInfo',
        icon: 'ios-list-box-outline',
        name: 'importStudentInfo',
        title: '初始数据导入',
        component: resolve => {
          require(['@/views/COMMON_VIEW/data_submission/state_grid/importStudentInfo.vue'], resolve);
        }
      },
      {
        path: 'importStudentInfoNow',
        icon: 'ios-list-box-outline',
        name: 'importStudentInfoNow',
        title: '初始数据导入',
        component: resolve => {
          require(['@/views/COMMON_VIEW/data_submission/state_grid/importStudentInfoNow.vue'], resolve);
        }
      },
      {
        path: 'nationReportDetail',
        icon: 'ios-list-box-outline',
        name: 'nationReportDetail',
        title: '体质健康数据上报详情',
        component: resolve => {
          require(['@/views/COMMON_VIEW/data_submission/state_grid/nationReportDetail.vue'], resolve);
        }
      },
      {
        path: 'studentSubjectReportDetail',
        icon: 'ios-list-box-outline',
        name: 'studentSubjectReportDetail',
        title: '学生成绩详情',
        component: resolve => {
          require(['@/views/COMMON_VIEW/data_submission/state_grid/studentSubjectReportDetail.vue'], resolve);
        }
      },
      {
        path: 'semesterReportedBaseDetail',
        icon: 'ios-list-box-outline',
        name: 'semesterReportedBaseDetail',
        title: '基础数据上报详情',
        component: resolve => {
          require(['@/views/COMMON_VIEW/data_submission/state_grid/semesterReportedBaseDetail.vue'], resolve);
        }
      },
      {
        path: 'parentReportChangeStudent',
        icon: 'ios-list-box-outline',
        name: 'parentReportChangeStudent',
        title: '成绩变动学生',
        component: resolve => {
          require(['@/views/COMMON_VIEW/data_submission/state_grid/parentReportChangeStudent.vue'], resolve);
        }
      },
      {
        path: 'fixErrorData',
        icon: 'ios-list-box-outline',
        name: 'fixErrorData',
        title: '有误成绩修正',
        component: resolve => {
          require(['@/views/COMMON_VIEW/data_submission/state_grid/fixErrorData.vue'], resolve);
        }
      },
      // {
      //   path: 'parentReportYN',
      //   icon: 'ios-list-box-outline',
      //   name: 'parentReportYN',
      //   title: '家长端成绩推送',
      //   component: resolve => {
      //     require(['@/views/CITY_KM/data_submission/parentReportYN.vue'], resolve);
      //   }
      // },
      {
        path: 'parentReportStudentDetailYN',
        icon: 'ios-list-box-outline',
        name: 'parentReportStudentDetailYN',
        title: '家长端成绩推送成绩详情',
        component: resolve => {
          require(['@/views/CITY_KM/data_submission/parentReportStudentDetailYN.vue'], resolve);
        }
      }

    ]
  },
  {
    path: '/eductionDataReport',
    icon: 'md-mail-open',
    name: 'eductionDataReport',
    title: '教育局数据报送',
    parentCode: 'top_dataReport',
    parentName: '数据报送',
    parentIcon: 'ios-mail-open-outline',
    disabled: false,
    component: Main,
    children: [
      {
        path: 'ecucationReport',
        icon: 'ios-list-box-outline',
        name: 'ecucationReport',
        title: '老教育局上报',
        component: resolve => {
          require(['@/views/COMMON_VIEW/data_submission/bureau_of_education/educationReport.vue'], resolve);
        }
      },
      {
        path: 'physicalTestReport',
        icon: 'ios-list-box-outline',
        name: 'physicalTestReport',
        title: '体质测试上报',
        component: resolve => {
          require(['@/views/COMMON_VIEW/data_submission/bureau_of_education/physicalTestReport.vue'], resolve);
        }
      },
      {
        path: 'physicalTestStudentDetail',
        icon: 'ios-list-box-outline',
        name: 'physicalTestStudentDetail',
        title: '学生体测成绩详情',
        component: resolve => {
          require(['@/views/COMMON_VIEW/data_submission/bureau_of_education/studentResultDetail/physicalTestStudentDetail.vue'], resolve);
        }
      },
      {
        path: 'visionTestReport',
        icon: 'ios-list-box-outline',
        name: 'visionTestReport',
        title: '视力测试上报',
        component: resolve => {
          require(['@/views/COMMON_VIEW/data_submission/bureau_of_education/visionTestReport.vue'], resolve);
        }
      },
      {
        path: 'visionTestStudentDetail',
        icon: 'ios-list-box-outline',
        name: 'visionTestStudentDetail',
        title: '学生视力成绩详情',
        component: resolve => {
          require(['@/views/COMMON_VIEW/data_submission/bureau_of_education/studentResultDetail/visionTestStudentDetail.vue'], resolve);
        }
      },
      {
        path: 'subjectEvaluationReport',
        icon: 'ios-list-box-outline',
        name: 'subjectEvaluationReport',
        title: '学科评价上报',
        component: resolve => {
          require(['@/views/COMMON_VIEW/data_submission/bureau_of_education/subjectEvaluationReport.vue'], resolve);
        }
      },
      {
        path: 'subjectEvaluationStudentDetail',
        icon: 'ios-list-box-outline',
        name: 'subjectEvaluationStudentDetail',
        title: '学生学科评价成绩详情',
        component: resolve => {
          require(['@/views/COMMON_VIEW/data_submission/bureau_of_education/studentResultDetail/subjectEvaluationStudentDetail.vue'], resolve);
        }
      },
      {
        path: 'baseDataReport',
        icon: 'ios-list-box-outline',
        name: 'baseDataReport',
        title: '基础数据上报',
        component: resolve => {
          require(['@/views/COMMON_VIEW/data_submission/bureau_of_education/baseDataReport.vue'], resolve);
        }
      },

      {
        path: 'editAuthority',
        icon: 'ios-list-box-outline',
        name: 'editAuthority',
        title: '编辑权限申请',
        component: resolve => {
          require(['@/views/COMMON_VIEW/data_submission/bureau_of_education/editAuthority.vue'], resolve);
        }
      },
      {
        path: 'visionStudentReportPage',
        icon: 'ios-list-box-outline',
        name: 'visionStudentReportPage',
        title: '上报学生导入',
        component: resolve => {
          require(['@/views/COMMON_VIEW/data_submission/bureau_of_education/visionStudentReportPage.vue'], resolve);
        }
      },
    ]
  },
  {
    path: '/parentReport',
    icon: 'md-filing',
    name: 'parentReport',
    title: '家长端成绩推送',
    parentCode: 'top_dataReport',
    parentName: '数据报送',
    parentIcon: 'ios-mail-open-outline',
    disabled: false,
    component: Main,
    children: [
      {
        path: 'parentPhysicalTestReport',
        icon: 'ios-list-box-outline',
        name: 'parentPhysicalTestReport',
        title: '体测数据推送',
        component: resolve => {
          require(['@/views/COMMON_VIEW/data_submission/parent_end/physicalTest.vue'], resolve);
        }
      },
      {
        path: 'parentVisionTestReport',
        icon: 'ios-list-box-outline',
        name: 'parentVisionTestReport',
        title: '视力数据推送',
        component: resolve => {
          require(['@/views/COMMON_VIEW/data_submission/parent_end/visionTest.vue'], resolve);
        }
      },
      {
        path: 'parentSubjectEvaluationReport',
        icon: 'ios-list-box-outline',
        name: 'parentSubjectEvaluationReport',
        title: '学科评价推送',
        component: resolve => {
          require(['@/views/COMMON_VIEW/data_submission/parent_end/subjectEvaluation.vue'], resolve);
        }
      },
      {
        path: 'parentReportStudentData/:testType',
        name: 'parentReportStudentData',
        title: '学生数据',
        component: resolve => {
          require(['@/views/COMMON_VIEW/data_submission/parent_end/commonDetail.vue'], resolve);
        }
      }
    ]

  },
]