<style lang="less">
    @import './loginError.less';
</style>

<template>
    <div class="login_box">
      <div class="welcome-container">
        <div class="welcome-left flex-c">
          <img class="company-logo" src="../../images/login/login-logo.png" alt="小马健康" />
            <div class="aside-container">
              <div class="wel-title">智慧体育教育SaaS云平台</div>
              <div class="wel-saide flex-c">
                <img class="saide-logo" src="../../images/login/login-warning.png" alt="小马健康" /><span>当前账号无登录权限，请联系小马健康平台管理员。</span>
              </div>
              <div class="funs">
                <span>联系方式：</span><span class="wel11">400-666-1816</span>
              </div>
            </div>
        </div>
        <div class="welcome-right">
          <img class="company-aside" src="../../images/login/login-welcome.png" alt="小马健康" />
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: 'Error500',
    methods: {
        backPage () {
            this.$router.go(-1);
        },
        goHome () {
            this.$router.push({
                name: 'home_index'
            });
        }
    }
};
</script>
