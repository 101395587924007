/**
 * 昆明-数据档案路由
*/
export default [
  {
    path: 'historyStudentDataKM',
    icon: 'ios-list-box-outline',
    name: 'historyStudentDataKM',
    title: '历史数据',
    component: resolve => {
      require(['@/views/CITY_KM/historyData/historyDataIndex.vue'], resolve);
    }
  },
  {
    path: 'hisSemesterItemKM',
    icon: 'ios-list-box-outline',
    name: 'hisSemesterItemKM',
    title: '历史学期数据明细',
    component: resolve => {
      require(['@/views/CITY_KM/historyData/hisSemesterItem/index.vue'], resolve);
    }
  },
  {
    path: 'skillAndPhysicalManageKM',
    icon: 'ios-list-box-outline',
    name: 'skillAndPhysicalManageKM',
    title: '技能/体能管理',
    component: resolve => {
      require(['@/views/CITY_KM/historyData/skillAndPhysicalManage/index.vue'], resolve);
    }
  },
  {
    path: 'studentDetailKM',
    icon: 'ios-list-box-outline',
    name: 'studentDetailKM',
    title: '学生详情',
    component: resolve => {
      require(['@/views/CITY_KM/historyData/skillAndPhysicalManage/pages/studentDetail.vue'], resolve);
    }
  },
  {
    path: 'itemDetailKM',
    icon: 'ios-list-box-outline',
    name: 'itemDetailKM',
    title: '项目详情',
    component: resolve => {
      require(['@/views/CITY_KM/historyData/skillAndPhysicalManage/pages/itemDetail.vue'], resolve);
    }
  },
  {
    path: 'enterResultKM',
    icon: 'ios-list-box-outline',
    name: 'enterResultKM',
    title: '技能/体能成绩录入',
    component: resolve => {
      require(['@/views/CITY_KM/historyData/skillAndPhysicalManage/pages/enterResult.vue'], resolve);
    }
  },
  {
    path: 'hisStudentDataManageKM',
    icon: 'ios-list-box-outline',
    name: 'hisStudentDataManageKM',
    title: '学生管理',
    component: resolve => {
      require(['@/views/CITY_KM/historyData/hisStudentDataMange/index.vue'], resolve);
    }
  },
  {
    path: 'hisPhysicalTestMangeKM',
    icon: 'ios-list-box-outline',
    name: 'hisPhysicalTestMangeKM',
    title: '体质测试管理',
    component: resolve => {
      require(['@/views/CITY_KM/historyData/physicalTestMange/index.vue'], resolve);
    }
  }
]