
let util = {};
util.title = function (title) {
  title = '学校体育与健康管理信息系统'
  window.document.title = title
}


// 秒转分秒-5分20秒
util.s_to_ms = function (s) {
  let m, ms;
  const multiple = 100000;
  m = Math.floor(s * multiple / (60 * multiple)).toString();
  ms = Math.round((s % 60 + Number.EPSILON) * multiple) / multiple;
  return `${m}分${ms}秒`;
}

// 根据传入的数据保留几位小数
util.msAdditive = (result, num) => {
  let mutill = num == '1' ? 100 : num == '2' ? 1000 : num == '3' ? 10000 : 1;
  return Math.round((result + Number.EPSILON) * mutill) / mutill;
}

// 秒转分秒 - 5'20''
util.s_to_hs = function (s) {
  let h;
  const multiple = 100000;
  h = Math.floor(s * multiple / (60 * multiple))
  s = ((s * multiple) % (60 * multiple) / multiple);
  h += ''
  s += ''
  h = h.length === 1 ? '0' + h : h
  s = s.length === 1 ? '0' + s : s
  return `${h} \'${s}\'\'`
}

// 两个数组中是否有包含彼此数组中的数据
util.inOf = function (arr, targetArr) {
  let res = true
  arr.map((item) => {
    if (targetArr.indexOf(item) < 0) {
      res = false
    }
  })
  return res
}

// 数组中是否有包含某个数据
util.oneOf = function (ele, targetArr) {
  if (targetArr.indexOf(ele) >= 0) {
    return true
  } else {
    return false
  }
}

// 返回两位数
util.formatNumber = n => {
  n = n.toString()
  return n[1] ? n : '0' + n
}

// 获取年月日，可传入分隔符，默认斜杠
util.formatTimeMonth = (date, joinType) => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  return [year, month, day].map(util.formatNumber).join(joinType ? joinType : '/');
}

// 获取六个月前的年月日-日期
util.formatTimeSixMonth = date => {
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()
  return util.formatTime(new Date(year, month - 6, day));
}

// 获取随机名称
util.random_string = function (len) {
  len = len || 32
  var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
  var maxPos = chars.length
  var pwd = ''
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd
}

util.hasParams = function (store, route) {
  let pars = null
  if (store.state.pageParams.listPagePars.has(route.name)) {
    pars = store.state.pageParams.listPagePars.get(route.name)
  }
  return pars
}
util.Trim = function (str, global) {
  //去除字符空格
  let result
  result = str.replace(/(^\s+)|(\s+$)/g, '')
  if (global.toLowerCase() == 'g') result = result.replace(/\s/g, '')
  return result
}
const chnNumChar = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
const chnUnitSection = ['', '万', '亿', '万亿', '亿亿']
const chnUnitChar = ['', '十', '百', '千']
util.NumberToChinese = function (num) {
  var unitPos = 0
  var strIns = '',
    chnStr = ''
  var needZero = false
  if (num === 0) {
    return chnNumChar[0]
  }
  while (num > 0) {
    var section = num % 10000
    if (needZero) {
      chnStr = chnNumChar[0] + chnStr
    }
    strIns = util.SectionToChinese(section)
    strIns += section !== 0 ? chnUnitSection[unitPos] : chnUnitSection[0]
    chnStr = strIns + chnStr
    needZero = section < 1000 && section > 0
    num = Math.floor(num / 10000)
    unitPos++
  }
  return chnStr
}

util.SectionToChinese = function (section) {
  var strIns = '',
    chnStr = ''
  var unitPos = 0
  var zero = true
  while (section > 0) {
    var v = section % 10
    if (v === 0) {
      if (!zero) {
        zero = true
        chnStr = chnNumChar[v] + chnStr
      }
    } else {
      zero = false
      strIns = chnNumChar[v]
      strIns += chnUnitChar[unitPos]
      chnStr = strIns + chnStr
    }
    unitPos++
    section = Math.floor(section / 10)
  }
  return chnStr
}

// 处理违法数据
const filterErrorData = (val) => {
  return val === 'ALL' || val == 'NaN' || val == null || val == 'null'|| val == 'undefined' || val == undefined ? '' : val;
}
/**
 * 
 * @param {*} url 
 * @param {*} param 
 * @param {*} isTrim 是否移除空格(参数为日期格式时必须传 false 否则会导致日期参数格式有误查询不出数据！)
 * @returns 
 */
util.createURL = function (url, param, isTrim = true) {
  // 未传参数时处理
  if (Object.prototype.toString.call(param) === '[object Undefined]') return url;

  let urlLink = '';
  Object.keys(param).forEach((key) => {
    param[key] = filterErrorData(param[key]);
    let link = `&${key}=${param[key]}`
    urlLink += link
  })
  urlLink = `${url}${urlLink ? '?' : ''}${urlLink.substring(1)}`
  return isTrim ? urlLink.replace(' ', '') : urlLink
}

util.createParams = function (params) {
  const paramsClone = JSON.parse(JSON.stringify(params))
  for (let key in paramsClone) {
    paramsClone[key] = filterErrorData(paramsClone[key]);
  }
  return paramsClone
}
/**
 * 生成一个用不重复的ID
 * @param { Number } randomLength
 */
util.getUuiD = (randomLength) => {
  return Number(Math.random().toString().substr(2, randomLength) + Date.now()).toString(36)
}
const formatNumber = (n) => {
  n = n.toString()
  return n[1] ? n : '0' + n
}
util.formatTime = (date) => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  return [year, month, day].map(formatNumber).join('/')
}
/**
 * @desc 限制并过滤非正整数和小数点
 */
util.filterDecimalNum = (value, bit) => {
  let str = '';

  if (bit === 0) {
    // 如果 bit 为 0，只保留数字字符
    str = value.replace(/[^\d]/g, '');
  } else {
    // 否则，使用正则表达式匹配并保留最多 bit 位小数
    const regex = new RegExp(`^\\d*\\.?\\d{0,${bit}}`);
    const match = value.match(regex);
    str = match ? match[0] : '';
  }
  return str;
}
util.formatDateTime = (date) => {
  let Year = date.getFullYear();
  let Month = date.getMonth() + 1
  let DateNum = date.getDate()
  let Hours = date.getHours()
  let Minutes = date.getMinutes()
  let Seconds = date.getSeconds()
  return [Year, Month, DateNum].map(util.formatNumber).join('-') + ' ' + [Hours, Minutes, Seconds].map(util.formatNumber).join(':')
}
/**
 * @description 打开并跳转至外部页面
 * @param {*} url 
 */
util.openPage = (url) => {
  if (/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)) {
    let dom = document.createElement('a')
    dom.setAttribute('href', url)
    document.body.appendChild(dom)
    dom.click()
  } else {
    window.open(url)
  }
}
/**
 * 存缓存中的值
 * @param {key} 任意字符
 */
util.setStorage = (key, value) => {
  sessionStorage.setItem(key, value)
}

/**
 * 获取缓存中的值
 * @param {key} 任意字符
 */
util.getStorage = (key) => {
  return sessionStorage.getItem(key)
}

/**
 * 删除缓存中的key-value
 * @param {key} 任意字符
 */
util.removeStorage = (key) => {
  sessionStorage.removeItem(key)
}

util.deepClone = (obj) => {
  // 检查是否为对象类型
  if (typeof obj !== 'object' || obj === null) {
    return obj; // 如果不是对象直接返回
  }
  // 根据原对象的类型创建新的对象
  let newObj;
  if (Array.isArray(obj)) {
    newObj = []; // 如果是数组，创建一个空数组
  } else {
    newObj = {}; // 否则创建一个空对象
  }

  // 遍历原对象的属性
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      // 递归复制每一个属性
      newObj[key] = util.deepClone(obj[key]);
    }
  }
  return newObj; // 返回新对象
}
util.replaceText = (val) => {
  let text = val.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g, '').replace(/\s/g, '')
  return text
}
/**
 * @desc 计算平均得分
 * @param {*} score 得分
 * @param {*} num 学生数量
 * @param {*} point 保留小数点，默认保留1位小数
 * @returns 
 */
util.calculateAverage = (score, num, point = 1) => {
  if (!num || num == null) return 0

  return Number((score / num).toFixed(point))
}
/**
 * @desc 计算百分比
 * @param {*} num1 
 * @param {*} num2 
 * @param {*} point 保留小数点，默认保留2位小数
 * @returns 
 */
util.calculatePercentage = (num1, num2, point = 2) => {
  if (!num2 || num2 == null) return 0
  return Number(((num1 / num2) * 100).toFixed(point)) || 0
}
/**
 * 
 * @param {*} gender 
 * @returns 获取男女性别
 */
util.getGenderName = (gender) => {
  return gender == 1 ? '男' : '女'
}
/**
 * 计算学期数据
 * @param {Number} startYear 初始年限
 * @param {Boolean} isInsertSchoolYear 是否需要插入学年
 * @param {String} sortType descend 降序 ascend 升序
*/
util.factorySemeter = (startYear, isInsertSchoolYear, sortType) => {
  let nowYear = Number(util.getStorage("schoolYear"));//2022
  let nowYearSemester = Number(util.getStorage("yearSemester"));//20222 - 22年第一学期
  let count = 0;
  let yearSemesterList = [];

  while (nowYear > startYear) {
    yearSemesterList.push({
      name: `${startYear}-${startYear + 1}学年第一学期`,
      value: startYear * 10 + 2
    });
    yearSemesterList.push({
      name: `${startYear}-${startYear + 1}学年第二学期`,
      value: startYear * 10 + 11
    });
    if (isInsertSchoolYear) {
      yearSemesterList.push({
        name: `${startYear}-${startYear + 1}学年`,
        value: startYear
      });
    }
    if (count > 10)
      break;
    count++;
    startYear++;
  }

  if (nowYearSemester % 2 === 0) {//第一学期 - 没有学年
    yearSemesterList.push({
      name: `${nowYear}-${nowYear + 1}学年第一学期`,
      value: nowYearSemester
    });
  } else {// 第二学期
    yearSemesterList.push({
      name: `${nowYear}-${nowYear + 1}学年第一学期`,
      value: nowYear * 10 + 2
    });
    yearSemesterList.push({
      name: `${nowYear}-${nowYear + 1}学年第二学期`,
      value: nowYearSemester
    });
    if (isInsertSchoolYear) {
      yearSemesterList.push({
        name: `${nowYear}-${nowYear + 1}学年`,
        value: nowYear
      });
    }
  }
  if (sortType === 'descend') yearSemesterList.reverse();
  return yearSemesterList;
}
/**
 * 计算当前学年及之前的学年
 * 第二学期 - 才有学年
 * @param {Number} startYear 初始年限
 * @param {String} sortType descend 降序 ascend 升序
*/
util.schoolYearComputed = (startYear, sortType) => {
  if (!startYear) {
    throw new Error('schoolYearComputed方法，需传入初始年限');
  }

  let nowYear = Number(util.getStorage("schoolYear"));//2022
  let nowYearSemester = Number(util.getStorage("yearSemester"));//20222 - 22年第一学期
  let count = 0;
  const schoolYearList = [];

  while (nowYear >= startYear) {
    schoolYearList.push({
      name: `${startYear}-${startYear + 1}学年`,
      value: startYear
    });
    if (count > 10)
      break;
    count++;
    startYear++;
  }

  // 体测要显示所有学年
  // if (nowYearSemester % 2 !== 0) {//第二学期 - 才有学年
  //   schoolYearList.push({
  //     name: `${nowYear}学年`,
  //     value: nowYear
  //   });
  // }
  if (sortType === 'descend') schoolYearList.reverse();
  return {
    schoolYearList
  };
}


/**
 * @param {*} yearSemester 学期
 * 根据传入的学期拼接对应学年
 */
export const getSchoolYearName = (yearSemester) => {
  const schoolYear = Math.floor(yearSemester / 10);
  if (yearSemester % 2 === 1) {
    return `${schoolYear - 1}-${schoolYear}学年第二学期`
  } else {
    return `${schoolYear}-${schoolYear + 1}学年第一学期`
  }
}

/**
 * @desc 身份证号验证
 * @param {*} idCard
 * @returns
 */
export const checkIdCard = (idCard) => {
  var flag = false
  var regExp = new RegExp(/^(\d{17})(\d|X)$/)
  if (regExp.test(idCard)) {
    var coefficient = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2)
    var arr = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2')
    var temp = 0
    for (let i = 0; i < 17; i++) {
      temp += idCard.substr(i, 1) * coefficient[i]
    }
    flag = arr[temp % 11] == idCard.substr(17, 1)
  }
  return flag
}

/**
 * @desc 根据指定的 key 合并数组对象中相同 key 的项
 * @param {*} arr 数组对象
 * @param {*} key 指定的key
 */
export const mergeObjArr = (arr, key) => {
  let afterData = []
  arr.forEach((item) => {
    const flag = afterData.find((item1) => item1[key] === item[key])
    if (!flag) {
      const obj = {}
      obj[key] = item[key]
      obj['data'] = [item]
      afterData.push(obj)
    } else {
      flag.data.push(item)
    }
  })
  return afterData
}

// 复制到剪切板
export const copyToClipboard = (text) => {
  if (navigator.clipboard) {
    // clipboard api 复制
    navigator.clipboard.writeText(text);
  } else {
    let textarea = document.createElement('textarea');
    document.body.appendChild(textarea);
    // 隐藏此输入框
    textarea.style.position = 'fixed';
    textarea.style.clip = 'rect(0 0 0 0)';
    textarea.style.top = '10px';
    // 赋值
    textarea.value = text;
    // 选中
    textarea.select();
    // 复制
    document.execCommand('copy', true);
    // 移除输入框
    document.body.removeChild(textarea);
  }
}
export const getGradeName = (grade) => {
  let gradeName
  switch (grade) {
    case 1:
      gradeName = '一年级'
      break;
    case 2:
      gradeName = '二年级'
      break;
    case 3:
      gradeName = '三年级'
      break;
    case 4:
      gradeName = '四年级'
      break;
    case 5:
      gradeName = '五年级'
      break;
    case 6:
      gradeName = '六年级'
      break;
    case 7:
      gradeName = '七年级'
      break;
    case 8:
      gradeName = '八年级'
      break;
    case 9:
      gradeName = '九年级'
      break;
    case 10:
      gradeName = '高一'
      break;
    case 11:
      gradeName = '高二'
      break;
    case 12:
      gradeName = '高三'
      break;
  }
  return gradeName || '全部年级'
}


//根据当前时间计算多少天后的日期
const formatYesterDay = (date, num, joinType) => {
  date.setTime(date.getTime() - 24 * num * 60 * 60 * 1000)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  return [year, month, day].map(formatNumber).join(joinType ? joinType : '/')
}
//得出多少天后的日期并转换成json格式
export const constructureDay = (num, joinType) => {
  let yesterDay = formatYesterDay(new Date(), num, joinType)
  let startShowDate = yesterDay.split(joinType ? joinType : '/')
  let json = {}
  json.startShowDate = `${startShowDate[1]}月${startShowDate[2]}日`
  json.date = yesterDay
  return json
}
/**
 * 动态计算echarts图表字体大小
 * @param {*} px 字体大小
 * @returns 
 */
export const getFontSize = (px) => {
  let clientWidth = window.innerWidth || document.body.clientWidth; //屏幕尺寸
  if (!clientWidth) {
    return 0;
  }
  let fontSize = clientWidth / 1920;
  return px * fontSize
}


// 扩展util库,加入其他几个方法
util = {
  ...util,
  getSchoolYearName,
  checkIdCard,
  mergeObjArr,
  copyToClipboard,
  getGradeName
}



export default util
