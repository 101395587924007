import Main from '@/views/MAIN/Main.vue';
import DefaultFiles from './modules/dataFiles'
import KMFiles from './modules/km_dataFiles'
const All_dataFiles = [
    {
        path: '/healthRecordManage',
        icon: 'ios-folder-open',
        name: 'healthRecordManage',
        title: '数据档案',
        parentCode: 'top_healthrecord',
        parentName: '数据档案',
        parentIcon: 'ios-folder-open',
        disabled: false,
        component: Main,
        children: [...DefaultFiles, ...KMFiles]
    },
    {
        path: '/motionRecipelManage',
        icon: 'ios-copy',
        name: 'motionRecipelManage',
        title: '运动处方管理',
        parentCode: 'top_healthrecord',
        parentName: '数据档案',
        parentIcon: 'ios-folder-open',
        disabled: false,
        component: Main,
        children: [
            {
                path: 'motionRecipelList',
                icon: 'ios-list-box-outline',
                name: 'motionRecipelList',
                title: '运动处方管理',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/exercise_prescription_management/motionRecipelList.vue'], resolve);
                }
            },
        ]
    },
    {
        path: '/psychological',
        icon: 'md-trending-up',
        name: 'psychological',
        title: '心理测评',
        parentCode: 'top_healthrecord',
        parentName: '数据档案',
        parentIcon: 'md-trending-up',
        disabled: false,
        component: Main,
        children: [
          {
            path: 'psychologicalSummary',
            icon: 'ios-list-box-outline',
            name: 'psychologicalSummary',
            title: '心理评测汇总数据',
            component: resolve => {
              require(['@/views/COMMON_VIEW/other_service/psychological/psychologicalSummary.vue'], resolve);
            }
          },
          {
            path: 'psychologicalDetail',
            icon: 'ios-list-box-outline',
            name: 'psychologicalDetail',
            title: '心理评测详情数据',
            component: resolve => {
              require(['@/views/COMMON_VIEW/other_service/psychological/psychologicalDetail.vue'], resolve);
            }
          },
          {
            path: 'administration',
            icon: 'ios-list-box-outline',
            name: 'administration',
            title: '心理测试管理',
            component: resolve => { require(['@/views/COMMON_VIEW/other_service/psychological/psychologicalAdministration.vue'], resolve); }
          },
          {
            path: 'psychologicalAside',
            icon: 'ios-list-box-outline',
            name: 'psychologicalAside',
            title: '心理测评详情',
            component: resolve => { require(['@/views/COMMON_VIEW/other_service/psychological/psychologicalTest.vue'], resolve); }
          }
        ]
    },
]

export default All_dataFiles;