import Main from '@/views/MAIN/Main.vue';
export default [
    {
        path: '/sportsTotalScore',
        icon: 'md-pulse',
        name: 'sportsTotalScore',
        title: '综合评价管理',
        parentCode: 'top_sports_score',
        parentName: '综合评价',
        parentIcon: 'md-pulse',
        disabled: false,
        component: Main,
        children: [
            {
                path: 'sportsScoreStudentKM',
                icon: 'ios-list-box-outline',
                name: 'sportsScoreStudentKM',
                title: '学生数据管理',
                component: resolve => {
                    require(['@/views/CITY_KM/comprehensive_evaluation/comprehensive_evaluation_manage/sportsScoreStudentKM.vue'], resolve);
                }
            },
            {
                path: 'sportsScoreStudent',
                icon: 'ios-list-box-outline',
                name: 'sportsScoreStudent',
                title: '按学生查询',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/comprehensive_evaluation/comprehensive_evaluation_manage/sportsScoreStudent/sportsScoreStudent.vue'], resolve);
                }
            },
            {
                path: 'sportsScoreStudentDetail',
                icon: 'ios-list-box-outline',
                name: 'sportsScoreStudentDetail',
                title: '学生详情',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/comprehensive_evaluation/comprehensive_evaluation_manage/sportsScoreStudentDetail.vue'], resolve);
                }
            },
            {
                path: 'sportsScoreStudentDetailCD',
                icon: 'ios-list-box-outline',
                name: 'sportsScoreStudentDetailCD',
                title: '学生详情',
                component: resolve => {
                    require(['@/views/CITY_CD/comprehensive_evaluation/comprehensive_evaluation_manage/sportsScoreStudentDetailCD.vue'], resolve);
                }
            },
            {
                path: 'sportsScoreStudentDetailYB',
                icon: 'ios-list-box-outline',
                name: 'sportsScoreStudentDetailYB',
                title: '学生详情',
                component: () => import("@/views/CITY_YB/ELEMENTARY_SCHOOL/comprehensiveEvaluation/sportsScoreStudentDetail.vue")
            },
            {
                path: 'sportsScoreStudentDetailKM',
                icon: 'ios-list-box-outline',
                name: 'sportsScoreStudentDetailKM',
                title: '学生详情',
                component: resolve => {
                    require(['@/views/CITY_KM/comprehensive_evaluation/comprehensive_evaluation_manage/sportsScoreStudentDetailKM/index.vue'], resolve);
                }
            },
            {
                path: 'sportsScoreClass',
                icon: 'ios-list-box-outline',
                name: 'sportsScoreClass',
                title: '按班级查询',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/comprehensive_evaluation/comprehensive_evaluation_manage/sportsScoreClass.vue'], resolve);
                }
            },
            {
                path: 'sportsScoreGrade',
                icon: 'ios-list-box-outline',
                name: 'sportsScoreGrade',
                title: '按年级查询',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/comprehensive_evaluation/comprehensive_evaluation_manage/sportsScoreGrade.vue'], resolve);
                }
            },
            {
                path: 'sportsScoreStudentYB',
                icon: 'ios-list-box-outline',
                name: 'sportsScoreStudentYB',
                title: '按学生查询',
                component: () => import("@/views/CITY_YB/ELEMENTARY_SCHOOL/comprehensiveEvaluation/sportsScoreStudent/sportsScoreStudent.vue")
            },
            {
                path: 'sportsScoreClassYB',
                icon: 'ios-list-box-outline',
                name: 'sportsScoreClassYB',
                title: '按班级查询',
                component: () => import("@/views/CITY_YB/ELEMENTARY_SCHOOL/comprehensiveEvaluation/sportsScoreClass.vue")
            },
            {
                path: 'sportsScoreGradeYB',
                icon: 'ios-list-box-outline',
                name: 'sportsScoreGradeYB',
                title: '按年级查询',
                component: () => import("@/views/CITY_YB/ELEMENTARY_SCHOOL/comprehensiveEvaluation/sportsScoreGrade.vue")
            },
            {
                path: 'sportsScoreClassYN',
                icon: 'ios-list-box-outline',
                name: 'sportsScoreClassYN',
                title: '班级数据查询',
                component: resolve => {
                    require(['@/views/CITY_KM/comprehensive_evaluation/comprehensive_evaluation_manage/sportsScoreClassYN.vue'], resolve);
                }
            },
            {
                path: 'sportsScoreGradeYN',
                icon: 'ios-list-box-outline',
                name: 'sportsScoreGradeYN',
                title: '年级数据查询',
                component: resolve => {
                    require(['@/views/CITY_KM/comprehensive_evaluation/comprehensive_evaluation_manage/sportsScoreGradeYN.vue'], resolve);
                }
            },
            {
                path: 'sportsScoreYear',
                icon: 'ios-list-box-outline',
                name: 'sportsScoreYear',
                title: '体育分值查询',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/comprehensive_evaluation/comprehensive_evaluation_manage/sportsScoreYear.vue'], resolve);
                }
            },
            {
                path: 'sportsScoreYearKM',
                icon: 'ios-list-box-outline',
                name: 'sportsScoreYearKM',
                title: '中考成绩查询',
                component: resolve => {
                    require(['@/views/CITY_KM/comprehensive_evaluation/comprehensive_evaluation_manage/sportsScoreYearKM.vue'], resolve);
                }
            },
            {
                path: 'sportsScoreYearDetail',
                icon: 'ios-list-box-outline',
                name: 'sportsScoreYearDetail',
                title: '分值详情',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/comprehensive_evaluation/comprehensive_evaluation_manage/sportsScoreYearDetail.vue'], resolve);
                }
            },
            {
                path: 'subjectReport',
                icon: 'ios-list-box-outline',
                name: 'subjectReport',
                title: '学生成绩上报',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/comprehensive_evaluation/comprehensive_evaluation_manage/subjectReport.vue'], resolve);
                }
            },
            {
                path: 'lessonReport',
                icon: 'ios-list-box-outline',
                name: 'lessonReport',
                title: '学课报告',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/comprehensive_evaluation/comprehensive_evaluation_manage/lessonReport.vue'], resolve);
                }
            },
            {
                path: 'subjectReportDetail',
                icon: 'ios-list-box-outline',
                name: 'subjectReportDetail',
                title: '学生上报信息',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/comprehensive_evaluation/comprehensive_evaluation_manage/subjectReportDetail.vue'], resolve);
                }
            },
            {
                path: 'highSportsScoreStudent',
                icon: 'ios-list-box-outline',
                name: 'highSportsScoreStudent',
                title: '按学生查询',
                component: resolve => {
                    require(['@/views/CITY_CD/highSchool/sportsScore/sportsScoreStudent.vue'], resolve);
                }
            },
            {
                path: 'sportsYearSemesterScoreStudentDetail',
                icon: 'ios-list-box-outline',
                name: 'sportsYearSemesterScoreStudentDetail',
                title: '按学生查询',
                component: resolve => {
                    require(['@/views/CITY_CD/highSchool/sportsScore/sportsYearSemesterScoreStudentDetail.vue'], resolve);
                }
            },

        ]
    },
    {
        path: '/evaluateReport',
        icon: 'md-pie',
        name: 'evaluateReport',
        title: '综合素质评价报告',
        parentCode: 'top_sports_score',
        parentName: '综合评价',
        parentIcon: 'md-pulse',
        disabled: false,
        component: Main,
        children: [
            {
                path: 'semesterEvaluateReport',
                icon: 'ios-list-box-outline',
                name: 'semesterEvaluateReport',
                title: '学期数据报告',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/comprehensive_evaluation/comprehensive_quality_evaluation/index.vue'], resolve);
                }
            }
        ]
    }
]