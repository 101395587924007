import Main from '@/views/MAIN/Main.vue';
export default [
  {
    path: '/systemNotice',
    icon: 'md-chatboxes',
    name: 'systemNotice',
    title: '系统通知',
    parentCode: 'top_psychological',
    parentName: '其他服务',
    parentIcon: 'md-cloud',
    disabled: false,
    component: Main,
    children: [
      {
        path: 'systemNoticeList',
        icon: 'ios-list-box-outline',
        name: 'systemNoticeList',
        title: '系统通知',
        component: resolve => {
          require(['@/views/COMMON_VIEW/other_service/systemNotice/index.vue'], resolve);
        }
      },
    ]
  },
  {
    path: '/psychological',
    icon: 'md-trending-up',
    name: 'psychological',
    title: '心理测评',
    parentCode: 'top_psychological',
    parentName: '其他服务',
    parentIcon: 'md-trending-up',
    disabled: false,
    component: Main,
    children: [
      {
        path: 'psychologicalSummary',
        icon: 'ios-list-box-outline',
        name: 'psychologicalSummary',
        title: '心理评测汇总数据',
        component: resolve => {
          require(['@/views/COMMON_VIEW/other_service/psychological/psychologicalSummary.vue'], resolve);
        }
      },
      {
        path: 'psychologicalDetail',
        icon: 'ios-list-box-outline',
        name: 'psychologicalDetail',
        title: '心理评测详情数据',
        component: resolve => {
          require(['@/views/COMMON_VIEW/other_service/psychological/psychologicalDetail.vue'], resolve);
        }
      },
      {
        path: 'administration',
        icon: 'ios-list-box-outline',
        name: 'administration',
        title: '心理测试管理',
        component: resolve => { require(['@/views/COMMON_VIEW/other_service/psychological/psychologicalAdministration.vue'], resolve); }
      },
      {
        path: 'psychologicalAside',
        icon: 'ios-list-box-outline',
        name: 'psychologicalAside',
        title: '心理测评详情',
        component: resolve => { require(['@/views/COMMON_VIEW/other_service/psychological/psychologicalTest.vue'], resolve); }
      }
    ]
  },

  {
    path: '/projectionScreen',
    icon: 'md-desktop',
    name: 'projectionScreen',
    title: '系统投屏',
    parentCode: 'top_psychological',
    parentName: '其他服务',
    parentIcon: 'md-cloud',
    disabled: false,
    component: Main,
    children: [
      {
        path: 'screenManage',
        icon: 'ios-list-box-outline',
        name: 'screenManage',
        title: '系统投屏',
        component: resolve => {
          require(['@/views/COMMON_VIEW/other_service/projectionScreen/index.vue'], resolve);
        }
      },
      {
        path: 'screenManage_addScreen',
        icon: 'ios-list-box-outline',
        name: 'screenManage_addScreen',
        title: '系统投屏添加屏幕',
        component: resolve => {
          require(['@/views/COMMON_VIEW/other_service/projectionScreen/addScreen.vue'], resolve);
        }
      }
    ]
  },
  {
    path: '/cabinetManagement',
    icon: 'md-grid',
    name: 'cabinetManagrment',
    title: '智能储物柜管理',
    parentCode: 'top_psychological',
    parentName: '其他服务',
    parentIcon: 'md-cloud',
    disabled: false,
    component: Main,
    children: [
      {
        path: 'cupboard',
        icon: 'ios-list-box-outline',
        name: 'cupboard',
        title: '智能储物柜',
        component: resolve => {
          require(['@/views/COMMON_VIEW/other_service/cabinetManagrment/index.vue'], resolve);
        }
      }
    ]
  },
  {
    path: '/operationLogManage',
    icon: 'ios-paper',
    name: 'operationLogManage',
    title: '系统操作日志',
    parentCode: 'top_psychological',
    parentName: '其他服务',
    parentIcon: 'md-cloud',
    disabled: false,
    component: Main,
    children: [
      {
        path: 'operationLogIndex',
        icon: 'ios-list-box-outline',
        name: 'operationLogIndex',
        title: '系统操作日志',
        component: resolve => {
          require(['@/views/COMMON_VIEW/other_service/operationLogManage/index.vue'], resolve);
        }
      }
    ]
  },
  {
    path: '/exerciseDataManagement',
    icon: 'ios-podium',
    name: 'exerciseDataManagement',
    title: '锻炼数据管理',
    parentCode: 'top_psychological',
    parentName: '其他服务',
    parentIcon: 'md-cloud',
    disabled: false,
    component: Main,
    children: [
      {
        path: 'exerciseDataStatistics',
        icon: 'ios-list-box-outline',
        name: 'exerciseDataStatistics',
        title: '锻炼数据统计',
        component: resolve => {
          require(['@/views/COMMON_VIEW/other_service/exerciseDataManagement/index.vue'], resolve);
        }
      },
      {
        path: 'exerciseData-classDetail',
        icon: 'ios-list-box-outline',
        name: 'exerciseDataClassDetail',
        title: '班级详情',
        component: resolve => {
          require(['@/views/COMMON_VIEW/other_service/exerciseDataManagement/classDetail.vue'], resolve);
        }
      },
      {
        path: 'exerciseData-exerciseDetail',
        icon: 'ios-list-box-outline',
        name: 'exerciseDataExerciseDetail',
        title: '锻炼详情',
        component: resolve => {
          require(['@/views/COMMON_VIEW/other_service/exerciseDataManagement/exerciseDetail.vue'], resolve);
        }
      }
    ]
  },
  {
    path: '/cloudMatch',
    icon: 'md-cloudy',
    name: 'cloudMatch',
    title: '赛事管理',
    parentCode: 'top_psychological',
    parentName: '其他服务',
    parentIcon: 'md-cloud',
    disabled: false,
    component: Main,
    children: [
      {
        path: 'cloudMatchList',
        icon: 'ios-list-box-outline',
        name: 'cloudMatchList',
        title: '云赛事',
        component: resolve => {
          require(['@/views/COMMON_VIEW/other_service/cloudMatch/index.vue'], resolve);
        }
      },
      {
        path: 'sportMatch',
        icon: 'ios-list-box-outline',
        name: 'sportMatch',
        title: '运动会',
        component: resolve => {
          require(['@/views/COMMON_VIEW/other_service/cloudMatch/sportMatch/index.vue'], resolve);
        }
      },
      {
        path: 'sportMatchDetail',
        icon: 'ios-list-box-outline',
        name: 'sportMatchDetail',
        title: '运动会详情',
        component: resolve => {
          require(['@/views/COMMON_VIEW/other_service/cloudMatch/sportMatch/matchDetail.vue'], resolve);
        }
      },
      {
        path: 'groupConfig',
        icon: 'ios-list-box-outline',
        name: 'groupConfig',
        title: '运动会详情-组别配置',
        component: resolve => {
          require(['@/views/COMMON_VIEW/other_service/cloudMatch/sportMatch/groupConfig.vue'], resolve);
        }
      },
      {
        path: 'sportmanInfo',
        icon: 'ios-list-box-outline',
        name: 'sportmanInfo',
        title: '运动会详情-运动员信息',
        component: resolve => {
          require(['@/views/COMMON_VIEW/other_service/cloudMatch/sportMatch/sportmanInfo.vue'], resolve);
        }
      },
      {
        path: 'dataDetail-1',
        icon: 'ios-list-box-outline',
        name: 'cloudMatch-dataDetail-1',
        title: '数据详情(云联赛)',
        component: resolve => {
          require(['@/views/COMMON_VIEW/other_service/cloudMatch/yunliansai/dataDetail.vue'], resolve);
        }
      },
      {
        path: 'matchDetail',
        icon: 'ios-list-box-outline',
        name: 'cloudMatch-matchDetail-1',
        title: '赛事详情(云联赛)',
        component: resolve => {
          require(['@/views/COMMON_VIEW/other_service/cloudMatch/yunliansai/matchDetail.vue'], resolve);
        }
      },
      {
        path: 'dataDetail-2',
        icon: 'ios-list-box-outline',
        name: 'cloudMatch-dataDetail-2',
        title: '数据详情(贡纳杯)',
        component: resolve => {
          require(['@/views/COMMON_VIEW/other_service/cloudMatch/gonggabei/dataDetail.vue'], resolve);
        }
      },
      {
        path: 'matchDetail-2',
        icon: 'ios-list-box-outline',
        name: 'cloudMatch-matchDetail-2',
        title: '赛事详情(贡纳杯)',
        component: resolve => {
          require(['@/views/COMMON_VIEW/other_service/cloudMatch/gonggabei/matchDetail.vue'], resolve);
        }
      },
      {
        path: 'studentInfo',
        name: 'cloudMatch-studentInfo',
        title: '学生详情(云联赛)',
        component: resolve => {
          require(['@/views/COMMON_VIEW/other_service/cloudMatch/yunliansai/studentInfo/index.vue'], resolve);
        }
      },
    ]
  },
]