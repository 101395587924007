<template>
  <div class="top_menu" @click="handleMenu">
    <Tabs :value="activeTopMenuName" @on-click="changeTab">
      <TabPane
        :label="item.parentName || item.name"
        v-for="(item, index) in menuList"
        :name="item.parentCode + '-' + item.name"
        :key="index + item"
        :id="item.name"
        class="topMenuItem"
        :disabled="item.disabled"
        :icon="item.parentIcon"
      >
        <!-- 标签一的内容 :disabled="isDisabled(item.parentCode)"-->
      </TabPane>
    </Tabs>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'topMenu',
  props: {
    menuList: Array,
    theme1: String,
    schoolLevel: String,
    isQiangji: Boolean
  },
  computed: {
    ...mapState(['activeTopMenuName'])
  },
  methods: {
    ...mapActions(['updateActiveTopMenuName']),
    // 赛事版 和 文选试用版 需要提示
    handleMenu(e) {
      let versionMsg = this.schoolLevel == '2' ? '云锻炼活动版' : '试用版'
      let content = `老师您好，感谢使用“${
        this.isQiangji ? '强基未来' : '小马健康'
      }”智慧体育教育SaaS云平台，当前版本为${versionMsg}，如需了解或开通其他功能，请联系客服人员400-666-1816，感谢您的支持！`
      if ((this.schoolLevel === '2' || this.schoolLevel === '4') && e.target._prevClass == 'ivu-tabs-nav') {
        this.$Modal.info({
          title: '提示',
          content
        })
      }
    },
    changeTab(active) {
      this.updateActiveTopMenuName(active)

      let _this = this
      this.$nextTick(() => {
        if (this.$store.state.app.menuList) {
          active =
            active == null
              ? `${this.$store.state.app.menuList[0].parentCode}-${this.$store.state.app.menuList[0].name}`
              : active
        }
      })

      sessionStorage.setItem('selectedName', active)

      _this.$store.commit('setStateName', active)
      let active1 = active.split('-')[0]
      let active2 = active.split('-')[1]
      _this.$store.commit('modifyMnueList', active1)

      _this.$emit('on-change', active2)
      let elementArr = _this.siblings(document.getElementById(active2))
      if (elementArr) {
        for (let val of elementArr) {
          val.className = 'ivu-menu-item topMenuItem'
        }
      }
      if (document.getElementById(active2)) {
        document.getElementById(active2).className =
          'ivu-menu-item ivu-menu-item-active ivu-menu-item-selected topMenuItem'
      }
      this.$emit(
        'on-click',
        this.$store.state.app.menuList.length > 0
          ? this.$store.state.app.menuList[0]?.children[0]?.name
          : this.$store.state.app.menuListAll[0]?.children[0]?.name
      )
      let fromName = sessionStorage.getItem('fromName')
      if (this.$store.state.pageParams.listPagePars.has(fromName)) {
        this.$store.state.pageParams.listPagePars.delete(fromName)
      }
    },
    isDisabled(name) {
      let isDisabled = false
      if (this.schoolLevel === '2') {
        if (name === 'top_psychological') {
          isDisabled = false
        } else {
          isDisabled = true
        }
      }
      return isDisabled
    },
    changmenuColor(name) {
      if (name == '') return
      let elementArr = this.siblings(document.getElementById(name))
      if (elementArr) {
        for (let val of elementArr) {
          val.className = 'ivu-menu-item topMenuItem'
        }
        document.getElementById(name).className =
          'ivu-menu-item ivu-menu-item-active ivu-menu-item-selected topMenuItem'
      }
    },
    siblings(elm) {
      if (!elm) {
        return
      }
      var a = []
      var p = elm.parentNode.children
      for (var i = 0, pl = p.length; i < pl; i++) {
        if (p[i] !== elm) a.push(p[i])
      }
      return a
    }
  },
  mounted() {
    const firstMenu = this.menuList[0]
    this.changeTab(`${firstMenu?.parentCode}-${firstMenu?.name}`)
    this.changmenuColor(
      sessionStorage.getItem('selectedName') == '-' || sessionStorage.getItem('selectedName') == null
        ? this.$store.state.app.name
        : sessionStorage.getItem('selectedName').split('-')[1]
    )
  },
  updated() {
    this.$nextTick(() => {
      if (this.$refs.topMenu) {
        this.changmenuColor(
          sessionStorage.getItem('selectedName') == null
            ? this.$store.state.app.menuList[0].name
            : sessionStorage.getItem('selectedName').split('-')[1]
        )
        this.$refs.topMenu.updateOpened()
      }
    })
  }
}
</script>
<style lang="less">
@primary-color: #00c693;
// 修改顶部菜单样式
.top_menu {
  height: 100%;

  .ivu-tabs {
    height: 100%;

    .ivu-tabs-ink-bar {
      left: 0 !important;
    }

    .ivu-tabs-bar {
      height: 100%;

      .ivu-tabs-nav-container {
        line-height: 64px;

        .ivu-tabs-nav-prev,
        .ivu-tabs-nav-next {
          font-size: 24px;
          transform: translateY(18px);
          // padding-left: 10px;
        }
        .ivu-tabs-nav-next {
          right: -8px;
        }
        .ivu-icon-ios-arrow-forward {
          padding-left: 20px !important;
        }
        .ivu-icon {
          font-size: 24px;
          transform: translateY(8px);
        }
      }
    }

    .ivu-tabs-nav {
      display: flex;
    }

    .ivu-tabs-tab {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 80px !important;
      padding-bottom: 0 !important;
    }

    .ivu-tabs-nav .ivu-tabs-tab:hover {
      color: @primary-color;
    }

    .ivu-tabs-nav .ivu-tabs-tab-active {
      color: @primary-color;
    }
  }
}
</style>
<style lang="less" scoped>
.topMenuItem {
  min-width: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 15px !important;
}
</style>
