var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: {
            closable: false,
            title: "提醒",
            "mask-closable": false,
            "ok-text": "去配置",
            "cancel-text": "稍后提醒我",
          },
          model: {
            value: _vm.visable,
            callback: function ($$v) {
              _vm.visable = $$v
            },
            expression: "visable",
          },
        },
        [
          _c("p", [_vm._v("新学期即将开始，请先设置学校基本配置。")]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _vm.limitStatus != "1"
                ? _c("Button", { on: { click: _vm.remindLater } }, [
                    _vm._v("稍后提醒我"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "Button",
                { attrs: { type: "success" }, on: { click: _vm.modalOk } },
                [_vm._v("去配置")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }