import Main from '@/views/MAIN/Main.vue';
export default [
  {
    path: '/cloudMatch',
    icon: 'md-cloudy',
    name: 'cloudMatch',
    title: '赛事管理',
    parentCode: 'top_cloud',
    parentName: '赛事管理',
    parentIcon: 'md-cloud',
    disabled: false,
    component: Main,
    children: [
      {
        path: 'cloudMatchList',
        icon: 'ios-list-box-outline',
        name: 'cloudMatchList',
        title: '云赛事',
        component: resolve => {
          require(['@/views/COMMON_VIEW/other_service/cloudMatch/index.vue'], resolve);
        }
      },
      {
        path: 'sportMatch',
        icon: 'ios-list-box-outline',
        name: 'sportMatch',
        title: '运动会',
        component: resolve => {
          require(['@/views/COMMON_VIEW/other_service/cloudMatch/sportMatch/index.vue'], resolve);
        }
      },
      {
        path: 'sportMatchDetail',
        icon: 'ios-list-box-outline',
        name: 'sportMatchDetail',
        title: '运动会详情',
        component: resolve => {
          require(['@/views/COMMON_VIEW/other_service/cloudMatch/sportMatch/matchDetail.vue'], resolve);
        }
      },
      {
        path: 'groupConfig',
        icon: 'ios-list-box-outline',
        name: 'groupConfig',
        title: '运动会详情-组别配置',
        component: resolve => {
          require(['@/views/COMMON_VIEW/other_service/cloudMatch/sportMatch/groupConfig.vue'], resolve);
        }
      },
      {
        path: 'sportmanInfo',
        icon: 'ios-list-box-outline',
        name: 'sportmanInfo',
        title: '运动会详情-运动员信息',
        component: resolve => {
          require(['@/views/COMMON_VIEW/other_service/cloudMatch/sportMatch/sportmanInfo.vue'], resolve);
        }
      },
      {
        path: 'dataDetail-1',
        icon: 'ios-list-box-outline',
        name: 'cloudMatch-dataDetail-1',
        title: '数据详情(云联赛)',
        component: resolve => {
          require(['@/views/COMMON_VIEW/other_service/cloudMatch/yunliansai/dataDetail.vue'], resolve);
        }
      },
      {
        path: 'matchDetail',
        icon: 'ios-list-box-outline',
        name: 'cloudMatch-matchDetail-1',
        title: '赛事详情(云联赛)',
        component: resolve => {
          require(['@/views/COMMON_VIEW/other_service/cloudMatch/yunliansai/matchDetail.vue'], resolve);
        }
      },
      {
        path: 'dataDetail-2',
        icon: 'ios-list-box-outline',
        name: 'cloudMatch-dataDetail-2',
        title: '数据详情(贡纳杯)',
        component: resolve => {
          require(['@/views/COMMON_VIEW/other_service/cloudMatch/gonggabei/dataDetail.vue'], resolve);
        }
      },
      {
        path: 'matchDetail-2',
        icon: 'ios-list-box-outline',
        name: 'cloudMatch-matchDetail-2',
        title: '赛事详情(贡纳杯)',
        component: resolve => {
          require(['@/views/COMMON_VIEW/other_service/cloudMatch/gonggabei/matchDetail.vue'], resolve);
        }
      },
      {
        path: 'studentInfo',
        name: 'cloudMatch-studentInfo',
        title: '学生详情(云联赛)',
        component: resolve => {
          require(['@/views/COMMON_VIEW/other_service/cloudMatch/yunliansai/studentInfo/index.vue'], resolve);
        }
      },
    ]
  },
]