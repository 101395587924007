import Main from '@/views/MAIN/Main.vue';
import DefaultFiles from './modules/dataFiles'
import KMFiles from './modules/km_dataFiles'
const All_dataFiles = [
    {
        path: '/healthRecordManage',
        icon: 'ios-folder-open',
        name: 'healthRecordManage',
        title: '数据档案',
        parentCode: 'top_healthrecord',
        parentName: '数据档案',
        parentIcon: 'ios-folder-open',
        disabled: false,
        component: Main,
        children: [...DefaultFiles, ...KMFiles]
    },
    {
        path: '/motionRecipelManage',
        icon: 'ios-copy',
        name: 'motionRecipelManage',
        title: '运动处方管理',
        parentCode: 'top_healthrecord',
        parentName: '运动处方管理',
        parentIcon: 'ios-folder-open',
        disabled: false,
        component: Main,
        children: [
            {
                path: 'motionRecipelList',
                icon: 'ios-list-box-outline',
                name: 'motionRecipelList',
                title: '运动处方管理',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/exercise_prescription_management/motionRecipelList.vue'], resolve);
                }
            },
        ]
    }
]

export default All_dataFiles;