<template>
  <div id="main" class="app-main">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  created() {
    this.initTianditu() // 初始化天地图
  },
  methods: {
    /** 初始化天地图 */
    initTianditu() {
      // 创建一个 script 标签，插入到页面head中，并将src指向 https://html-test.tanmasports.com/js/tianditu.js
      const scriptElement = document.createElement('script')
      scriptElement.src = 'https://html-test.tanmasports.com/js/tianditu.js'
      document.head.appendChild(scriptElement)
    }
  }
}
</script>
<style scoped>
.app-main {
  width: 100%;
  height: 100%;
}
</style>
