var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ivu-shrinkable-menu", style: { background: _vm.bgColor } },
    [
      _vm._t("top"),
      _vm._v(" "),
      _c("sidebar-menu", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.shrink,
            expression: "!shrink",
          },
        ],
        ref: "sidebarMenuBut",
        attrs: {
          "menu-theme": _vm.theme,
          "menu-list": _vm.menuList,
          "open-names": _vm.openNames,
        },
        on: { "on-change": _vm.handleChange },
      }),
      _vm._v(" "),
      _c("sidebar-menu-shrink", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.shrink,
            expression: "shrink",
          },
        ],
        attrs: {
          "menu-theme": _vm.theme,
          "menu-list": _vm.menuList,
          "icon-color": _vm.shrinkIconColor,
        },
        on: { "on-change": _vm.handleChange },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }