import Main from '@/views/MAIN/Main.vue';
export default [
  {
    path: '/schoolExerciseConfigYB',
    icon: 'ios-cog',
    name: 'schoolExerciseConfigYB',
    title: '校内锻炼配置',
    parentCode: 'top_schoolExercise',
    parentName: '校内锻炼',
    parentIcon: 'ios-body',
    disabled: false,
    component: Main,
    children: [
      {
        path: 'exerciseConfigListYB',
        icon: 'ios-list-box-outline',
        name: 'exerciseConfigListYB',
        title: '校内锻炼配置',
        component: () => import("@/views/CITY_YB/ELEMENTARY_SCHOOL/schoolExercise/dataConfig/index.vue")
      },
    ]
  },
  {
    path: '/schoolExerciseManageYB',
    icon: 'ios-stats',
    name: 'schoolExerciseManageYB',
    title: '校内锻炼数据管理',
    parentCode: 'top_schoolExercise',
    parentName: 'cloud',
    parentIcon: 'md-cloud',
    disabled: false,
    component: Main,
    children: [
      {
        path: 'queryStudentListYB',
        icon: 'ios-list-box-outline',
        name: 'queryStudentListYB',
        title: '按学生查询',
        component: () => import("@/views/CITY_YB/ELEMENTARY_SCHOOL/schoolExercise/dataManage/queryStudentList.vue")
      },
      {
        path: 'queryClassListYB',
        icon: 'ios-list-box-outline',
        name: 'queryClassListYB',
        title: '按班级查询',
        component: () => import("@/views/CITY_YB/ELEMENTARY_SCHOOL/schoolExercise/dataManage/queryClassList.vue")

      },
      {
        path: 'queryGradeListYB',
        icon: 'ios-list-box-outline',
        name: 'queryGradeListYB',
        title: '按年级查询',
        component: () => import("@/views/CITY_YB/ELEMENTARY_SCHOOL/schoolExercise/dataManage/queryGradeList.vue")

      },
      {
        path: 'exerciseRecordsYB',
        icon: 'ios-list-box-outline',
        name: 'exerciseRecordsYB',
        title: '锻炼记录',
        component: () => import("@/views/CITY_YB/ELEMENTARY_SCHOOL/schoolExercise/dataManage/exerciseRecords.vue")

      },
      {
        path: 'exerciseDataStatisticsYB',
        icon: 'ios-list-box-outline',
        name: 'exerciseDataStatisticsYB',
        title: '锻炼数据统计',
        component: () => import("@/views/CITY_YB/ELEMENTARY_SCHOOL/schoolExercise/dataManage/exerciseDataStatistics.vue")

      },
      {
        path: 'exerciseDataClassDetailYB',
        icon: 'ios-list-box-outline',
        name: 'exerciseDataClassDetailYB',
        title: '班级详情',
        component: () => import("@/views/CITY_YB/ELEMENTARY_SCHOOL/schoolExercise/dataManage/classDetail.vue")
      },
      {
        path: 'exerciseDataExerciseDetailYB',
        icon: 'ios-list-box-outline',
        name: 'exerciseDataExerciseDetailYB',
        title: '锻炼详情',
        component: resolve => {
          require(['@/views/CITY_YB/ELEMENTARY_SCHOOL/schoolExercise/dataManage/exerciseDetail.vue'], resolve);
        }
      },
      {
        path: 'signInStatisticsYB',
        icon: 'ios-list-box-outline',
        name: 'signInStatisticsYB',
        title: '打卡统计',
        component: () => import("@/views/CITY_YB/ELEMENTARY_SCHOOL/schoolExercise/dataManage/signInStatistics.vue")
      },
      {
        path: 'signInStatisticsDetail',
        icon: 'ios-list-box-outline',
        name: 'signInStatisticsDetail',
        title: '打卡统计详情',
        component: () => import("@/views/CITY_YB/ELEMENTARY_SCHOOL/schoolExercise/dataManage/signInStatisticsDetail.vue")
      },
      {
        path: 'signInRecordYB',
        icon: 'ios-list-box-outline',
        name: 'signInRecordYB',
        title: '打卡记录',
        component: () => import("@/views/CITY_YB/ELEMENTARY_SCHOOL/schoolExercise/dataManage/signInRecord.vue")

      },
      {
        path: 'studentDetail',
        icon: 'ios-list-box-outline',
        name: 'studentDetail',
        title: '学生详情',
        component: () => import("@/views/CITY_YB/ELEMENTARY_SCHOOL/schoolExercise/dataManage/studentDetail.vue")

      },
    ]
  },


]