var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Menu",
        {
          ref: "sideMenu",
          attrs: {
            "active-name": _vm.$route.name,
            "open-names": _vm.openNames,
            theme: _vm.menuTheme,
            width: "auto",
          },
          on: { "on-select": _vm.changeMenu },
        },
        [
          _vm._l(_vm.menuList, function (item, index) {
            return [
              item.children.length
                ? _c(
                    "Submenu",
                    { key: item.path, attrs: { name: item.name } },
                    [
                      _c(
                        "template",
                        { slot: "title" },
                        [
                          _c("Icon", {
                            attrs: { type: item.icon, size: "20" },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "layout-text" }, [
                            _vm._v(_vm._s(item.title)),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._l(item.children, function (child, jindex) {
                        return [
                          _c(
                            "MenuItem",
                            { attrs: { id: child.name, name: child.name } },
                            [
                              _c(
                                "div",
                                [
                                  _c("Icon", {
                                    key: child.name + jindex,
                                    attrs: {
                                      type: child.icon,
                                      size: _vm.iconSize,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      key: jindex,
                                      staticClass:
                                        "layout-text layout-text-size",
                                    },
                                    [_vm._v(_vm._s(child.title))]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }