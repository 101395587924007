import Main from '@/views/MAIN/Main.vue';
export default [
    {
        path: '/auditKMManage',
        icon: 'md-checkbox',
        name: 'auditKMManage',
        title: '审核管理',
        parentCode: 'top_audit',
        parentName: '审核管理',
        parentIcon: 'md-checkbox',
        disabled: false,
        component: Main,
        children: [
            {
                path: 'auditManageKM',
                icon: 'ios-list-box-outline',
                name: 'auditManageKM',
                title: '审核管理',
                component: resolve => {
                    require(['@/views/CITY_KM/audit/auditManageKM.vue'], resolve);
                }
            }
        ]
    }
]
