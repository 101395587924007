<template>
  <div>
    <Modal
      v-model="visable"
      :closable="false"
      title="提醒"
      :mask-closable="false"
      ok-text="去配置"
      cancel-text="稍后提醒我"
    >
      <p>新学期即将开始，请先设置学校基本配置。</p>
      <div slot="footer">
        <Button v-if="limitStatus != '1'" @click="remindLater">稍后提醒我</Button>
        <Button type="success" @click="modalOk">去配置</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  name: 'SemesterConfigModal',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    limitStatus: {
      type: String,
      default: '0'
    }
  },
  computed: {
    visable: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  // data() {
  //   return {
  //     visable: this.value
  //   }
  // },
  methods: {
    /** 稍后提醒我 */
    remindLater() {
      this.visable = false
      // this.$emit('input', this.visable)
      this.$emit('on-ok', 0)
    },
    /** 去配置 */
    modalOk() {
      this.visable = false
      // this.$emit('input', this.visable)
      this.$emit('on-config')
    }
  }
}
</script>

<style lang="less" scoped></style>
