var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.menuList, function (item, index) {
        return [
          _c(
            "div",
            { key: index, staticStyle: { "text-align": "center" } },
            [
              item.children.length !== 1
                ? _c(
                    "Dropdown",
                    {
                      key: index,
                      attrs: { transfer: "", placement: "right-start" },
                      on: { "on-click": _vm.changeMenu },
                    },
                    [
                      _c(
                        "Button",
                        {
                          staticStyle: {
                            width: "70px",
                            "margin-left": "-5px",
                            padding: "10px 0",
                          },
                          attrs: { type: "text" },
                        },
                        [
                          _c("Icon", {
                            attrs: {
                              size: 20,
                              color: _vm.iconColor,
                              type: item.icon,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "DropdownMenu",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { slot: "list" },
                          slot: "list",
                        },
                        [
                          _vm._l(item.children, function (child, i) {
                            return [
                              _c(
                                "DropdownItem",
                                { key: i, attrs: { name: child.name } },
                                [
                                  _c("Icon", { attrs: { type: child.icon } }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticStyle: { "padding-left": "10px" } },
                                    [_vm._v(_vm._s(_vm.itemTitle(child)))]
                                  ),
                                ],
                                1
                              ),
                            ]
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _c(
                    "Dropdown",
                    {
                      key: index,
                      attrs: { transfer: "", placement: "right-start" },
                      on: { "on-click": _vm.changeMenu },
                    },
                    [
                      _c(
                        "Button",
                        {
                          staticStyle: {
                            width: "70px",
                            "margin-left": "-5px",
                            padding: "10px 0",
                          },
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.changeMenu(item.children[0].name)
                            },
                          },
                        },
                        [
                          _c("Icon", {
                            attrs: {
                              size: 20,
                              color: _vm.iconColor,
                              type: item.icon,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "DropdownMenu",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { slot: "list" },
                          slot: "list",
                        },
                        [
                          _c(
                            "DropdownItem",
                            {
                              key: "d" + index,
                              attrs: { name: item.children[0].name },
                            },
                            [
                              _c("Icon", { attrs: { type: item.icon } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticStyle: { "padding-left": "10px" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.itemTitle(item.children[0]))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }