import { otherRouter, appRouter } from '@/router/router';
import axios from 'axios'

// 根据登录账号地域替换免试或者免考
const replacenoTestTitle = (menuItem) => {
  const schoolDistrict = sessionStorage.getItem('schoolDistrict');
  const replacement = schoolDistrict == '2000' ? "免考" : "免试";
  if (menuItem) {
    if (menuItem.path == "/StudentExempt") {
      menuItem.title = `${replacement}管理`;
    }
    if (menuItem.children) {
      const reg = schoolDistrict == '2000' ? /免试/g : /免考/g;

      menuItem.children.forEach((key) => {
        key.title = key.title.replace(reg, replacement);
      });
    }
  }
}

// 过滤菜单权限
const filterMenuList = (appRouterTemp, menuList) => {
  const data = new Set(menuList);

  return appRouterTemp.filter(item => {
    if (!data.has(item.name)) {
      return false;
    }

    item.children = item.children.filter(child => data.has(child.name));

    if (item.children.length === 0) {
      return false;
    }

    replacenoTestTitle(item);
    return true;
  });
}

// 根据用户权限过滤菜单或者替换菜单位置
const filterPowerMenu = (appRouterTemp) => {
  let appRouterClone = JSON.parse(JSON.stringify(appRouterTemp))
  const overallSituationuserInfo = JSON.parse(sessionStorage.getItem('overallSituationuserInfo'));
  if (overallSituationuserInfo instanceof Object) {

    // 赛事版
    if (overallSituationuserInfo.schoolLevel === '2') {
      const needFliterOneMenuName = 'top_psychological';
      appRouterClone.forEach(item => {
        item.disabled = item.parentCode != needFliterOneMenuName;
      });
      appRouterClone = appRouterClone.filter(item => (item.name === 'cloudMatch' && item.parentCode === needFliterOneMenuName) || (item.parentCode !== needFliterOneMenuName))
      // 替换位置到第一个
      appRouterClone.splice(0, 0, appRouterClone.pop())

      return appRouterClone;
    }

    // 文轩试用
    if (overallSituationuserInfo.schoolLevel === '4') {
      // 基础配置、体质测试、视力测试、课堂监控、课后作业
      const needShowMenuList = ['top_config', 'top_test', 'top_vision', 'top_sportsClass', 'top_homeWork']
      appRouterClone.forEach(item => {
        item.disabled = !needShowMenuList.includes(item.parentCode);
      });
      // 按照需要展示的顺序排序
      appRouterClone.sort((a, b) => {
        const indexA = needShowMenuList.indexOf(a.parentCode);
        const indexB = needShowMenuList.indexOf(b.parentCode);

        // 如果 a 和 b 都在 needShowMenuList 中，则按索引排序
        if (indexA !== -1 && indexB !== -1) {
          return indexA - indexB;
        }
        // 如果 a 在 needShowMenuList 中而 b 不在，则 a 排在前
        if (indexA !== -1) {
          return -1;
        }
        // 如果 b 在 needShowMenuList 中而 a 不在，则 b 排在前
        if (indexB !== -1) {
          return 1;
        }
        // 如果 a 和 b 都不在 needShowMenuList 中，则保持原顺序
        return 0;
      });
      return appRouterClone;
    }
  }
  return appRouterClone;
}

const getFirstMenu = () => {
  let sessionFirst = sessionStorage.getItem('firstMenu');
  let firstMenu = sessionFirst != null && sessionFirst != 'undefined' ? JSON.parse(sessionFirst) : {};

  return { firstMenu }
}

const app = {
  state: {
    menuList: [],
    menuListAll: [],
    name: '',
    nameData: '',
    routers: [
      otherRouter,
      ...appRouter
    ],
  },
  getters: {
    /** 判断是否是双流佳发域名 */
    isJiafaDomain() {
      const { shuangliuURL } = axios.defaults;
      const { origin } = window.location;
      return origin === shuangliuURL
    }
  },
  mutations: {
    updateMenulist(state) {
      // 获取本工程的所有路由
      sessionStorage.setItem('scholl-appRouter', JSON.stringify(appRouter))
      let schollAppRouter = sessionStorage.getItem('scholl-appRouter');
      let appRouterTemp = schollAppRouter ? JSON.parse(schollAppRouter) : appRouter;

      // 当前账号的权限菜单列表
      let menuListSession = sessionStorage.getItem('menuList');
      let menuListRes = menuListSession ? menuListSession.split(',') : [];

      if (menuListRes.length) {
        appRouterTemp = filterMenuList(appRouterTemp, menuListRes);
      }

      // 存储过滤后的第一个路由对象，作为默认选中
      let [firstMenu] = appRouterTemp;
      sessionStorage.setItem('firstMenu', JSON.stringify(firstMenu));

      // 后台配置归属强基或者小马，赛事版和文选试用版-需要筛选路由
      appRouterTemp = filterPowerMenu(appRouterTemp)


      state.menuListAll = appRouterTemp;
    },
    modifyMnueList(state, active) {
      // 是否包含当前菜单
      let isContain = state.menuListAll.some(item => item.parentCode == active);
      let { firstMenu } = getFirstMenu();
      if (!isContain) active = firstMenu.parentCode;
      state.menuList = [...state.menuListAll].filter(val => val.parentCode == active);
    },
    setStateName(state, name) {
      let { firstMenu } = getFirstMenu();
      state.name = name ? name.split('-')[1] : firstMenu.name;
      state.nameData = name ? name.split('-')[0] : firstMenu.parentCode;
    },
    setMenuList(state, msg) {
      state.menuList = msg
    },

  },
  actions: {
    setMenuList({ commit }, msg) {
      commit('setMenuList', msg)
    }
  }
};

export default app;
