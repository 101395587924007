import Main from '@/views/MAIN/Main.vue';
export default [
    {
        path: '/exerciseFamily',
        icon: 'md-basketball',
        name: 'exerciseFamily',
        title: '课后作业',
        parentCode: 'top_homeWork',
        parentName: '课后作业',
        parentIcon: 'md-basketball',
        disabled: false,
        component: Main,
        children: [
            {
                path: 'exercisePlanList',
                icon: 'ios-list-box-outline',
                name: 'exercisePlanList',
                title: '视频动作管理',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/home_work/home_work_manage/exercisePlanList.vue'], resolve);
                }
            },
            {
                path: 'exerciseFamilyStandard',
                icon: 'ios-list-box-outline',
                name: 'exerciseFamilyStandard',
                title: '视频课后作业配置',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/home_work/home_work_manage/exerciseFamilyStandard.vue'], resolve);
                }
            },
            {
                path: 'AIHomeWorkStandard',
                icon: 'ios-list-box-outline',
                name: 'AIHomeWorkStandard',
                title: 'AI课后作业配置',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/home_work/home_work_manage/AIHomeWorkStandard.vue'], resolve);
                }
            },
            {
                path: 'AIAddHomeWork',
                icon: 'ios-list-box-outline',
                name: 'AIAddHomeWork',
                title: '添加AI作业',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/home_work/home_work_manage/AIAddHomeWork.vue'], resolve);
                }
            },
            {
                path: 'vacationConfiguration',
                icon: 'ios-list-box-outline',
                name: 'vacationConfiguration',
                title: '假期课后作业配置',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/home_work/home_work_manage/vacationConfiguration.vue'], resolve);
                }
            },
            {
                path: 'exercisePlanDetail',
                icon: 'ios-list-box-outline',
                name: 'exercisePlanDetail',
                title: '锻炼计划详情',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/home_work/home_work_manage/exercisePlanDetail.vue'], resolve);
                }
            },
            {
                path: 'exciseSchoolDetail',
                icon: 'ios-list-box-outline',
                name: 'exciseSchoolDetail',
                title: '锻炼计划详情',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/home_work/home_work_manage/exciseSchoolDetail.vue'], resolve);
                }
            },
            {
                path: 'studentExerciseManage',
                icon: 'ios-list-box-outline',
                name: 'studentExerciseManage',
                title: '学生锻炼管理',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/home_work/home_work_manage/studentExerciseManage.vue'], resolve);
                }
            },
            {
                path: 'exerciseComboList',
                icon: 'ios-list-box-outline',
                name: 'exerciseComboList',
                title: '作业标准配置',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/home_work/home_work_manage/exerciseComboList.vue'], resolve);
                }
            },
            {
                path: 'exerciseComboDetail',
                icon: 'ios-list-box-outline',
                name: 'exerciseComboDetail',
                title: '作业标准配置',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/home_work/home_work_manage/exerciseComboDetail/index.vue'], resolve);
                }
            },
            {
                path: 'exerciseFamilyStandardSingle',
                icon: 'ios-list-box-outline',
                name: 'exerciseFamilyStandardSingle',
                title: '课后作业标准配置',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/home_work/home_work_manage/exerciseFamilyStandardSingle.vue'], resolve);
                }
            },
            {
                path: 'homeworkRecord',
                icon: 'ios-list-box-outline',
                name: 'homeworkRecord',
                title: '课后作业考核录入',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/home_work/home_work_manage/homeworkRecord.vue'], resolve);
                }
            },
            {
                path: 'familyExerciseAnalyze',
                icon: 'ios-list-box-outline',
                name: 'familyExerciseAnalyze',
                title: '课后作业分析',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/home_work/dataAnalyze/familyExerciseAnalyze.vue'], resolve);
                }
            }
        ]
    },
    {
        path: '/exerciseManage',
        icon: 'ios-podium',
        name: 'exerciseManage',
        title: '锻炼数据管理',
        parentCode: 'top_homeWork',
        parentName: '课后作业',
        parentIcon: 'ios-basketball-outline',
        disabled: false,
        component: Main,
        children: [
            {
                path: 'exerciseManageDate',
                icon: 'ios-list-box-outline',
                name: 'exerciseManageDate',
                title: '按日期查询',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/home_work/exerciseManage/exerciseManageDate.vue'], resolve);
                }
            },
            {
                path: 'exerciseManageStudent',
                icon: 'ios-list-box-outline',
                name: 'exerciseManageStudent',
                title: '按学生查询',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/home_work/exerciseManage/exerciseManageStudent.vue'], resolve);
                }
            },
            {
                path: 'additionalRecordingStudentManage',
                icon: 'ios-list-box-outline',
                name: 'additionalRecordingStudentManage',
                title: '课后作业补录',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/home_work/exerciseManage/additionalRecordingStudentManage.vue'], resolve);
                }
            },
            {
                path: 'exerciseManageClass',
                icon: 'ios-list-box-outline',
                name: 'exerciseManageClass',
                title: '按班级查询',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/home_work/exerciseManage/exerciseManageClass.vue'], resolve);
                }
            },
            {
                path: 'exerciseManageGrade',
                icon: 'ios-list-box-outline',
                name: 'exerciseManageGrade',
                title: '按年级查询',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/home_work/exerciseManage/exerciseManageGrade.vue'], resolve);
                }
            },
            {
                path: 'exerciseManageStudentDetail',
                icon: 'ios-list-box-outline',
                name: 'exerciseManageStudentDetail',
                title: '学生锻炼详情',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/home_work/exerciseManage/exerciseManageStudentDetail.vue'], resolve);
                }
            },
            {
                path: 'exerciseManageDateDetail',
                icon: 'ios-list-box-outline',
                name: 'exerciseManageDateDetail',
                title: '锻炼数据管理',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/home_work/exerciseManage/exerciseManageDateDetail.vue'], resolve);
                }
            },
            {
                path: 'exerciseManageStudentSingle',
                icon: 'ios-list-box-outline',
                name: 'exerciseManageStudentSingle',
                title: '按学生查询',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/home_work/exerciseManage/exerciseManageStudentSingle.vue'], resolve);
                }
            },
            {
                path: 'exerciseManageClassSingle',
                icon: 'ios-list-box-outline',
                name: 'exerciseManageClassSingle',
                title: '按班级查询',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/home_work/exerciseManage/exerciseManageClassSingle.vue'], resolve);
                }
            },
            {
                path: 'exerciseManageGradeSingle',
                icon: 'ios-list-box-outline',
                name: 'exerciseManageGradeSingle',
                title: '按年级查询',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/home_work/exerciseManage/exerciseManageGradeSingle.vue'], resolve);
                }
            }
        ]
    }
]