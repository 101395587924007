var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login_box", style: _vm.contianerBg },
    [
      _vm.isLzjy == 1
        ? _c("div", { staticClass: "lzjy-container" }, [
            _vm._m(0),
            _vm._v(" "),
            _vm._m(1),
          ])
        : _vm.isSingleLogin
        ? _c("div", { staticClass: "welcome-container" }, [
            _vm._m(2),
            _vm._v(" "),
            _vm._m(3),
          ])
        : _vm.holdOn
        ? _c("div", { staticClass: "writeAllScreen" })
        : _c("div", { staticClass: "default-container" }, [
            _c("section", { staticClass: "section fcc" }, [
              _c("h2", { staticClass: "login-title" }, [
                _vm._v(_vm._s(_vm.loginTitle)),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form" },
                [
                  _c("img", {
                    staticClass: "welcome",
                    attrs: { src: _vm.welcome, alt: "welcomeLogin" },
                  }),
                  _vm._v(" "),
                  _c(
                    "Form",
                    {
                      ref: "loginForm",
                      attrs: { model: _vm.form, rules: _vm.rules },
                    },
                    [
                      _c("div", { staticClass: "userSign" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.login
                                ? "用户登录"
                                : _vm.isNowState === 3
                                ? "修改密码"
                                : "找回密码"
                            )
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "formData fcb" }, [
                        _vm.login
                          ? _c(
                              "div",
                              {
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.handleSubmit.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "FormItem",
                                  {
                                    staticClass: "login-item",
                                    attrs: { prop: "userName" },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("../../images/userName.png"),
                                        alt: "",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("Input", {
                                      staticClass: "login-name",
                                      attrs: {
                                        placeholder: "请输入账号",
                                        maxlength: 11,
                                      },
                                      model: {
                                        value: _vm.form.userName,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "userName", $$v)
                                        },
                                        expression: "form.userName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "FormItem",
                                  {
                                    staticClass: "login-item",
                                    attrs: { prop: "password" },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("../../images/password.png"),
                                        alt: "",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("Input", {
                                      staticClass: "login-name",
                                      attrs: {
                                        type: "password",
                                        placeholder: "请输入密码",
                                        minlength: 6,
                                      },
                                      model: {
                                        value: _vm.form.password,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "password", $$v)
                                        },
                                        expression: "form.password",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "FormItem",
                                  {
                                    staticClass: "login-item",
                                    attrs: { prop: "code" },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("../../images/code.png"),
                                        alt: "",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("Input", {
                                      attrs: {
                                        placeholder: "请输入验证码",
                                        maxlength: 4,
                                      },
                                      model: {
                                        value: _vm.form.code,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "code", $$v)
                                        },
                                        expression: "form.code",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("img", {
                                      staticClass: "code-img",
                                      attrs: { alt: "", src: _vm.codeUrl },
                                      on: { click: _vm.changeCode },
                                    }),
                                    _vm._v(" "),
                                    _c("img", {
                                      staticClass: "reload",
                                      attrs: {
                                        alt: "",
                                        src: require("../../images/reload.png"),
                                      },
                                      on: { click: _vm.changeCode },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.login
                          ? _c(
                              "div",
                              {
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.handleSubmitForget.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "FormItem",
                                  {
                                    staticClass: "login-item",
                                    attrs: { prop: "userNameAgain" },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("../../images/userName.png"),
                                        alt: "",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("Input", {
                                      staticClass: "login-name",
                                      staticStyle: { flex: "1" },
                                      attrs: {
                                        placeholder:
                                          _vm.isNowState === 3
                                            ? "请输入需修改密码的用户名"
                                            : "请输入需找回密码的用户名",
                                        maxlength: 11,
                                      },
                                      model: {
                                        value: _vm.form.userNameAgain,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "userNameAgain",
                                            $$v
                                          )
                                        },
                                        expression: "form.userNameAgain",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "sendCode",
                                        style: _vm.codeStyle,
                                        on: { click: _vm.changePhoneCode },
                                      },
                                      [_vm._v(_vm._s(_vm.codeTxt))]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "FormItem",
                                  {
                                    staticClass: "login-item",
                                    attrs: { prop: "codeAgain" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "flex-c" },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("../../images/code.png"),
                                            alt: "",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("Input", {
                                          staticClass: "login-name",
                                          attrs: {
                                            placeholder: "请输入验证码",
                                            maxlength: 6,
                                          },
                                          model: {
                                            value: _vm.form.codeAgain,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "codeAgain",
                                                $$v
                                              )
                                            },
                                            expression: "form.codeAgain",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "FormItem",
                                  {
                                    staticClass: "login-item",
                                    attrs: { prop: "newPassword" },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("../../images/password.png"),
                                        alt: "",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("Input", {
                                      staticClass: "login-name",
                                      attrs: {
                                        type: "password",
                                        placeholder: "请输入新密码",
                                        minlength: 8,
                                      },
                                      model: {
                                        value: _vm.form.newPassword,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "newPassword", $$v)
                                        },
                                        expression: "form.newPassword",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "FormItem",
                                  {
                                    staticClass: "login-item",
                                    attrs: { prop: "passWordAgain" },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("../../images/password.png"),
                                        alt: "",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("Input", {
                                      staticClass: "login-name",
                                      attrs: {
                                        type: "password",
                                        placeholder: "请再次输入新密码",
                                        minlength: 8,
                                      },
                                      model: {
                                        value: _vm.form.passWordAgain,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "passWordAgain",
                                            $$v
                                          )
                                        },
                                        expression: "form.passWordAgain",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.SystemType !== "qiangji"
                          ? _c(
                              "div",
                              {
                                staticClass: "sCode",
                                class: {
                                  visHidden:
                                    _vm.computerPhone === "020-38939139",
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("../../images/sCode.png"),
                                    alt: "二维码",
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "goWechart" }, [
                                  _vm._v("微信扫一扫"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "goWechart" }, [
                                  _vm._v("进入教师移动端"),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "Forget" }, [
                        _c("span", { on: { click: _vm.forgetThePwd } }, [
                          _vm._v(_vm._s(_vm.login ? "忘记密码？" : "返回登录")),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "FormItem",
                        { staticClass: "login-check" },
                        [
                          _vm.login
                            ? _c(
                                "Button",
                                {
                                  staticClass: "login-button",
                                  attrs: { type: "success", long: "" },
                                  on: { click: _vm.handleSubmit },
                                },
                                [_vm._v("登 录")]
                              )
                            : _c(
                                "Button",
                                {
                                  staticClass: "login-button",
                                  staticStyle: { "margin-top": "1.3021vw" },
                                  attrs: { type: "success", long: "" },
                                  on: { click: _vm.handleSubmitForget },
                                },
                                [_vm._v("\n              登 录\n            ")]
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("footer", [
              _c("div", { staticClass: "flex-c" }, [
                _c("span", [_vm._v("版本号：2.4.9")]),
                _vm._v(" "),
                _c("span", [_vm._v("技术支持：" + _vm._s(_vm.computerPhone))]),
              ]),
              _vm._v(" "),
              _vm.SystemType !== "qiangji"
                ? _c("span", [_vm._v(_vm._s(_vm.asideTxt))])
                : _vm._e(),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  "( 推荐浏览器：谷歌浏览器Google Chrome | 推荐分辨率：1920*1080 )"
                ),
              ]),
            ]),
          ]),
      _vm._v(" "),
      _c(
        "Modal",
        {
          attrs: {
            title: "提醒",
            mask: true,
            closable: false,
            "mask-closable": false,
            "ok-text": "立即修改",
            "cancel-text": "暂不",
          },
          on: { "on-ok": _vm.confirmBack, "on-cancel": _vm.cancelBack },
          model: {
            value: _vm.pwdCheckModal,
            callback: function ($$v) {
              _vm.pwdCheckModal = $$v
            },
            expression: "pwdCheckModal",
          },
        },
        [
          _c("p", [
            _vm._v("您的密码过于简单，为保障信息安全，请您立即修改密码"),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "welcome-left flex-c" }, [
      _c("img", {
        staticClass: "company-logo",
        attrs: { src: require("./imgs/logo@2x.png") },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "aside-container animate__animated animate__backInLeft",
        },
        [
          _c("div", [
            _c("span", [_vm._v("欢迎使用")]),
            _vm._v(" "),
            _c("span", { staticClass: "wel" }, [_vm._v("welcome")]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "wel-title" }, [
            _vm._v("智慧体育教育SaaS云平台"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "welcome-right" }, [
      _c("img", {
        staticClass: "company-aside",
        attrs: { src: require("./imgs/icon_login.png"), alt: "小马健康" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "welcome-left flex-c" }, [
      _c("img", {
        staticClass: "company-logo",
        attrs: {
          src: require("../../images/login/login-logo.png"),
          alt: "小马健康",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "aside-container animate__animated animate__backInLeft",
        },
        [
          _c("div", [
            _c("span", [_vm._v("欢迎使用")]),
            _vm._v(" "),
            _c("span", { staticClass: "wel" }, [_vm._v("welcome")]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "wel-title" }, [
            _vm._v("智慧体育教育SaaS云平台"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "welcome-right" }, [
      _c("img", {
        staticClass: "company-aside",
        attrs: {
          src: require("../../images/login/login-welcome.png"),
          alt: "小马健康",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }