import Vue from 'vue';
import Vuex from 'vuex';

import app from './modules/app';
import user from './modules/user';
import pageParams from './modules/params';

Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    schoolDistrictStore: {
      CD: '1000',
      YN: '2000',
      XA: '3000',
      YB: '5000'
    },
    eventBus: {},
    cometitionLevel: [],
    kmSession: null, //基础配置-学校管理-班级课程管理
    evaluate: null, //综合评价-综合评价管理-所有到详情的参数
    loginAgain: false, //登录状态
    activeTopMenuName: 'top_config-baseConfig', // 选中的顶部菜单名称
    themeList: [
      {
        id: 'theme-default',
        name: '默认主题',
        color: '#00c693'
      }, {
        id: 'theme-simple',
        name: '简单主题',
        color: '#30A37D'
      }
    ],
    isFullscreen: false, // 是否是全屏
    isUpdateDataDeskPage: '1', // 是否更新首页内容
    themeActiveColor: '#30A37D'
  },
  getters: {},
  actions: {
    setEventBus({ commit, state }, event) {
      commit("setEventNow", event);
    },
    setSessionFun({ commit, state }, data) {
      commit("setSession", data);
    },
    setEvaluateFun({ commit, state }, data) {
      commit("setEvaluate", data);
    },
    /** 更改顶部菜单默认选中 */
    updateActiveTopMenuName({ commit }, paylod) {
      commit('UPDATE_ACTIVE_TOP_MENU_NAME', paylod)
    },
    /** 更改全屏状态 */
    updateFullScreenStatus({ commit }, paylod) {
      commit('UPDATE_FULL_SCREEN_STATUS', paylod)
    },
    /** 切换下拉菜单时更新首页内容 */
    updateDataDeskPage({ commit }, paylod) {
      commit('UPDATE_DATADESK_Page', paylod)
    },
  },
  mutations: {
    /** 切换下拉菜单时更新首页内容 */
    UPDATE_DATADESK_Page(state, data) {
      state.isUpdateDataDeskPage = data
    },
    /** 更改全屏状态 */
    UPDATE_FULL_SCREEN_STATUS(state, data) {
      state.isFullscreen = data
    },
    /** 更改顶部菜单默认选中 */
    UPDATE_ACTIVE_TOP_MENU_NAME(state, name) {
      state.activeTopMenuName = name
    },
    setEventNow(state, event) {
      state.eventBus = event;
    },
    setCompetitionLevel(state, paylod) {
      state.cometitionLevel = paylod;
    },
    setSession(state, data) {
      state.kmSession = data;
    },
    setEvaluate(state, data) {
      state.evaluate = data;
    },
    setLoginStatus(state, status) {
      state.loginAgain = status
    },
    setThemeColor(state, color) {
      state.themeActiveColor = color
    },

  },
  modules: {
    app,
    user,
    pageParams
  }
});

export default store;
