import Vue from 'vue';
import iView from 'iview';
import { router } from './router/index';
import store from './store';
import App from './app.vue';
import 'iview/dist/styles/iview.css';
import axios from './libs/request';

import * as echarts from 'echarts';
import '../theme/index.less';
import './styles/common.less';
import './styles/custom.less';
import utils from './libs/util'
import 'regenerator-runtime/runtime'



Vue.use(iView);
Vue.prototype.$axios = axios;

Vue.prototype.$echarts = echarts;
Vue.prototype.$utils = utils;

Vue.prototype.$tableScroll = (table) => {
    table.$refs.body.scrollTop = 0;
    table.$refs.body.scrollLeft = 0;
};
Vue.prototype.$Message.config({
    top: 400,
    duration: 2
});

new Vue({
    el: '#app',
    router: router,
    store: store,
    render: h => h(App),
    mounted() {
        this.$store.commit('updateMenulist');
    }
});
