
/**
 * DEVELOP - 测试环境
 * RELEASE - 正式环境
 * BASE_URL - 探马请求地址
 * Z_H_URL - 珠海请求地址
 * QIANG_JI_URL - 强基请求地址
 * SHUANG_LIU_URL - 双流请求地址
 * WJ_KC_URL - 温江科创请求地址
 * JIA_FA_URL - 佳发请求地址
 * ANSWER_Url - 答题系统
 * */ 

export const DEVELOP_BASE_URL_PATH = 'https://patriarch-tm.tanmasports.com/organ'
export const RELEASE_BASE_URL_PATH = 'https://patriarch.tanmasports.com/organ';

export const DEVELOP_Z_H_URL_PATH = 'https://zhschool.tanmasports.com'
export const RELEASE_Z_H_URL_PATH = 'https://zhschool.tanmasports.com';

export const DEVELOP_QIANG_JI_URL_PATH = 'https://qjsc-tm.tanmasports.cn'
export const RELEASE_QIANG_JI_URL_PATH = 'qjsc.tanmasports.com';

export const DEVELOP_SHUANG_LIU_URL_PATH = 'https://cdsl.school-tm.tanmasports.cn'
export const RELEASE_SHUANG_LIU_URL_PATH = 'https://cdsl.school.tanmasports.com';

export const DEVELOP_WJ_KC_URL_PATH = 'https://wjkc.school-tm.tanmasports.cn'
export const RELEASE_WJ_KC_URL_PATH = 'https://wjkc.school.tanmasports.com';

export const DEVELOP_JIA_FA_URL_PATH = 'http://110.185.174.59:10881'
export const RELEASE_JIA_FA_URL_PATH = 'https://smart.cdsledu.net';

export const DEVELOP_ANSWER_URL_PATH = 'https://html-test.tanmasports.com/page/'
export const RELEASE_ANSWER_URL_PATH = 'https://html.tanmasports.com/page/';