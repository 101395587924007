import Main from '@/views/MAIN/Main.vue';
export default [
  {
    path: '/sportsClassMonitoring',
    icon: 'logo-instagram',
    name: 'sportsClassMonitoring',
    title: '学习态度',
    parentCode: 'top_sportsClass',
    parentName: '课堂监控',
    parentIcon: 'logo-python',
    disabled: false,
    component: Main,
    children: [
      {
        path: 'sportsClassStandard',
        icon: 'ios-list-box-outline',
        name: 'sportsClassStandard',
        title: '评分标准',
        component: resolve => {
          require(['@/views/COMMON_VIEW/classroom_monitoring/learningAttitude/sportsClassStandard.vue'], resolve);
        }
      },
      {
        path: 'sportsClassManage',
        icon: 'ios-list-box-outline',
        name: 'sportsClassManage',
        title: '学习态度管理',
        component: resolve => {
          require(['@/views/COMMON_VIEW/classroom_monitoring/learningAttitude/sportsClassManage.vue'], resolve);
        }
      },
      {
        path: 'sportsClassStudentHis',
        icon: 'ios-list-box-outline',
        name: 'sportsClassStudentHis',
        title: '学习态度记录',
        component: resolve => {
          require(['@/views/COMMON_VIEW/classroom_monitoring/learningAttitude/sportsClassStudentHis.vue'], resolve);
        }
      },
      {
        path: 'sportsClassAnalyze',
        icon: 'ios-list-box-outline',
        name: 'sportsClassAnalyze',
        title: '学习态度分析',
        component: resolve => {
          require(['@/views/COMMON_VIEW/classroom_monitoring/learningAttitude/classroomMonitoring.vue'], resolve);
        }
      }
    ]
  },
  {
    path: '/sportsHealthData',
    icon: 'md-bicycle',
    name: 'sportsHealthData',
    title: '运动与健康数据',
    parentCode: 'top_sportsClass',
    parentName: '课堂监控',
    parentIcon: 'logo-python',
    disabled: false,
    component: Main,
    children: [
      { // 昆明、成都中小学（列表页）
        path: 'classMonitoringData',
        icon: 'ios-list-box-outline',
        name: 'classMonitoringData',
        title: '课堂监测数据',
        component: resolve => { require(['@/views/COMMON_VIEW/classroom_monitoring/sportsAndHealthData/index.vue'], resolve); }
      },
      { // 成都-高中（列表页）
        path: 'classMonitoringDataHigh',
        icon: 'ios-list-box-outline',
        name: 'classMonitoringDataHigh',
        title: '课堂监测数据',
        component: resolve => { require(['@/views/CITY_CD/highSchool/classMonitorData/index.vue'], resolve); }
      },
      { // 监测报告-详情页（中小学、高中、昆明共用）
        path: 'courseMonitoringReport',
        icon: 'ios-list-box-outline',
        name: 'courseMonitoringReport',
        title: '监测报告',
        component: resolve => {
          require(['@/views/COMMON_VIEW/classroom_monitoring/sportsAndHealthData/reportDetail.vue'], resolve);
        }
      },
      {
        path: 'dailyMonitoringData',
        icon: 'ios-list-box-outline',
        name: 'dailyMonitoringData',
        title: '日常监测数据',
        component: resolve => {
          require(['@/views/COMMON_VIEW/classroom_monitoring/sportsAndHealthData/dailyMonitoringData.vue'], resolve);
        }
      },
      {
        path: 'classMonitoringStudentDetail',
        icon: 'ios-list-box-outline',
        name: 'classMonitoringStudentDetail',
        title: '学生课堂心率走势详情',
        component: resolve => {
          require(['@/views/COMMON_VIEW/classroom_monitoring/sportsAndHealthData/studentDetail.vue'], resolve);
        }
      },
      {
        path: 'classMonitorConfig',
        icon: 'ios-paper-outline',
        name: 'classMonitorConfig',
        title: '课堂监测配置',
        component: resolve => { require(['@/views/COMMON_VIEW/classroom_monitoring/classMonitorConfig/index.vue'], resolve); }
      },
    ]
  },
  {
    path: '/outdoorSportManage',
    icon: 'md-walk',
    name: 'outdoorSportManage',
    title: '户外运动',
    parentCode: 'top_sportsClass',
    parentName: '课堂监控',
    parentIcon: 'logo-python',
    disabled: false,
    component: Main,
    children: [
      {
        path: 'outdoorSportData',
        icon: 'ios-list-box-outline',
        name: 'outdoorSportData',
        title: '户外运动',
        component: resolve => {
          require(['@/views/COMMON_VIEW/classroom_monitoring/outdoorSportManage/outdoorSportData/index.vue'], resolve);
        }
      },
      {
        path: 'addOrEditCurriculum',
        icon: 'ios-list-box-outline',
        name: 'addOrEditCurriculum',
        title: '创建/编辑课程',
        component: resolve => {
          require(['@/views/COMMON_VIEW/classroom_monitoring/outdoorSportManage/addOrEditCurriculum/index.vue'], resolve);
        }
      },
      {
        path: 'outdoorSportsReport',
        icon: 'ios-list-box-outline',
        name: 'outdoorSportsReport',
        title: '运动报告',
        component: resolve => {
          require(['@/views/COMMON_VIEW/classroom_monitoring/outdoorSportManage/outdoorSportsReport/index.vue'], resolve);
        }
      },
      {
        path: 'studentSportDetail',
        icon: 'ios-list-box-outline',
        name: 'studentSportDetail',
        title: '学生运动数据详情',
        component: resolve => {
          require(['@/views/COMMON_VIEW/classroom_monitoring/outdoorSportManage/outdoorSportsReport/studentSportDetail.vue'], resolve);
        }
      },
    ]
  },
]