import Main from '@/views/MAIN/Main.vue';
export default [
  {
    path: '/sportsClassMonitoring',
    icon: 'logo-instagram',
    name: 'sportsClassMonitoring',
    title: '学习态度',
    parentCode: 'top_sportsClass',
    parentName: '课堂监控',
    parentIcon: 'logo-python',
    disabled: false,
    component: Main,
    children: [
      {
        path: 'sportsClassStandard',
        icon: 'ios-list-box-outline',
        name: 'sportsClassStandard',
        title: '评分标准',
        component: resolve => {
          require(['@/views/COMMON_VIEW/classroom_monitoring/learningAttitude/sportsClassStandard.vue'], resolve);
        }
      },
      {
        path: 'sportsClassManage',
        icon: 'ios-list-box-outline',
        name: 'sportsClassManage',
        title: '学习态度管理',
        component: resolve => {
          require(['@/views/COMMON_VIEW/classroom_monitoring/learningAttitude/sportsClassManage.vue'], resolve);
        }
      },
      {
        path: 'sportsClassStudentHis',
        icon: 'ios-list-box-outline',
        name: 'sportsClassStudentHis',
        title: '学习态度记录',
        component: resolve => {
          require(['@/views/COMMON_VIEW/classroom_monitoring/learningAttitude/sportsClassStudentHis.vue'], resolve);
        }
      },
      {
        path: 'sportsClassAnalyze',
        icon: 'ios-list-box-outline',
        name: 'sportsClassAnalyze',
        title: '学习态度分析',
        component: resolve => {
          require(['@/views/COMMON_VIEW/classroom_monitoring/learningAttitude/classroomMonitoring.vue'], resolve);
        }
      }
    ]
  },
  
  
]