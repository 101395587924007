var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "error500" }, [
    _c(
      "div",
      { staticClass: "error500-body-con" },
      [
        _c("Card", [
          _c("div", { staticClass: "error500-body-con-title" }, [
            _vm._v("\n                5"),
            _c(
              "span",
              { staticClass: "error500-0-span" },
              [_c("Icon", { attrs: { type: "social-freebsd-devil" } })],
              1
            ),
            _c(
              "span",
              { staticClass: "error500-0-span" },
              [_c("Icon", { attrs: { type: "social-freebsd-devil" } })],
              1
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "error500-body-con-message" }, [
            _vm._v("Oops! the server is wrong"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "error500-btn-con" },
            [
              _c(
                "Button",
                {
                  staticStyle: { width: "200px" },
                  attrs: { size: "large", type: "text" },
                  on: { click: _vm.goHome },
                },
                [_vm._v("返回首页")]
              ),
              _vm._v(" "),
              _c(
                "Button",
                {
                  staticStyle: { width: "200px", "margin-left": "40px" },
                  attrs: { size: "large", type: "primary" },
                  on: { click: _vm.backPage },
                },
                [_vm._v("返回上一页")]
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }