<style lang="less">
@import './home.less';
</style>
<template>
  <div class="home-main"></div>
</template>

<script>
export default {
  name: 'home',
  data() {
    return {
      //
    }
  },
  mounted() {}
}
</script>
