import md5 from "js-md5"; 
const mixins = {
   data () {
     return {
      //重新登录相关属性
       againParams: {
        userPhone:'',
        againPassword:'',
        code:'',
        userCode:'',
        codeSource:''
      },
      rules: {
        userPhone: [
          {
            required: true,
            message: "账号不能为空",
            trigger: "blur",
          },
        ],
        againPassword: [
          {
            required: true,
            message: "密码不能为空",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "验证码不能为空",
            trigger: "blur",
          },
        ]
      },
      loginStep:false,//是否重新登录
     }
   },
   methods: {
    isAgainLogin(){
      const { jiafaURL } = this.$axios.defaults;
      const { isDefaultLogout } = this.useLoginOut()
      if(!isDefaultLogout) {
        sessionStorage.clear()
        this.$router.push({ name: 'login'})
      } else {
        this.loginStep = true;
      }
    },
    againLogin(){
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          const password = md5(this.againParams.againPassword);
          const userPhone = sessionStorage.getItem('phoneNo');
          const schoolId = sessionStorage.getItem('schoolId');
          const params = {
            userPhone: userPhone,
            password: password,
            code: this.againParams.code,
            userCode: this.againParams.userCode,
            schoolId: schoolId,
            schoolType: 2,
            type: '1'
          };
          this.$axios.post("/v1/auth/login/password", params).then((res) => {
            if(res.data.code === 10000){
              let { response } = res.data;
              sessionStorage.setItem("token", response.oAuthTokenVO.token);
              this.loginStep = false;
              this.$store.commit('setLoginStatus', false);
            }else{
              this.$Message.info(res.data.msg);
            }
          })
        }
      })
    },
    changeCode() {
      const myDate = new Date();
      this.againParams.userCode = myDate.getTime();
      this.againParams.codeSource = `${this.$axios.defaults.baseURL}/v1/auth/login/getCheckCode?userCode=${this.againParams.userCode}`;
    },
    cancelLogin(){
      this.$store.commit('setLoginStatus', false);
      this.loginStep = false;
      const { jiafaURL } = this.$axios.defaults;
      const { isDefaultLogout } = this.useLoginOut()
      if(!isDefaultLogout) {
        sessionStorage.clear()
        window.location.replace(`${jiafaURL}/sso/logout`);
      } else {
        this.$router.push({ name: 'login'})
      }
    },
    // 正常退出,还是跳转单点退出
    useLoginOut(){
      const { shuangliuURL } = this.$axios.defaults;
      let { origin } = window.location;
      let isDefaultLogout = origin === shuangliuURL ? false : true;
      return {
        isDefaultLogout
      }
    },
   }
 }
export default mixins;