<template>
  <div class="login_box" :style="contianerBg">
    <div class="lzjy-container" v-if="isLzjy == 1">
      <div class="welcome-left flex-c">
        <img class="company-logo" src="./imgs/logo@2x.png" />
        <div class="aside-container animate__animated animate__backInLeft">
          <div>
            <span>欢迎使用</span>
            <span class="wel">welcome</span>
          </div>
          <div class="wel-title">智慧体育教育SaaS云平台</div>
        </div>
      </div>
      <div class="welcome-right">
        <img class="company-aside" src="./imgs/icon_login.png" alt="小马健康" />
      </div>
    </div>
    <!-- 双流单点登录 -->
    <div class="welcome-container" v-else-if="isSingleLogin">
      <div class="welcome-left flex-c">
        <img class="company-logo" src="../../images/login/login-logo.png" alt="小马健康" />
        <div class="aside-container animate__animated animate__backInLeft">
          <div>
            <span>欢迎使用</span>
            <span class="wel">welcome</span>
          </div>
          <div class="wel-title">智慧体育教育SaaS云平台</div>
        </div>
      </div>
      <div class="welcome-right">
        <img class="company-aside" src="../../images/login/login-welcome.png" alt="小马健康" />
      </div>
    </div>
    <!-- 登录回跳后的白屏过渡 -->
    <div class="writeAllScreen" v-else-if="holdOn"></div>
    <!-- 正常流程 -->
    <div class="default-container" v-else>
      <section class="section fcc">
        <h2 class="login-title">{{ loginTitle }}</h2>
        <div class="form">
          <img class="welcome" :src="welcome" alt="welcomeLogin" />
          <Form ref="loginForm" :model="form" :rules="rules">
            <div class="userSign">
              <span>{{ login ? '用户登录' : isNowState === 3 ? '修改密码' : '找回密码' }}</span>
            </div>
            <div class="formData fcb">
              <!-- 登录 -->
              <div v-if="login" @keydown.enter="handleSubmit">
                <FormItem prop="userName" class="login-item">
                  <img src="../../images/userName.png" alt />
                  <Input v-model="form.userName" class="login-name" placeholder="请输入账号" :maxlength="11"></Input>
                </FormItem>
                <FormItem prop="password" class="login-item">
                  <img src="../../images/password.png" alt />
                  <Input
                    v-model="form.password"
                    type="password"
                    class="login-name"
                    placeholder="请输入密码"
                    :minlength="6"
                  ></Input>
                </FormItem>
                <FormItem prop="code" class="login-item">
                  <img src="../../images/code.png" alt />
                  <Input v-model="form.code" placeholder="请输入验证码" :maxlength="4"></Input>
                  <img class="code-img" @click="changeCode" alt :src="codeUrl" />
                  <img class="reload" @click="changeCode" alt src="../../images/reload.png" />
                </FormItem>
              </div>
              <!-- 找回密码/修改密码 -->
              <div v-if="!login" @keydown.enter="handleSubmitForget">
                <FormItem prop="userNameAgain" class="login-item">
                  <img src="../../images/userName.png" alt />
                  <Input
                    v-model="form.userNameAgain"
                    class="login-name"
                    :placeholder="isNowState === 3 ? '请输入需修改密码的用户名' : '请输入需找回密码的用户名'"
                    :maxlength="11"
                    style="flex: 1"
                  ></Input>
                  <span class="sendCode" @click="changePhoneCode" :style="codeStyle">{{ codeTxt }}</span>
                </FormItem>

                <FormItem prop="codeAgain" class="login-item">
                  <div class="flex-c">
                    <img src="../../images/code.png" alt />
                    <Input
                      v-model="form.codeAgain"
                      class="login-name"
                      placeholder="请输入验证码"
                      :maxlength="6"
                    ></Input>
                  </div>
                </FormItem>
                <FormItem prop="newPassword" class="login-item">
                  <img src="../../images/password.png" alt />
                  <Input
                    v-model="form.newPassword"
                    class="login-name"
                    type="password"
                    placeholder="请输入新密码"
                    :minlength="8"
                  ></Input>
                </FormItem>
                <!-- <p class="password_tip">密码需要8-20个位，必须包含数字、英文字母大、小写</p> -->

                <FormItem prop="passWordAgain" class="login-item">
                  <img src="../../images/password.png" alt />
                  <Input
                    v-model="form.passWordAgain"
                    class="login-name"
                    type="password"
                    placeholder="请再次输入新密码"
                    :minlength="8"
                  ></Input>
                </FormItem>
              </div>
              <div
                class="sCode"
                :class="{ visHidden: computerPhone === '020-38939139' }"
                v-if="SystemType !== 'qiangji'"
              >
                <img src="../../images/sCode.png" alt="二维码" />
                <div class="goWechart">微信扫一扫</div>
                <div class="goWechart">进入教师移动端</div>
              </div>
            </div>
            <div class="Forget">
              <span @click="forgetThePwd">{{ login ? '忘记密码？' : '返回登录' }}</span>
            </div>
            <FormItem class="login-check">
              <Button class="login-button" @click="handleSubmit" type="success" long v-if="login">登 录</Button>
              <Button
                class="login-button"
                @click="handleSubmitForget"
                type="success"
                long
                v-else
                style="margin-top: 1.3021vw"
              >
                登 录
              </Button>
            </FormItem>
          </Form>
        </div>
      </section>
      <footer>
        <div class="flex-c">
          <span>版本号：2.4.9</span>
          <span>技术支持：{{ computerPhone }}</span>
        </div>

        <span v-if="SystemType !== 'qiangji'">{{ asideTxt }}</span>
        <span>( 推荐浏览器：谷歌浏览器Google Chrome | 推荐分辨率：1920*1080 )</span>
      </footer>
    </div>

    <Modal
      v-model="pwdCheckModal"
      title="提醒"
      :mask="true"
      :closable="false"
      :mask-closable="false"
      ok-text="立即修改"
      cancel-text="暂不"
      @on-ok="confirmBack"
      @on-cancel="cancelBack"
    >
      <p>您的密码过于简单，为保障信息安全，请您立即修改密码</p>
    </Modal>
  </div>
</template>

<script>
import md5 from 'js-md5'
import welcome from '../../images/welcome.png'
import qiangji from '../../images/qiangji.png'
import guangzhou from '../../images/guangzhou.png'
import Login from '@/api/login'
import Util from '@/libs/util.js'

let thisObject = null

const userNameCheck = (rule, value, callback) => {
  let str = value
  let regExps = new RegExp(/^1[34578]\d{9}$/)
  if (str == null || str.length < 11) {
    callback(new Error('手机号不能少于11位'))
  } else if (!regExps.test(thisObject.form.userNameAgain)) {
    callback(new Error('手机号格式不正确'))
  } else {
    callback()
  }
}
const pwdCheck = (rule, value, callback) => {
  let str = value
  const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,20}$/
  if (str == null || str.length < 8) {
    callback(new Error('密码需要8-20个位，必须包含数字、英文字母大、小写'))
  } else if (!reg.test(str)) {
    callback(new Error('密码需要8-20个位，必须包含数字、英文字母大、小写'))
  } else {
    callback()
  }
}
const pwdCheckValidate = (rule, value, callback) => {
  let str = value
  if (str == null || str.length < 8) {
    callback(new Error('密码需要8-20个位，必须包含数字、英文字母大、小写'))
  } else if (thisObject.form.newPassword != thisObject.form.passWordAgain) {
    callback(new Error('两次输入密码不一致'))
  } else {
    callback()
  }
}
// 密码验证规则
const validatePass = value => {
  const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,20}$/
  return reg.test(value)
}
// 获取参数
const getUrlValue = key => {
  const fragment = window.location.href.split('#')[1]
  const queryString = fragment.split('?')[1]
  const params = new URLSearchParams(queryString)
  return params.get(key)
}

export default {
  data() {
    return {
      schoolType: 2,
      login: true,
      countdown: 60,
      codeTxt: '发送验证码',
      isShowPng: true,
      loginTitle: '智慧体育教育SaaS云平台',
      codeStyle: {
        background: '#2DB394'
      },
      form: {
        userName: '',
        password: '',
        code: '',
        userNameAgain: '',
        codeAgain: '',
        newPassword: '',
        passWordAgain: ''
      },
      codeUrl: '',
      userCode: '',
      rules: {
        userName: [
          {
            required: true,
            message: '账号不能为空',
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: '密码不能为空',
            trigger: 'blur'
          }
        ],
        code: [
          {
            required: true,
            message: '验证码不能为空',
            trigger: 'blur'
          }
        ],
        userNameAgain: [
          {
            required: true,
            validator: userNameCheck,
            trigger: 'blur'
          }
        ],
        codeAgain: [
          {
            required: true,
            message: '验证码不能为空',
            trigger: 'blur'
          }
        ],
        newPassword: [
          {
            required: true,
            validator: pwdCheck,
            trigger: 'blur'
          }
        ],
        passWordAgain: [
          {
            required: true,
            validator: pwdCheckValidate,
            trigger: 'blur'
          }
        ]
      },
      welcome: welcome,
      computerPhone: '400-666-1816',
      asideTxt: 'copyright© 2021 TMNETWORK 成都探马网络科技有限公司',
      SystemType: 'chengdu',
      pwdCheckModal: false,
      isContainue: false,
      isNowState: 1, // 1 登录 2 跳过修改直接登录 3 修改密码 4 找回密码
      userInfo: null,
      isSingleLogin: false, // 是否是单点登录完成登录后进入
      holdOn: false, // 单点登录回跳进入白屏等待
      ybTimer: null, // 宜宾大屏进入的计时器
      isLzjy: null
    }
  },
  computed: {
    contianerBg() {
      return this.isSingleLogin
        ? { background: 'linear-gradient(to right, #FFFFFF, #DCF7EB)' }
        : this.holdOn
        ? { background: '#FFFFFF' }
        : { background: '#8CD1C1' }
    }
  },
  methods: {
    /** 温江科创单点登录 */
    checkWenJiangKechuangTeacherSSO(code, state) {
      const params = {
        code,
        state
      }
      this.$axios.post(`/v1/auth/checkWenJiangKechuangTeacherSSO`, params).then(res => {
        console.log('checkWenJiangKechuangTeacherSSO', res)
        this.$Message.destroy()
        if (res.data.code == 10000) {
          let { response } = res.data
          this.commonSubmit(response)
        } else {
          this.$router.push({ name: 'loginError' })
          // 登录失败需要替换路由到登录失败的结果页面
          let url = `${location.origin}/#/loginError`
          location.replace(url)
          this.holdOn = true
          this.isSingleLogin = false
        }
      })
    },
    // 确认修改密码
    confirmBack() {
      this.pwdCheckModal = false
      this.login = false
      this.isNowState = 3
      this.form.userNameAgain = this.form.userName
      this.form.codeAgain = ''
    },
    // 暂不修改
    cancelBack() {
      this.pwdCheckModal = false
      this.isContainue = true
      this.isNowState = 2
      this.commonSubmit(this.userInfo)
    },
    // 修改密码/找回密码
    handleSubmitForget() {
      let _this = this
      _this.$refs.loginForm.validate(valid => {
        if (valid) {
          let password = md5(_this.form.passWordAgain)
          let userData = {
            code: _this.form.codeAgain,
            passWord: password,
            phoneNum: _this.form.userNameAgain,
            teacherId: '',
            schoolType: this.schoolType
          }
          _this.$axios.post('/v1/auth/update/pass/word', userData).then(function (res) {
            _this.$Message.destroy()
            if (res.data.code == 10000) {
              let { response } = res.data
              _this.commonSubmit(response)
            } else if (res.data.code == 40004) {
              _this.$Message.info('验证码不正确或验证码已过期！')
            } else if (res.data.code == 50003) {
              _this.$Message.info('该账号已失效，请联系管理员！')
            } else {
              _this.$Message.info('账户或者密码错误！')
            }
          })
        } else {
          _this.$Message.error('请填写正确的各项信息')
        }
      })
    },
    commonSubmit(response, jumpType) {
      const that = this
      if (!response.menuList || response.menuList.length === 0) {
        that.$Message.info('该账号没有菜单权限，请联系管理员')
        return
      }
      //成都市温江区永盛学校-初中部
      if (response.schoolId == 2996) {
        response.menuList.push('schoolDataReport') //添加学校数据报告
        //删除学生健康监测
        let menuIundexdex = response.menuList.indexOf('healthDetection')
        response.menuList.splice(menuIundexdex, 1)
      }
      // 区分强基和小马系统版本
      const { schoolLevel, qiangjiSchoolLevel } = response
      response.schoolLevel = that.SystemType === 'qiangji' ? qiangjiSchoolLevel : schoolLevel

      // 文轩测试版本，添加固定置灰的路由
      if (response.schoolLevel === '4') {
        const wenxuanDisMenuList = [
          'examManage',
          'examItemManage',
          'healthknowledge',
          'paperManage',
          'sportsTotalScore',
          'sportsScoreStudent',
          'healthRecordManage',
          'healthRecordStudent',
          'dataReport',
          'reportStudentManage',
          'systemNotice',
          'systemNoticeList'
        ]
        response.menuList = [...response.menuList, ...wenxuanDisMenuList]
      }
      sessionStorage.setItem('overallSituationuserInfo', JSON.stringify(response))
      that.$axios.defaults.headers.common['token'] = response.oAuthTokenVO.token
      sessionStorage.setItem('token', response.oAuthTokenVO.token)
      sessionStorage.setItem('phoneNo', response.phoneNum)
      sessionStorage.setItem('teacherId', response.teacherId)
      sessionStorage.setItem('schoolId', response.schoolId)
      sessionStorage.setItem('name', response.name)
      sessionStorage.setItem('avatarUrl', response.avatarUrl)
      sessionStorage.setItem('menuList', response.menuList)
      sessionStorage.setItem('schoolName', response.schoolName)
      sessionStorage.setItem('schoolType', response.schoolType)
      sessionStorage.setItem('schoolDistrict', response.schoolDistrict)
      sessionStorage.setItem('weekCount', response.weekCount) // 周数
      sessionStorage.setItem('showMessageBox', true) // 登陆后需要显示待办事项
      sessionStorage.setItem('semesterStatus', response.semesterStatus) // 当前学期状态 1第一学期 2 寒假 3 第二学期 4 暑假 ,
      sessionStorage.setItem('isAdmin', response.isAdmin)
      sessionStorage.setItem('yearSemester', response.yearSemester)
      sessionStorage.setItem('semesterOver', response.semesterOver)
      sessionStorage.setItem('schoolYear', response.schoolYear)
      sessionStorage.setItem('single', response.single)

      that.$store.commit('updateMenulist')
      that.$store.commit('setStateName', '')
      that.$store.commit('modifyMnueList', '')
      that.$Message.success('登录成功！')

      const { shuangliuURL, wjkcURL } = this.$axios.defaults
      let { origin } = window.location
      // 双流单点登录需要替换路由
      // 乐智教育也需要替换路由
      if (origin === shuangliuURL || origin === wjkcURL || this.isLzjy == 1) {
        let url = `${location.origin}/#/dataDesk/dataDeskMain`
        location.replace(url)
      } else if (jumpType === 'welcome') {
        that.$router.push({ name: 'page-welCome' })
      } else {
        that.$router.push({ name: 'home_index' })
      }
    },
    // 登录
    handleSubmit() {
      let that = this
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          let password = md5(this.form.password)
          let data = {
            userPhone: this.form.userName,
            password: password,
            code: this.form.code,
            userCode: this.userCode,
            schoolType: this.schoolType,
            type: '1',
            loginType: this.SystemType === 'qiangji' ? '2' : this.SystemType === 'chengdu' ? '1' : ''
          }
          this.$axios
            .post('/v1/auth/login/password', data)
            .then(function (res) {
              that.$Message.destroy()
              that.$store.commit('setLoginStatus', false)
              if (res.data.code == 10000) {
                let { response } = res.data
                that.userInfo = response
                if (!validatePass(that.form.password) && !that.isContainue) {
                  that.pwdCheckModal = true
                  return
                }
                that.commonSubmit(response)
              } else {
                that.$Message.info(res.data.msg)
              }
            })
            .catch(function (error) {
              console.log(error)
            })
        }
      })
    },
    //第三方平台默认登录
    otherPlatformLoginAuth(temporaryToken) {
      let this_ = this
      let data = {
        temporaryToken: temporaryToken
      }
      this.$axios
        .post('/v1/auth/otherPlatformLoginAuth', data)
        .then(function (res) {
          this_.$Message.destroy()
          if (res.data.code == 10000) {
            let { response } = res.data
            this_.commonSubmit(response)
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    //嘉祥学校免密登录
    jiaxiangPlatformLoginAuth(jiaXiangAppKey, data) {
      let this_ = this
      let param = {
        jiaXiangAppKey: jiaXiangAppKey,
        data: data
      }
      this.$axios
        .post('/v1/auth/schoolLoginForJiaXiang', param)
        .then(res => {
          this.$Message.destroy()
          if (res.data.code == 10000) {
            let { response } = res.data
            this.commonSubmit(response)
          } else {
            this.$Modal.info({
              title: '提示',
              content: res.data.msg
            })
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    // 默认登录
    organLogin(ortanToken, loginSchoolId) {
      let this_ = this
      this.$axios
        .get('/v1/auth/loginSchoolByOrgantoken?token=' + ortanToken + '&schoolId=' + loginSchoolId)
        .then(function (res) {
          this_.$Message.destroy()
          if (res.data.code == 10000) {
            let { response } = res.data
            this.commonSubmit(response, 'welcome')
          } else {
            this_.$Message.info(res.data.msg)
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    // 发送验证码
    changePhoneCode() {
      const that = this
      if (this.countdown < 60) return
      const regExp = /^1[34578]\d{9}$/
      const { userNameAgain } = this.form
      if (regExp.test(userNameAgain)) {
        this.$axios
          .get(`/v1/auth/sendSmsForUpdatePassword?phoneNum=${userNameAgain}&schoolType=${this.schoolType}`)
          .then(function (res) {
            if (res.data.code == 10000) {
              that.codeStyle.background = '#999999'
              that.settime(that)
            } else {
              that.$Message.error('发送失败，请稍后再试！')
            }
          })
      }
    },
    // 获取动态验证码
    changeCode() {
      this.userCode = new Date().getTime()
      this.codeUrl = `${this.$axios.defaults.baseURL}/v1/auth/login/getCheckCode?userCode=${this.userCode}`
    },
    // 倒计时
    settime(that) {
      if (that.countdown == 0) {
        that.codeStyle.background = '#2DB394'
        that.countdown = 60
        that.codeTxt = '发送验证码'
        return
      } else {
        that.codeTxt = `已发送${that.countdown}s`
        that.countdown--
      }
      setTimeout(function () {
        that.settime(that)
      }, 1000)
    },
    // 切换状态
    forgetThePwd() {
      this.login = !this.login
      if (this.login) {
        this.isNowState = 1
      } else {
        this.isNowState = 4
        this.form.userNameAgain = this.form.userName
        this.form.codeAgain = ''
      }
    },
    //单点登录认证地址
    checkJiaFaTeacherSSO(ticket) {
      const { shuangliuURL } = this.$axios.defaults
      let this_ = this
      let data = {
        appType: '1',
        ticket: ticket,
        serviceUrl: `${shuangliuURL}/`
      }
      this.$axios
        .post('/v1/auth/checkJiaFaTeacherSSO', data)
        .then(function (res) {
          this_.$Message.destroy()
          if (res.data.code == 10000) {
            let { response } = res.data
            this_.commonSubmit(response)
          } else {
            // this_.$router.push({name: 'loginError'})
            // 登录失败需要替换路由到登录失败的结果页面
            let url = `${location.origin}/#/loginError`
            location.replace(url)
            this_.holdOn = true
            this_.isSingleLogin = false
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    // 第三方平台临时令牌登录
    secretKeyLogin() {
      let hrefUrl = window.location.href

      if (hrefUrl.indexOf('jiaXiangAppKey=') >= 0) {
        let jiaXiangAppKey = hrefUrl.substring(hrefUrl.indexOf('jiaXiangAppKey=') + 15, hrefUrl.indexOf('&'))
        let data = hrefUrl.substring(hrefUrl.indexOf('data=') + 5, hrefUrl.length)
        this.jiaxiangPlatformLoginAuth(jiaXiangAppKey, data)
      } else if (hrefUrl.indexOf('schoolopenappkey=') >= 0) {
        let schoolopenappkey = hrefUrl.substring(hrefUrl.indexOf('schoolopenappkey=') + 17, hrefUrl.length)
        this.schoolLoginForKey(schoolopenappkey, '')
      } else if (hrefUrl.indexOf('temporaryToken=') > 0) {
        let temporaryToken = hrefUrl.substring(hrefUrl.indexOf('temporaryToken=') + 15, hrefUrl.length)
        this.otherPlatformLoginAuth(temporaryToken)
      } else if (hrefUrl.indexOf('ticket=') > 0) {
        let ticket = hrefUrl.substring(hrefUrl.indexOf('ticket=') + 7, hrefUrl.indexOf('#'))
        this.checkJiaFaTeacherSSO(ticket)
      } else if (hrefUrl.indexOf('token=') > 0) {
        let organToken = hrefUrl.substring(hrefUrl.indexOf('token=') + 6, hrefUrl.indexOf('&'))
        let loginSchool = hrefUrl.substring(hrefUrl.indexOf('schoolid=') + 9, hrefUrl.indexOf('key=') - 1)
        this.organLogin(organToken, loginSchool)
      } else if (hrefUrl.indexOf('state=') > 0) {
        const code = this.getQueryString('code')
        const state = this.getQueryString('state')
        this.checkWenJiangKechuangTeacherSSO(code, state)
      }
    },
    /** 乐智教育免密登录 */
    dxLzjyTeacherLogin() {
      const code = sessionStorage.getItem('lzjy-code')
      // 获取当前域名
      const redirectUri = window.location.origin

      const params = {
        code,
        redirectUri,
        userId: 1234
      }
      // 清除本地存储中的isLzjy参数
      sessionStorage.removeItem('isLzjy')
      sessionStorage.removeItem('lzjy-code')

      this.$axios.post('/v1/auth/dxLzjyTeacherLogin', params).then(res => {
        console.log('乐智教育免密登录', res)

        if (res.data.code === 10000) {
          this.commonSubmit(res.data.response)
        } else {
          // 失败以后重定向到当前登陆页面，并清除url参数
          this.isLzjy = false
          location.replace(`${location.origin}/#/login`)
        }
      })
    },
    // 根据域名做不同的展示或者跳转
    handleDomainName() {
      const { zhURL, qiangjiURL, shuangliuURL, jiafaURL, wjkcURL } = this.$axios.defaults
      const { href, origin, hostname } = window.location

      const setSingleLoginTimeout = (callback, delay = 3000) => {
        let timeoutId = setTimeout(() => {
          callback()
          clearTimeout(timeoutId)
          timeoutId = null
        }, delay)
      }

      const domainActions = {
        [zhURL]: () => {
          this.welcome = guangzhou
          this.computerPhone = '020-38939139'
          this.asideTxt = 'copyright© 2018-2021 广州蔚来体育科技有限公司'
        },
        [qiangjiURL]: () => {
          this.welcome = qiangji
          this.loginTitle = '学校体育与健康管理信息系统(学校端)'
          this.SystemType = 'qiangji'
          this.$store.commit('setThemeColor', '#30A37D')
        },
        [shuangliuURL]: () => {
          if (!href.includes('ticket=')) {
            this.isSingleLogin = true
            setSingleLoginTimeout(() => {
              window.location.replace(`${jiafaURL}/sso/login?service=${shuangliuURL}/`)
            })
          } else {
            this.holdOn = true
          }
        },
        [wjkcURL]: () => {
          if (!href.includes('state=')) {
            this.isSingleLogin = true
            setSingleLoginTimeout(() => this.handleWJKSSOLogin())
          } else {
            this.holdOn = true
          }
        }
      }
      const action = domainActions[origin] || domainActions[hostname]
      if (action) action()
    },
    // 宜宾学校硬件大屏进入学校，两分钟内未登录，自动返回上一个欢迎页
    checkIsNeedBack() {
      let hrefUrl = window.location.href
      // 是否是大屏进入后回退回来的
      let yb_enter = sessionStorage.getItem('yb_enter')
      if (yb_enter != '1') {
        // 初始值 是否是宜宾大屏进入，是小马大屏还是宜宾大屏
        sessionStorage.setItem('yb_isYiBinBigScreen', '0')
        sessionStorage.setItem('yb_originType', '')
        sessionStorage.setItem('yb_deviceCode', '')
      } else {
        // 回退进入,直接开始计时，缓存不做任何变化
        clearTimeout(this.ybTimer)
        this.ybTimer = setTimeout(() => {
          sessionStorage.setItem('yb_enter', '0')
          //TODO: 此方法无法回到大屏页面---待定
          window.history.back()
        }, 60000 * 2)
      }
      // 大屏进入
      if (hrefUrl.indexOf('city=') >= 0) {
        sessionStorage.setItem('yb_isYiBinBigScreen', '1')
        sessionStorage.setItem('yb_enter', '1')
        // 1 小马 2 强基
        sessionStorage.setItem('yb_originType', getUrlValue('originType'))
        sessionStorage.setItem('yb_deviceCode', getUrlValue('deviceCode'))
        this.ybTimer = setTimeout(() => {
          sessionStorage.setItem('yb_enter', '0')
          window.history.back()
        }, 60000 * 2)
      } else {
        // 直接系统登录进入
        sessionStorage.setItem('yb_isYiBinBigScreen', '0')
        sessionStorage.setItem('yb_enter', '0')
        sessionStorage.setItem('yb_originType', '')
        sessionStorage.setItem('yb_deviceCode', '')
      }
    },
    /** 处理温江科创单点登录  */
    handleWJKSSOLogin() {
      this.$axios.get('/v1/wjkc/getUserAgentData').then(res => {
        if (res.data.code === 10000) {
          const { authorizationCodeUrl, clientId, codeChallenge, redirectUri, responseType, scope, state } =
            res.data.response
          const params = {
            client_id: clientId,
            scope,
            state,
            code_challenge: codeChallenge,
            response_type: responseType,
            redirect_uri: redirectUri
          }
          const url = Util.createURL(authorizationCodeUrl, params)
          window.location.href = url
        } else {
          this.$Message.info(res.data.msg)
        }
      })
    },
    // 获取地址参数
    getQueryString(name) {
      let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      let r = window.location.search.substr(1).match(reg)
      if (r != null) return unescape(r[2])
      return ''
    },
    // 免密登录
    async schoolLoginForKey(appKey, temporaryKey) {
      const res = await Login.educationLoginForKey({
        appKey,
        temporaryKey: temporaryKey,
        loginType: 1
      })
      if (res.data.code == 10000) {
        let { response } = res.data
        this.commonSubmit(response)
      } else {
        this.changeCode()
      }
    }
  },
  created() {
    // 处理乐智教育免密登录
    const isLzjy = sessionStorage.getItem('isLzjy')
    if (isLzjy == 1) {
      this.isLzjy = isLzjy
      setTimeout(() => {
        this.dxLzjyTeacherLogin()
      }, 1000)
    }
  },
  mounted() {
    thisObject = this
    this.secretKeyLogin() // 第三方平台临时令牌登录
    this.handleDomainName() // 根据域名做不同的展示或者跳转
    this.changeCode()
    this.checkIsNeedBack()
  },
  beforeDestroy() {
    clearTimeout(this.ybTimer)
    this.ybTimer = null // Clear the timer when the component is destroyed
  }
}
</script>

<style lang="less">
@import './login.less';
.lzjy-container {
  width: 100vw;
  height: 100vh;
  padding: 5.1563vw 1.1979vw 5.3125vw 4.2708vw;
  box-sizing: border-box;
  background: linear-gradient(to right, #fffefd, #ffe9d7);
  display: flex;
  justify-content: space-between;
  .welcome-left {
    position: relative;
    .company-logo {
      position: absolute;
      top: 0;
      left: 0;
      width: 14.4271vw;
      height: 3.3333vw;
    }
    .aside-container {
      font-size: 64px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #000000;
      .wel {
        font-size: 64px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ff7d12;
      }
      .wel-title {
        font-size: 64px;
        font-family: Source Han Sans SC;
        font-weight: bold;
        color: #000000;
      }
    }
    .animate__animated.animate__backInLeft {
      --animate-duration: 3s;
    }
  }
  .welcome-right {
    overflow: hidden;
    max-width: 49.5833vw;
    max-height: 45.7813vw;
    .company-aside {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
