/**
 * 非昆明-数据档案路由
*/
export default [
    {
        path: 'historyStudentData',
        icon: 'ios-list-box-outline',
        name: 'historyStudentData',
        title: '历史学生数据',
        component: resolve => {
            require(['@/views/COMMON_VIEW/data_files/archive/historyStudentData.vue'], resolve);
        }
    },
    {
        path: 'healthRecordStudent',
        icon: 'ios-list-box-outline',
        name: 'healthRecordStudent',
        title: '学生档案',
        component: resolve => {
            require(['@/views/COMMON_VIEW/data_files/archive/healthRecordStudent.vue'], resolve);
        }
    },
    {
        path: 'healthRecordDetail',
        icon: 'ios-list-box-outline',
        name: 'healthRecordDetail',
        title: '健康档案详情',
        component: resolve => {
            require(['@/views/COMMON_VIEW/data_files/archive/healthRecordDetail/index.vue'], resolve);
        }
    },
    {
        path: 'healthRecordDetailYN',
        icon: 'ios-list-box-outline',
        name: 'healthRecordDetailYN',
        title: '健康档案详情',
        component: resolve => {
            require(['@/views/CITY_KM/data_files/archive/healthRecordDetailYN/index.vue'], resolve);
        }
    },
    // {
    //     path: 'healthDetection',
    //     icon: 'ios-list-box-outline',
    //     name: 'healthDetection',
    //     title: '学生健康监测',
    //     component: resolve => {
    //         require(['@/views/COMMON_VIEW/data_files/history_data/healthDetection.vue'], resolve);
    //     }
    {
      path: 'healthDetection',
      icon: 'ios-list-box-outline',
      name: 'healthDetection',
      title: '问卷管理',
      component: resolve => {
          require(['@/views/COMMON_VIEW/data_files/history_data/questionManagement.vue'], resolve);
      }
    },
    {
        path: 'questionData',
        icon: 'ios-list-box-outline',
        name: 'questionData',
        title: '问卷数据',
        component: resolve => {
            require(['@/views/COMMON_VIEW/data_files/history_data/questionData.vue'], resolve);
        }
    },
    {
        path: 'dayList',
        icon: 'ios-list-box-outline',
        name: 'dayList',
        title: '学生健康监测列表',
        component: resolve => {
            require(['@/views/COMMON_VIEW/data_files/history_data/dayList.vue'], resolve);
        }
    },
    {
        path: 'schoolDataReport',
        icon: 'ios-list-box-outline',
        name: 'schoolDataReport',
        title: '学校数据报告',
        component: resolve => {
            require(['@/views/COMMON_VIEW/data_files/history_data/schoolDataReport.vue'], resolve);
        }
    },
    {
        path: 'childrenNCPDetailInfo',
        icon: 'ios-list-box-outline',
        name: 'childrenNCPDetailInfo',
        title: '学生健康监测报告',
        component: resolve => {
            require(['@/views/COMMON_VIEW/data_files/history_data/childrenNCPDetailInfo.vue'], resolve);
        }
    },
    {
        path: 'dataFilesManage',
        icon: 'ios-list-box-outline',
        name: 'dataFilesManage',
        title: '历史数据',
        component: resolve => {
            require(['@/views/COMMON_VIEW/data_files/history_data/dataFilesManage.vue'], resolve);
        }
    },
    {
        path: 'dataFilesManageHigh',
        icon: 'ios-list-box-outline',
        name: 'dataFilesManageHigh',
        title: '历史数据',
        component: resolve => {
            require(['@/views/COMMON_VIEW/data_files/history_data/highSchool/dataFilesManageHigh.vue'], resolve);
        }
    },
    {
        path: 'dataFilesGradeInfo',
        icon: 'ios-list-box-outline',
        name: 'dataFilesGradeInfo',
        title: '历史学期信息',
        component: resolve => {
            require(['@/views/COMMON_VIEW/data_files/history_data/dataFilesGradeInfo.vue'], resolve);
        }
    },
    {
        path: 'hisStudentExempt',
        icon: 'ios-list-box-outline',
        name: 'hisStudentExempt',
        title: '历史学期免试列表',
        component: resolve => {
            require(['@/views/COMMON_VIEW/data_files/hisStudentExempt.vue'], resolve);
        }
    },
    {
        path: 'hisStudentExemptDetail',
        icon: 'ios-list-box-outline',
        name: 'hisStudentExemptDetail',
        title: '历史学期免试详情',
        component: resolve => {
            require(['@/views/COMMON_VIEW/data_files/hisStudentExemptDetail.vue'], resolve);
        }
    },
    {
        path: 'dataFilesScoreStudent',
        icon: 'ios-list-box-outline',
        name: 'dataFilesScoreStudent',
        title: '数据档案学生列表',
        component: resolve => {
            require(['@/views/COMMON_VIEW/data_files/history_data/dataFilesScoreStudent.vue'], resolve);
        }
    },
    {
        path: 'dataFilesScoreStudentDetail',
        icon: 'ios-list-box-outline',
        name: 'dataFilesScoreStudentDetail',
        title: '数据档案学生详情',
        component: resolve => {
            require(['@/views/COMMON_VIEW/data_files/history_data/dataFilesScoreStudentDetail.vue'], resolve);
        }
    },
    {
        path: 'exportDataFilesStudnetTaskManage',
        icon: 'ios-list-box-outline',
        name: 'exportDataFilesStudnetTaskManage',
        title: '数据档案导入学生',
        component: resolve => {
            require(['@/views/COMMON_VIEW/data_files/history_data/exportDataFilesStudnetTaskManage.vue'], resolve);
        }
    },
    {
        path: 'educationHistoryReport',
        icon: 'ios-list-box-outline',
        name: 'educationHistoryReport',
        title: '历史数据报送',
        component: resolve => {
            require(['@/views/COMMON_VIEW/data_files/history_data/educationHistoryReport.vue'], resolve);
        }
    },
    {
        path: 'studentSubjectHisReportDetail',
        icon: 'ios-list-box-outline',
        name: 'studentSubjectHisReportDetail',
        title: '历史数据报送学生数据',
        component: resolve => {
            require(['@/views/COMMON_VIEW/data_files/history_data/studentSubjectHisReportDetail.vue'], resolve);
        }
    },
    {
        path: 'actionStaminaGrade',
        icon: 'ios-list-box-outline',
        name: 'actionStaminaGrade',
        title: '技能体能历史年级数据',
        component: resolve => {
            require(['@/views/COMMON_VIEW/data_files/history_data/skillPhysicalHistoryData/actionStaminaGrade.vue'], resolve);
        }
    },
    {
        path: 'actionStaminaStudentList',
        icon: 'ios-list-box-outline',
        name: 'actionStaminaStudentList',
        title: '技能体能历史学生列表',
        component: resolve => {
            require(['@/views/COMMON_VIEW/data_files/history_data/skillPhysicalHistoryData/actionStaminaStudentList.vue'], resolve);
        }
    },
    {
        path: 'actionStaminaStudentDetail',
        icon: 'ios-list-box-outline',
        name: 'actionStaminaStudentDetail',
        title: '技能体能历史学生详情',
        component: resolve => {
            require(['@/views/COMMON_VIEW/data_files/history_data/skillPhysicalHistoryData/actionStaminaStudentDetail.vue'], resolve);
        }
    },
    {
        path: 'actionStaminaRepair',
        icon: 'ios-list-box-outline',
        name: 'actionStaminaRepair',
        title: '技能体能历史学生补录',
        component: resolve => {
            require(['@/views/COMMON_VIEW/data_files/history_data/skillPhysicalHistoryData/actionStaminaRepair.vue'], resolve);
        }
    },
    {
        path: 'actionStaminaHisConfige',
        icon: 'ios-list-box-outline',
        name: 'actionStaminaHisConfige',
        title: '技能体能历史配置',
        component: resolve => {
            require(['@/views/COMMON_VIEW/data_files/history_data/skillPhysicalHistoryData/actionStaminaHisConfige.vue'], resolve);
        }
    },
    {
        path: 'actionStaminaConfigeChoose',
        icon: 'ios-list-box-outline',
        name: 'actionStaminaConfigeChoose',
        title: '技能体能历史配置选择',
        component: resolve => {
            require(['@/views/COMMON_VIEW/data_files/history_data/skillPhysicalHistoryData/actionStaminaConfigeChoose.vue'], resolve);
        }
    },
    {
        path: 'hisExerciseManageClass',
        icon: 'ios-list-box-outline',
        name: 'hisExerciseManageClass',
        title: '历史学期课后作业按班级管理',
        component: resolve => { require(['@/views/COMMON_VIEW/data_files/hisExerciseManageClass.vue'], resolve); }
    },
    {
        path: 'hisExerciseManageStudent',
        icon: 'ios-list-box-outline',
        name: 'hisExerciseManageStudent',
        title: '历史学期课后作业按学生管理',
        component: resolve => { require(['@/views/COMMON_VIEW/data_files/hisExerciseManageStudent.vue'], resolve); }
    },
    {
        path: 'hisExerciseMakeUpManage',
        icon: 'ios-list-box-outline',
        name: 'hisExerciseMakeUpManage',
        title: '历史学期课后作业按学生补录',
        component: resolve => { require(['@/views/COMMON_VIEW/data_files/hisExerciseMakeUpManage.vue'], resolve); }
    },
    {
        path: 'hisStudyLearningManage',
        icon: 'ios-list-box-outline',
        name: 'hisStudyLearningManage',
        title: '历史学期课后作业按学生补录',
        component: resolve => { require(['@/views/COMMON_VIEW/data_files/hisStudyLearningManage.vue'], resolve); }
    },
    {
        path: 'hisStudyLearningStudent',
        icon: 'ios-list-box-outline',
        name: 'hisStudyLearningStudent',
        title: '历史学期课后作业学习态度详情',
        component: resolve => { require(['@/views/COMMON_VIEW/data_files/hisStudyLearningStudent.vue'], resolve); }
    },
    {
        path: 'hisPaperScoreManage',
        icon: 'ios-list-box-outline',
        name: 'hisPaperScoreManage',
        title: '历史学期健康知识管理',
        component: resolve => { require(['@/views/COMMON_VIEW/data_files/hisPaperScoreManage.vue'], resolve); }
    },
    {
        path: 'hisPaperClassResults',
        icon: 'ios-list-box-outline',
        name: 'hisPaperClassResults',
        title: '历史学期健康知识详情',
        component: resolve => { require(['@/views/COMMON_VIEW/data_files/hisPaperClassResults.vue'], resolve); }
    },
    {
        path: 'hisPaperEntryResults',
        icon: 'ios-list-box-outline',
        name: 'hisPaperEntryResults',
        title: '历史学期健康知识详情',
        component: resolve => { require(['@/views/COMMON_VIEW/data_files/hisPaperEntryResults.vue'], resolve); }
    },
    {
        path: 'hisActivityClassList',
        icon: 'ios-list-box-outline',
        name: 'hisActivityClassList',
        title: '历史学期体质测试班级管理',
        component: resolve => { require(['@/views/COMMON_VIEW/data_files/hisActivityClassList.vue'], resolve); }
    },
    {
        path: 'hisActivityStudentList',
        icon: 'ios-list-box-outline',
        name: 'hisActivityStudentList',
        title: '历史学期体质测试学生管理',
        component: resolve => { require(['@/views/COMMON_VIEW/data_files/hisActivityStudentList/index.vue'], resolve); }
    },
    {
        path: 'hisActivityClassTestDetail',
        icon: 'ios-list-box-outline',
        name: 'hisActivityClassTestDetail',
        title: '历史学期体质测试学生管理',
        component: resolve => { require(['@/views/COMMON_VIEW/data_files/hisActivityClassTestDetail.vue'], resolve); }
    },
    {
        path: 'hisVisionManage',
        icon: 'ios-list-box-outline',
        name: 'hisVisionManage',
        title: '视力测试管理',
        component: resolve => { require(['@/views/COMMON_VIEW/data_files/visionManage/hisVisionManage/hisVisionManage.vue'], resolve); }
    },
    {
        path: 'hisVisionManageDetail',
        icon: 'ios-list-box-outline',
        name: 'hisVisionManageDetail',
        title: '视力测试管理列表',
        component: resolve => { require(['@/views/COMMON_VIEW/data_files/visionManage/hisVisionManageDetail/hisVisionManageDetail.vue'], resolve); }
    },
    {
        path: 'hisPhysicalTestImport',
        icon: 'ios-list-box-outline',
        name: 'hisPhysicalTestImport',
        title: '数据档案-历史数据-体测信息导入',
        component: resolve => { require(['@/views/COMMON_VIEW/public_import_report/commonImport/dataFiles/physicalTest.vue'], resolve); }
    },
    {
        path: 'createPaper',
        icon: 'ios-list-box-outline',
        name: 'createPaper',
        title: '新建问卷',
        component: resolve => { require(['@/views/COMMON_VIEW/data_files/createPaper/createPaper.vue'], resolve); }
    }
]