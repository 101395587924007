<template>
  <div>
    <Menu
      ref="sideMenu"
      :active-name="$route.name"
      :open-names="openNames"
      :theme="menuTheme"
      width="auto"
      @on-select="changeMenu"
    >
      <!-- 不包含首页的其他菜单 -->
      <template v-for="(item, index) in menuList">
        <Submenu :name="item.name" :key="item.path" v-if="item.children.length">
          <template slot="title">
            <Icon :type="item.icon" size="20"></Icon>
            <span class="layout-text">{{ item.title }}</span>
          </template>
          <template v-for="(child, jindex) in item.children">
            <MenuItem :id="child.name" :name="child.name">
              <div>
                <Icon :type="child.icon" :size="iconSize" :key="child.name + jindex"></Icon>
                <span class="layout-text layout-text-size" :key="jindex">{{ child.title }}</span>
              </div>
            </MenuItem>
          </template>
        </Submenu>
      </template>
    </Menu>
  </div>
</template>
<style lang="less">
@import '../styles/menu.less';
</style>

<script>
export default {
  name: 'sidebarMenu',
  props: {
    menuList: Array,
    iconSize: Number,
    menuTheme: {
      type: String,
      default: 'dark'
    },
    openNames: {
      type: Array
    }
  },
  data() {
    return {
      schoolDistrict: ''
    }
  },
  methods: {
    changeMenu(active) {
      this.$emit('on-change', active)
      let fromName = sessionStorage.getItem('fromName')
      this.$store.state.pageParams.listPagePars.clear()
      if (this.$store.state.pageParams.listPagePars.has(fromName)) {
        this.$store.state.pageParams.listPagePars.delete(fromName)
      }
    }
  },
  created() {},
  updated() {
    this.$nextTick(() => {
      if (this.$refs.sideMenu) {
        this.$refs.sideMenu.updateOpened()
      }
    })
  }
}
</script>
<style lang="less">
// .menu_line_top {
//   height: 50px;
//   box-sizing: border-box;
//   padding: 14px 24px;
// }
.ivu-menu-submenu {
  .ivu-menu-submenu-title {
  }
}
.ivu-menu-item {
  .ivu-menu-submenu-title {
    padding: 0 !important;
  }
}
</style>
