import Main from '@/views/MAIN/Main.vue';
export default [
    {
        path: '/dataManage',// 第二学期不显示 国家体制健康测试
        icon: 'md-git-branch',
        name: 'dataManage',
        title: '国家体质健康测试',
        parentCode: 'top_test',
        parentName: '体质测试',
        parentIcon: 'md-stats',
        disabled: false,
        component: Main,
        children: [
            {
                path: 'queryStudentList',
                icon: 'ios-list-box-outline',
                name: 'queryStudentList',
                title: '按学生查询',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/physical_fitness_test/queryStudentList.vue'], resolve);
                }
            },
            {
                path: 'classTestDetail',
                icon: 'ios-list-box-outline',
                name: 'classTestDetail',
                title: '学生测试详情',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/physical_fitness_test/classTestDetail.vue'], resolve);
                }
            },
            {
                path: 'exercisePrescription',
                icon: 'ios-list-box-outline',
                name: 'exercisePrescription',
                title: '学生运动处方',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/physical_fitness_test/exercisePrescription.vue'], resolve);
                }
            },
            {
                path: 'queryClassList',
                icon: 'ios-list-box-outline',
                name: 'queryClassList',
                title: '按班级查询',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/physical_fitness_test/queryClassList.vue'], resolve);
                }
            },
            {
                path: 'queryGradeList',
                icon: 'ios-list-box-outline',
                name: 'queryGradeList',
                title: '按年级查询',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/physical_fitness_test/queryGradeList.vue'], resolve);
                }
            },
            {
                path: 'queryProjectList',
                icon: 'ios-list-box-outline',
                name: 'queryProjectList',
                title: '按项目查询',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/physical_fitness_test/queryProjectList.vue'], resolve);
                }
            },
            {
                path: 'queryHistoryData',
                icon: 'ios-list-box-outline',
                name: 'queryHistoryData',
                title: '历史数据查询',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/physical_fitness_test/queryHistoryData.vue'], resolve);
                }
            },
            {
                path: 'bathAddStudentTestResult',
                icon: 'ios-list-box-outline',
                name: 'bathAddStudentTestResult',
                title: '学生体测成绩录入',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/physical_fitness_test/bathAddStudentTestResult.vue'], resolve);
                }
            },
            {
                path: 'testTeacherWorkload',
                icon: 'ios-list-box-outline',
                name: 'testTeacherWorkload',
                title: '体测工作量分析',
                component: resolve => {
                    require(['@/views/COMMON_VIEW/physical_fitness_test/testTeacherWorkload.vue'], resolve);
                }
            },
        ]
    },
]