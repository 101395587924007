import Main from '@/views/MAIN/Main.vue';
export default [
    {
        path: '/exerciseDataManagement',
        icon: 'ios-podium',
        name: 'exerciseDataManagement',
        title: '体育活动分析',
        parentCode: 'top_aiSchool',
        parentName: 'AI校园',
        parentIcon: 'md-cloud',
        disabled: false,
        component: Main,
        children: [
          {
            path: 'exerciseDataStatistics',
            icon: 'ios-list-box-outline',
            name: 'exerciseDataStatistics',
            title: '体育活动分析',
            component: resolve => {
              require(['@/views/COMMON_VIEW/other_service/exerciseDataManagement/index.vue'], resolve);
            }
          },
          {
            path: 'exerciseData-classDetail',
            icon: 'ios-list-box-outline',
            name: 'exerciseDataClassDetail',
            title: '班级详情',
            component: resolve => {
              require(['@/views/COMMON_VIEW/other_service/exerciseDataManagement/classDetail.vue'], resolve);
            }
          },
          {
            path: 'exerciseData-exerciseDetail',
            icon: 'ios-list-box-outline',
            name: 'exerciseDataExerciseDetail',
            title: '锻炼详情',
            component: resolve => {
              require(['@/views/COMMON_VIEW/other_service/exerciseDataManagement/exerciseDetail.vue'], resolve);
            }
          },
          
        ]
      },
      {
        path: '/sunshineRunManage',
        icon: 'md-walk',
        name: 'sunshineRunManage',
        title: '阳光跑',
        parentCode: 'top_aiSchool',
        parentName: 'AI校园',
        parentIcon: 'md-cloud',
        disabled: false,
        component: Main,
        children: [
          {
            path: 'sunshineRunStatistics',
            icon: 'ios-list-box-outline',
            name: 'sunshineRunStatistics',
            title: '跑步数据统计',
            component: resolve => {
              require(['@/views/COMMON_VIEW/other_service/sunshineRunManage/runningDataStatistics/index.vue'], resolve);
            }
          },
          {
            path: 'sunshineRunClassStatistics',
            icon: 'ios-list-box-outline',
            name: 'sunshineRunClassStatistics',
            title: '班级数据统计',
            component: resolve => {
              require(['@/views/COMMON_VIEW/other_service/sunshineRunManage/sunshineRunClassStatistics/index.vue'], resolve);
            }
          },
          {
            path: 'runStatistics',
            icon: 'ios-list-box-outline',
            name: 'runStatistics',
            title: '跑步数据明细',
            component: resolve => {
              require(['@/views/COMMON_VIEW/other_service/sunshineRunManage/runStatistics/index.vue'], resolve);
            }
          },
          {
            path: 'runStatisticDetail',
            icon: 'ios-list-box-outline',
            name: 'runStatisticDetail',
            title: '跑步数据明细',
            component: resolve => {
              require(['@/views/COMMON_VIEW/other_service/sunshineRunManage/runStatistics/detail.vue'], resolve);
            }
          },
        ]
      },
      {
        path: '/intelligentTeachingManagement',
        icon: 'md-bookmarks',
        name: 'intelligentTeachingManagement',
        title: '智能教学管理',
        parentCode: 'top_aiSchool',
        parentName: 'AI校园',
        parentIcon: 'md-cloud',
        disabled: false,
        component: Main,
        children: [
          {
            path: 'teachingManage',
            icon: 'ios-list-box-outline',
            name: 'teachingManage',
            title: '智能课堂监控',
            component: resolve => {
              require(['@/views/COMMON_VIEW/basic_configuration/intelligentTeachingManagement/teachingManage.vue'], resolve);
            }
          },
          {
            path: 'deviceAccountManagement',
            icon: 'ios-list-box-outline',
            name: 'deviceAccountManagement',
            title: '测试设备账号管理',
            component: resolve => {
              require(['@/views/COMMON_VIEW/basic_configuration/intelligentTeachingManagement/deviceAccountManagement.vue'], resolve);
            }
          },
          {
            path: 'bathGroup',
            icon: 'ios-list-box-outline',
            name: 'bathGroup',
            title: '批量分组',
            component: resolve => {
              require(['@/views/COMMON_VIEW/basic_configuration/intelligentTeachingManagement/bathGroup.vue'], resolve);
            }
          },
          {
            path: 'giveDevice',
            icon: 'ios-list-box-outline',
            name: 'giveDevice',
            title: '分配手环',
            component: resolve => {
              require(['@/views/COMMON_VIEW/basic_configuration/intelligentTeachingManagement/giveDevice.vue'], resolve);
            }
          },
          {
            path: 'testDeviceManage',
            icon: 'ios-list-box-outline',
            name: 'testDeviceManage',
            title: '测试任务管理',
            component: resolve => {
              require(['@/views/COMMON_VIEW/basic_configuration/intelligentTeachingManagement/testTaskManage/index.vue'], resolve);
            }
          },
          {
            path: 'testRecordManage',
            icon: 'ios-list-box-outline',
            name: 'testRecordManage',
            title: '测试记录管理',
            component: resolve => {
              require(['@/views/COMMON_VIEW/basic_configuration/intelligentTeachingManagement/testRecordManage.vue'], resolve);
            }
          },
          {
            path: 'smartClassMonitor_high',
            icon: 'ios-paper-outline',
            name: 'teachingManageHigh',
            title: '智能课堂监控',
            meta: {
              schoolType: '4',
              scene: '高中'
            },
            component: resolve => { require(['@/views/CITY_CD/highSchool/baseConfig/smartClassMonitor/index.vue'], resolve); }
          },
          {
            path: 'giveDevice_high',
            icon: 'ios-paper-outline',
            name: 'giveDevice_high',
            title: '分配手环',
            component: resolve => { require(['@/views/CITY_CD/highSchool/baseConfig/smartClassMonitor/giveDevice.vue'], resolve); }
          },
          {
            path: 'bathGroup_high',
            icon: 'ios-paper-outline',
            name: 'bathGroup_high',
            title: '手环批量分组',
            component: resolve => { require(['@/views/CITY_CD/highSchool/baseConfig/smartClassMonitor/bathGroup.vue'], resolve); }
          },
        ]
      },
      {
        path: '/sportsHealthData',
        icon: 'md-bicycle',
        name: 'sportsHealthData',
        title: '手环监测管理',
        parentCode: 'top_aiSchool',
        parentName: 'AI校园',
        parentIcon: 'md-cloud',
        disabled: false,
        component: Main,
        children: [
          { // 昆明、成都中小学（列表页）
            path: 'classMonitoringData',
            icon: 'ios-list-box-outline',
            name: 'classMonitoringData',
            title: '课堂监测数据',
            component: resolve => { require(['@/views/COMMON_VIEW/classroom_monitoring/sportsAndHealthData/index.vue'], resolve); }
          },
          { // 成都-高中（列表页）
            path: 'classMonitoringDataHigh',
            icon: 'ios-list-box-outline',
            name: 'classMonitoringDataHigh',
            title: '课堂监测数据',
            component: resolve => { require(['@/views/CITY_CD/highSchool/classMonitorData/index.vue'], resolve); }
          },
          {
            path: 'classMonitorConfig',
            icon: 'ios-paper-outline',
            name: 'classMonitorConfig',
            title: '课堂监测配置',
            component: resolve => { require(['@/views/COMMON_VIEW/classroom_monitoring/classMonitorConfig/index.vue'], resolve); }
          },
          { // 监测报告-详情页（中小学、高中、昆明共用）
            path: 'courseMonitoringReport',
            icon: 'ios-list-box-outline',
            name: 'courseMonitoringReport',
            title: '监测报告',
            component: resolve => {
              require(['@/views/COMMON_VIEW/classroom_monitoring/sportsAndHealthData/reportDetail.vue'], resolve);
            }
          },
          {
            path: 'classMonitoringStudentDetail',
            icon: 'ios-list-box-outline',
            name: 'classMonitoringStudentDetail',
            title: '学生课堂心率走势详情',
            component: resolve => {
              require(['@/views/COMMON_VIEW/classroom_monitoring/sportsAndHealthData/studentDetail.vue'], resolve);
            }
          },
          {
            path: 'allDayBraceletMonitorData',
            icon: 'ios-paper-outline',
            name: 'allDayBraceletMonitorData',
            title: '全天监测数据统计',
            component: resolve => { require(['@/views/COMMON_VIEW/classroom_monitoring/allDayBraceletMonitorData/index.vue'], resolve); }
          },
          {
            path: 'dailyMonitoringData',
            icon: 'ios-list-box-outline',
            name: 'dailyMonitoringData',
            title: '全天监测数据明细',
            component: resolve => {
              require(['@/views/COMMON_VIEW/classroom_monitoring/sportsAndHealthData/dailyMonitoringData.vue'], resolve);
            }
          },
        ]
    },
    {
        path: '/outdoorSportManage',
        icon: 'md-walk',
        name: 'outdoorSportManage',
        title: '户外运动',
        parentCode: 'top_aiSchool',
        parentName: 'AI校园',
        parentIcon: 'md-cloud',
        disabled: false,
        component: Main,
        children: [
          {
            path: 'outdoorSportData',
            icon: 'ios-list-box-outline',
            name: 'outdoorSportData',
            title: '户外运动',
            component: resolve => {
              require(['@/views/COMMON_VIEW/classroom_monitoring/outdoorSportManage/outdoorSportData/index.vue'], resolve);
            }
          },
          {
            path: 'addOrEditCurriculum',
            icon: 'ios-list-box-outline',
            name: 'addOrEditCurriculum',
            title: '创建/编辑课程',
            component: resolve => {
              require(['@/views/COMMON_VIEW/classroom_monitoring/outdoorSportManage/addOrEditCurriculum/index.vue'], resolve);
            }
          },
          {
            path: 'outdoorSportsReport',
            icon: 'ios-list-box-outline',
            name: 'outdoorSportsReport',
            title: '运动报告',
            component: resolve => {
              require(['@/views/COMMON_VIEW/classroom_monitoring/outdoorSportManage/outdoorSportsReport/index.vue'], resolve);
            }
          },
          {
            path: 'studentSportDetail',
            icon: 'ios-list-box-outline',
            name: 'studentSportDetail',
            title: '学生运动数据详情',
            component: resolve => {
              require(['@/views/COMMON_VIEW/classroom_monitoring/outdoorSportManage/outdoorSportsReport/studentSportDetail.vue'], resolve);
            }
          },
        ]
    },
    {
        path: '/cabinetManagement',
        icon: 'md-grid',
        name: 'cabinetManagrment',
        title: '智能储物柜管理',
        parentCode: 'top_aiSchool',
        parentName: 'AI校园',
        parentIcon: 'md-cloud',
        disabled: false,
        component: Main,
        children: [
          {
            path: 'cupboard',
            icon: 'ios-list-box-outline',
            name: 'cupboard',
            title: '智能储物柜',
            component: resolve => {
              require(['@/views/COMMON_VIEW/other_service/cabinetManagrment/index.vue'], resolve);
            }
          }
        ]
    },
]
